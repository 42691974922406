import { MemoizedSelector, createSelector, on, props, State, ReducerTypes, ActionCreator, Creator } from '@ngrx/store';

import { newAggregationId, CorrelationParams } from '../models/correlated-actions.model';

import { createTypedAction } from './action-creators.function';



/**
 * ========================================================================
 * Entity slice creation helpers
 * Source: https://github.com/vincent/ng-stator
 * ========================================================================
 */

/**
 * State Model
 */
export interface IStoreVoidEntityStateSlice {
	isLoading: boolean;
	error: any;
	isExporting?: boolean,
	exportError?: any,
}

export interface IStoreEntityStateSlice<Entity> {
	isLoading: boolean;
	error: any;
	entity: Entity;
	isExporting?: boolean,
	exportError?: any,
}

export function createEntitySlice<Entity>
(defaults?: Partial<IStoreEntityStateSlice<Entity>>): IStoreEntityStateSlice<Entity> {
	return {
		entity: null,
		isLoading: false,
		error: null,
		isExporting: false,
		exportError: null,
		...defaults,
	};
}

export function createVoidEntitySlice
(defaults?: Partial<IStoreVoidEntityStateSlice>): IStoreVoidEntityStateSlice {
	return {
		isLoading: false,
		error: null,
		isExporting: false,
		exportError: null,
		...defaults,
	};
}

/**
 * Entity Action Types
 */
export enum EntityActionType {
	LoadEntityByIdRequest = 'LoadEntityByIdRequest',
	LoadEntityRequest = 'LoadEntityRequest',
	LoadEntitySuccess = 'LoadEntitySuccess',
	LoadEntityFailure = 'LoadEntityFailure',

	CreateEntityConfirm = 'CreateEntityConfirm',
	CreateEntityRequest = 'CreateEntityRequest',
	CreateEntitySuccess = 'CreateEntitySuccess',
	CreateEntityFailure = 'CreateEntityFailure',

	UpdateEntityConfirm = 'UpdateEntityConfirm',
	UpdateEntityRequest = 'UpdateEntityRequest',
	UpdateEntitySuccess = 'UpdateEntitySuccess',
	UpdateEntityFailure = 'UpdateEntityFailure',

	PreviewEntityRequest = 'PreviewEntityRequest',
	PreviewEntitySuccess = 'PreviewEntitySuccess',
	PreviewEntityFailure = 'PreviewEntityFailure',

	DeleteEntityConfirm = 'DeleteEntityConfirm',
	DeleteEntityRequest = 'DeleteEntityRequest',
	DeleteEntityByIdRequest = 'DeleteEntityByIdRequest',
	DeleteEntitySuccess = 'DeleteEntitySuccess',
	DeleteEntityFailure = 'DeleteEntityFailure',

	ExportEntityRequest = 'ExportEntityRequest',
	ExportEntitySuccess = 'ExportEntitySuccess',
	ExportEntityFailure = 'ExportEntityFailure',
}

export function createEntityActions<Entity>(storeName: string, entityName: string) {
	return {
		LoadEntityRequest: createTypedAction(
			EntityActionType.LoadEntityRequest,
			`[${storeName}] Load ${entityName} Request`,
			(correlationParams: CorrelationParams = { correlationId: newAggregationId() }, noLoading?: boolean) => ( { correlationParams, noLoading } )
		),
		LoadEntityByIdRequest: createTypedAction(
			EntityActionType.LoadEntityRequest,
			`[${storeName}] Load ${entityName} By ID Request`,
			(id: number, correlationParams: CorrelationParams = { correlationId: newAggregationId() }, noLoading?: boolean) => ( { id, correlationParams, noLoading } )
		),
		LoadEntitySuccess: createTypedAction(
			EntityActionType.LoadEntitySuccess,
			`[${storeName}] Load ${entityName} Success`,
			props<{ entity: Entity, correlationParams?: CorrelationParams }>()
		),
		LoadVoidEntitySuccess: createTypedAction(
			EntityActionType.LoadEntitySuccess,
			`[${storeName}] Load ${entityName} Success`,
			props<{ correlationParams?: CorrelationParams }>()
		),
		LoadEntityFailure: createTypedAction(
			EntityActionType.LoadEntityFailure,
			`[${storeName}] Load ${entityName} Failure`,
			props<{ error: Error }>()
		),
		CreateEntityConfirm: createTypedAction(
			EntityActionType.CreateEntityConfirm,
			`[${storeName}] Create ${entityName} Confirm`,
			props<{ entity: Entity, isDirty?: boolean }>()
		),
		CreateEntityRequest: createTypedAction(
			EntityActionType.CreateEntityRequest,
			`[${storeName}] Create ${entityName} Request`,
			(entity: Entity, correlationParams: CorrelationParams = { correlationId: newAggregationId() }, noLoading?: boolean) => ( { entity, correlationParams, noLoading } )
		),
		CreateEntitySuccess: createTypedAction(
			EntityActionType.CreateEntitySuccess,
			`[${storeName}] Create ${entityName} Success`,
			props<{ entity: Entity, correlationParams?: CorrelationParams }>()
		),
		CreateEntityFailure: createTypedAction(
			EntityActionType.CreateEntityFailure,
			`[${storeName}] Create ${entityName} Failure`,
			props<{ error: Error }>()
		),
		UpdateEntityConfirm: createTypedAction(
			EntityActionType.UpdateEntityConfirm,
			`[${storeName}] Update ${entityName} Confirm`,
			props<{ entity: Entity, isDirty?: boolean }>()
		),
		UpdateEntityRequest: createTypedAction(
			EntityActionType.UpdateEntityRequest,
			`[${storeName}] Update ${entityName} Request`,
			(entity: Entity, correlationParams: CorrelationParams = { correlationId: newAggregationId() }, noLoading?: boolean) => ( { entity, correlationParams, noLoading } )
		),
		UpdateEntitySuccess: createTypedAction(
			EntityActionType.UpdateEntitySuccess,
			`[${storeName}] Update ${entityName} Success`,
			props<{ entity: Entity, correlationParams?: CorrelationParams }>()
		),
		UpdateEntityFailure: createTypedAction(
			EntityActionType.UpdateEntityFailure,
			`[${storeName}] Update ${entityName} Failure`,
			props<{ error: Error }>()
		),
		PreviewEntityRequest: createTypedAction(
			EntityActionType.PreviewEntityRequest,
			`[${storeName}] Preview ${entityName} Request`,
			(entity: Entity, correlationParams: CorrelationParams = { correlationId: newAggregationId() }) => ( { entity, correlationParams } )
		),
		PreviewEntitySuccess: createTypedAction(
			EntityActionType.PreviewEntitySuccess,
			`[${storeName}] Preview ${entityName} Success`,
			props<{ entity: Entity, correlationParams?: CorrelationParams }>()
		),
		PreviewEntityFailure: createTypedAction(
			EntityActionType.PreviewEntityFailure,
			`[${storeName}] Preview ${entityName} Failure`,
			props<{ error: Error }>()
		),
		DeleteEntityConfirm: createTypedAction(
			EntityActionType.DeleteEntityConfirm,
			`[${storeName}] Delete ${entityName} Confirm`,
			props<{ entity?: Entity, isDirty?: boolean, shouldRedirect?: boolean }>()
		),
		DeleteEntityRequest: createTypedAction(
			EntityActionType.DeleteEntityRequest,
			`[${storeName}] Delete ${entityName} Request`,
			(entity?: Entity, correlationParams: CorrelationParams = { correlationId: newAggregationId() }, shouldRedirect?: boolean) => ( {
				entity, correlationParams, shouldRedirect
			} )
		),
		DeleteEntityByIdRequest: createTypedAction(
			EntityActionType.DeleteEntityByIdRequest,
			`[${storeName}] Delete ${entityName} By ID Request`,
			(id: number, correlationParams: CorrelationParams = { correlationId: newAggregationId() }, shouldRedirect?: boolean) => ( {
				id, correlationParams, shouldRedirect
			} )
		),
		DeleteEntitySuccess: createTypedAction(
			EntityActionType.DeleteEntitySuccess,
			`[${storeName}] Delete ${entityName} Success`,
			props<{ entity?: Entity, correlationParams?: CorrelationParams, shouldRedirect?: boolean }>()
		),
		DeleteEntityFailure: createTypedAction(
			EntityActionType.DeleteEntityFailure,
			`[${storeName}] Delete ${entityName} Failure`,
			props<{ error: Error }>()
		),
		ExportEntityRequest: createTypedAction(
			EntityActionType.ExportEntityRequest,
			`[${storeName}] Export ${entityName} Request`,
			(correlationParams: CorrelationParams = {correlationId: newAggregationId()}) => ({correlationParams})
		),

		ExportEntitySuccess: createTypedAction(
			EntityActionType.ExportEntitySuccess,
			`[${storeName}] Export ${entityName} Success`,
			props<{ correlationParams?: CorrelationParams }>()
		),

		ExportEntityFailure: createTypedAction(
			EntityActionType.ExportEntityFailure,
			`[${storeName}] Export ${entityName} Failure`,
			props<{ error: Error }>()
		),
	};
}

export function createEntityActionsBy<Entity, By>(storeName: string, entityName: string) {
	return {
		...createEntityActions<Entity>(storeName, entityName),
		UpdateEntityRequest: createTypedAction(
			EntityActionType.UpdateEntityRequest,
			`[${storeName}] Update ${entityName} Request`,
			(entity: By, correlationParams: CorrelationParams = { correlationId: newAggregationId() }) => ({ entity, correlationParams })
		)
	};
}

export function entitySliceRequestReducer<S extends object, A extends { noLoading?: boolean }>(state: S, slice: keyof S, action?: A): S {
	return {
		...state,
		[ slice ]: {
			...state[ slice ],
			isLoading: !action?.noLoading,
			error: false
		}
	};
}

export function entitySliceSuccessReducer<S extends object, A extends { entity: any }>(state: S, slice: keyof S, action?: A) {
	return {
		...state,
		[ slice ]: {
			...state[ slice ],
			isLoading: false,
			error: false,
			entity: action ? action.entity : state[ slice ][ 'entity' ]
		}
	};
}

export function entitySliceFailureReducer<S extends object, A extends { error: any }>(state: S, slice: keyof S, action: A) {
	return {
		...state,
		[ slice ]: {
			...state[ slice ],
			isLoading: false,
			error: action.error
		}
	};
}

export function entitySliceExportLoadingReducer<S extends object, A>(state: S, slice: keyof S, _action: A) {
	return {
		...state,
		[ slice ]: {
			...state[ slice ],
			isExporting: true,
			exportError: null,
		}
	};
}

export function entitySliceExportSuccessReducer<S extends object, A>(state: S, slice: keyof S, _action: A) {
	return {
		...state,
		[ slice ]: {
			...state[ slice ],
			isExporting: false,
			exportError: null,
		}
	};
}

export function entitySliceExportFailureReducer<S extends object, A extends { error: any }>(state: S, slice: keyof S, action: A) {
	return {
		...state,
		[ slice ]: {
			...state[ slice ],
			isExporting: false,
			exportError: action.error,
		}
	};
}

type DebugStmt<S extends object> = (slice, state, action, result) => S;
const noop: DebugStmt<any> = (_, __, ___, r) => r;

export function Trace<S extends object>(slice, _, action, result): S {
	console.log('slice', slice, 'after', action?.type, 'is now', result);
	return result;
}

/**
 * Reducers
 */
export type EntityReducer<S> = ReducerTypes<S, readonly ActionCreator<string, Creator<any[], object>>[]>;

export function withEntityReducerUnsafe<S extends object>(slice: keyof S, {
	LoadEntityByIdRequest, LoadEntityRequest, CreateEntityRequest, UpdateEntityRequest,
	LoadEntitySuccess, CreateEntitySuccess, UpdateEntitySuccess,
	LoadEntityFailure, CreateEntityFailure, UpdateEntityFailure,
	PreviewEntityRequest, PreviewEntitySuccess, PreviewEntityFailure,
	DeleteEntityRequest, DeleteEntitySuccess, DeleteEntityFailure,
	ExportEntityRequest, ExportEntitySuccess, ExportEntityFailure,
}, debug: DebugStmt<S> = noop) {
	return [
		on(LoadEntityRequest,     (state: S, action) => ( debug(slice, state, action, entitySliceRequestReducer(state, slice, action)) )),
		on(CreateEntityRequest,   (state: S, action) => ( debug(slice, state, action, entitySliceRequestReducer(state, slice, action)) )),
		on(UpdateEntityRequest,   (state: S, action) => ( debug(slice, state, action, entitySliceRequestReducer(state, slice, action)) )),
		on(LoadEntityByIdRequest, (state: S, action) => ( debug(slice, state, action, entitySliceRequestReducer(state, slice, action)) )),
		on(PreviewEntityRequest,  (state: S, action) => ( debug(slice, state, action, entitySliceRequestReducer(state, slice, action)) )),
		on(DeleteEntityRequest,   (state: S, action) => ( debug(slice, state, action, entitySliceRequestReducer(state, slice, action)) )),
		on(ExportEntityRequest,   (state: S, action) => ( debug(slice, state, action, entitySliceExportLoadingReducer(state, slice, action)) )),

		on(LoadEntitySuccess,    <A extends { entity: any }>(state: S, action: A) => ( debug(slice, state, action, entitySliceSuccessReducer(state, slice, action)) )),
		on(CreateEntitySuccess,  <A extends { entity: any }>(state: S, action: A) => ( debug(slice, state, action, entitySliceSuccessReducer(state, slice, action)) )),
		on(UpdateEntitySuccess,  <A extends { entity: any }>(state: S, action: A) => ( debug(slice, state, action, entitySliceSuccessReducer(state, slice, action)) )),
		on(PreviewEntitySuccess, <A extends { entity: any }>(state: S, action: A) => ( debug(slice, state, action, entitySliceSuccessReducer(state, slice, action)) )),
		on(DeleteEntitySuccess,  <A extends { entity: any }>(state: S, action: A) => ( debug(slice, state, action, entitySliceSuccessReducer(state, slice, action)) )),
		on(ExportEntitySuccess,  <A extends { entity: any }>(state: S, action: A) => ( debug(slice, state, action, entitySliceExportSuccessReducer(state, slice, action)) )),

		on(LoadEntityFailure,    <A extends { error: any }>(state: S, action: A) => ( debug(slice, state, action, entitySliceFailureReducer(state, slice, action)) )),
		on(CreateEntityFailure,  <A extends { error: any }>(state: S, action: A) => ( debug(slice, state, action, entitySliceFailureReducer(state, slice, action)) )),
		on(UpdateEntityFailure,  <A extends { error: any }>(state: S, action: A) => ( debug(slice, state, action, entitySliceFailureReducer(state, slice, action)) )),
		on(PreviewEntityFailure, <A extends { error: any }>(state: S, action: A) => ( debug(slice, state, action, entitySliceFailureReducer(state, slice, action)) )),
		on(DeleteEntityFailure,  <A extends { error: any }>(state: S, action: A) => ( debug(slice, state, action, entitySliceFailureReducer(state, slice, action)) )),
		on(ExportEntityFailure,  <A extends { error: any }>(state: S, action: A) => ( debug(slice, state, action, entitySliceExportFailureReducer(state, slice, action)) )),
	];
}

export function withEntityReducer<S extends object>(slice: keyof S, types, debug: DebugStmt<S> = noop): EntityReducer<S>[] {
	return withEntityReducerUnsafe(slice, types, debug).map(on => on as EntityReducer<S>)
}

/**
 * Selectors
 */
export interface IStoreEntityStateSelectors<Entity> {
	getSlice: (state: IStoreEntityStateSlice<Entity>) => IStoreEntityStateSlice<Entity>;
	selectSlice: MemoizedSelector<object, IStoreEntityStateSlice<Entity>>;

	getError: (state: IStoreEntityStateSlice<Entity>) => any;
	selectError: MemoizedSelector<object, any>;

	getIsLoading: (state: IStoreEntityStateSlice<Entity>) => boolean;
	selectIsLoading: MemoizedSelector<object, boolean>;

	getEntity: (state: IStoreEntityStateSlice<Entity>) => Entity;
	selectEntity: MemoizedSelector<object, Entity>;

	getExportError: (state: IStoreEntityStateSlice<Entity>) => any;
	selectExportError: MemoizedSelector<object, any>;

	getIsExporting: (state: IStoreEntityStateSlice<Entity>) => boolean;
	selectIsExporting: MemoizedSelector<object, boolean>;
}

export function createVoidSliceSelectors<T>(sliceSelector: MemoizedSelector<object, IStoreVoidEntityStateSlice>): IStoreEntityStateSelectors<void> {
	return createEntitySliceSelectors<T, void>(sliceSelector as MemoizedSelector<object, IStoreEntityStateSlice<void>>)
}

export function createEntitySliceSelectors<T, Entity>(sliceSelector: MemoizedSelector<object, IStoreEntityStateSlice<Entity>>): IStoreEntityStateSelectors<Entity> {

	const getError = (state: IStoreEntityStateSlice<Entity>): any => state.error;
	const selectError = createSelector<State<T>, any, any>(sliceSelector, getError);

	const getIsLoading = (state: IStoreEntityStateSlice<Entity>): boolean => state.isLoading;
	const selectIsLoading = createSelector<State<T>, any, boolean>(sliceSelector, getIsLoading);

	const getEntity = (state: IStoreEntityStateSlice<Entity>): Entity => state.entity;
	const selectEntity = createSelector<State<T>, any, Entity>(sliceSelector, getEntity);

	const getSlice = (state: IStoreEntityStateSlice<Entity>): IStoreEntityStateSlice<Entity> => state;
	const selectSlice = createSelector<State<T>, any, IStoreEntityStateSlice<Entity>>(sliceSelector, getSlice);

	const getExportError = (state: IStoreEntityStateSlice<Entity>): any => state.exportError;
	const selectExportError = createSelector<State<T>, any, any>(sliceSelector, getExportError);

	const getIsExporting = (state: IStoreEntityStateSlice<Entity>): boolean => state.isExporting;
	const selectIsExporting = createSelector<State<T>, any, boolean>(sliceSelector, getIsExporting);

	return {
		getSlice,
		selectSlice,
		getEntity,
		selectEntity,
		getError,
		selectError,
		getIsLoading,
		selectIsLoading,
		getExportError,
		selectExportError,
		getIsExporting,
		selectIsExporting,
	};
}

