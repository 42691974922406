<debtor-kpis
    [data]="kpis$ | async"
    [currency]="currency$ | async"
/>

<section [class.block-loader]="loader.isLoading">
    <loader-spinner [model]="loader"/>

    @if (list$ | async; as list) {
        <div class="row mt-5">
            <h2 class="col-12 col-lg-8">
                {{ 'AccountingDocuments.List.Title' | translate }}
                @if (routeParams$ | async; as params) {
                    <span> - {{ 'AccountingDocuments.List.SubtitleDunning' | translate: params }}</span>
                }
            </h2>
            <div class="mb-3 d-flex justify-content-end col-12 col-lg-4">
                @if (payLink$ | async) {
                    <button
                        class="btn btn-primary mr-2"
                        translate="Actions.Pay" data-cy="Pay"
                        (click)="onPay()"
                    ></button>
                }
                <button
                    class="btn btn-secondary btn-badged"
                    translate="Actions.Discuss" data-cy="Discuss"
                    [ngbTooltip]="'AccountingDocuments.Tooltip.Discuss' | translate"
                    triggers="hover"
                    (click)="onDiscuss()"
                >
                    @if (list.meta.hasNewComment) {
                        <span class="badge badge-pill badge-pill-icon-big">
                            <i class="fas fa-exclamation-circle"></i>
                        </span>
                    }
                </button>
            </div>
        </div>

        <accounting-list-table
            [list]="list"
            [listHandler]="listHandler"
            [currency]="currency$ | async"
        />

        <layout-list-paging
            [list]="list"
            [listHandler]="listHandler"
        />
    }
</section>
