import { createAction, props } from '@ngrx/store';
import { INotificationItem } from 'apps/debtor-portal/src/app/shared-module/models';
import { NavigationExtras } from '@angular/router';

import { createEntityActions } from '../functions';

const ActionPrefix = 'Notifications';

export const LoadNotificationsEntityActions = createEntityActions<INotificationItem[]>(ActionPrefix, 'List');

export const {
	LoadEntityRequest: LoadNotificationsRequest,
	LoadEntitySuccess: LoadNotificationsSuccess,
	LoadEntityFailure: LoadNotificationsFailure,
} = LoadNotificationsEntityActions;

export const ToggleList = createAction(`[${ActionPrefix}] Toggle list`);
export const OpenList = createAction(`[${ActionPrefix}] Open list`);
export const CloseList = createAction(`[${ActionPrefix}] Close list`);
export const ShowUnreadOnly = createAction(`[${ActionPrefix}] Show unread only`);
export const ShowAll = createAction(`[${ActionPrefix}] Show all`);

export const SelectRequest = createAction(
	`[${ActionPrefix}] Select Notification Request`,
	(id: number, route?: string, extras?: NavigationExtras) => ({ id, route, extras })
);

export const SelectSuccess = createAction(
	`[${ActionPrefix}] Select Notification Success`,
	(id: number, route?: string, extras?: NavigationExtras) => ({ id, route, extras })
);

export const SelectFailure = createAction(
	`[${ActionPrefix}] Select Notification Failure`,
	props<{ error:  Error }>()
);

export const sendPaymentLinkNotificationToMOActions = createEntityActions(ActionPrefix, 'Send Payment Link Notification To MO');

export const {
	LoadEntityRequest: SendPaymentLinkNotificationToMORequest,
	LoadVoidEntitySuccess: SendPaymentLinkNotificationToMOSuccess,
	LoadEntityFailure: SendPaymentLinkNotificationToMOFailure,
} = sendPaymentLinkNotificationToMOActions;