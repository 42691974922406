import { Pipe, PipeTransform } from '@angular/core';

import { DunningActionType } from '../../accounting-module/enums';
import { IDunningAction } from '../../accounting-module/models';


type DAWithTypes = { type?:DunningActionType };

@Pipe({
	name: 'daType',
	standalone: true,
	pure: true,
})
export class DunningActionTypePipe implements PipeTransform {
	transform(type: DunningActionType | DAWithTypes): string {
		const useObj = !!type['type'];
		const action: IDunningAction = useObj ? (type as unknown as IDunningAction) : null;
		const daType = useObj ? action.type : type;
		return 'DunningActions.Types.' + daType
	}
}
