<div
    class="user-avatar user-avatar-{{ user.size }} user-avatar-{{ user.color }}"
    [ngbTooltip]="isSystem || hasFullName ? null : user.fullName"
    placement="left"
    container="body"
>
    @if (!isSystem) {
        <div class="user-avatar-img">{{ user.initials }}</div>
    }
    @if (hasFullName) {
        <div class="user-avatar-name">{{ user.fullName }}</div>
    }
    @if (isSystem) {
        <div class="user-avatar-name">
            {{ 'CommonTexts.System' | translate }}
        </div>
    }
</div>
