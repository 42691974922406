import { TranslateModule } from '@ngx-translate/core';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AmountPipe, DateFormatPipe, ListPagingComponent, LoaderSpinnerComponent, PluralPipe } from '@aston/foundation';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccountingDocumentTypePipe, DunningActionTypePipe } from '../shared-module/pipes';
import { CommentsBlockComponent } from '../comments-module/components/comments-block/comments-block.component';

import { AccountingListTableComponent } from './components/accounting-list-table/accounting-list-table.component';
import { AccountingDocumentsService, DunningActionsService } from './services';
import { AccountingListTableRowComponent } from './components/accounting-list-table-row/accounting-list-table-row.component';
import { DebtorKpisComponent } from './components/debtor-kpis/debtor-kpis.component';
import { DunningAccountingPageComponent } from './pages/dunning-accounting-page/dunning-accounting-page.component';
import { DunningAccountingDiscussComponent } from './components/dunning-accounting-discuss/dunning-accounting-discuss.component';
import { DunningActionsPageComponent } from './pages/dunning-actions-page/dunning-actions-page.component';
import { DunningActionListTableRowComponent } from './components/actions-list-table-row/actions-list-table-row.component';
import { ActionsListTableComponent } from './components/actions-list-table/actions-list-table.component';
import { DunningInvoicingPageComponent } from './pages/dunning-invoicing-page/dunning-invoicing-page.component';



@NgModule({
	declarations: [
		AccountingListTableComponent,
		AccountingListTableRowComponent,
		ActionsListTableComponent,
		DebtorKpisComponent,
		DunningAccountingDiscussComponent,
		DunningAccountingPageComponent,
		DunningAccountingPageComponent,
		DunningActionListTableRowComponent,
		DunningActionsPageComponent,
		DunningInvoicingPageComponent,
	],
	exports: [
		AccountingListTableComponent,
		DebtorKpisComponent,
	],
	imports: [
		AccountingDocumentTypePipe,
		AmountPipe,
		CommentsBlockComponent,
		CommonModule,
		DateFormatPipe,
		DunningActionTypePipe,
		FormsModule,
		ListPagingComponent,
		LoaderSpinnerComponent,
		NgbNavModule,
		NgbTooltipModule,
		PluralPipe,
		ReactiveFormsModule,
		RouterModule,
		TranslateModule,
	],
	providers: [
		AccountingDocumentsService,
		DunningActionsService,
	],
})
export class AccountingModule { }
