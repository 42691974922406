import { environmentCommon } from './environment.common';

export const environment = {
	...environmentCommon,
	name: 'Development',
	production: false,
	staging: false,
	dev: true,
	webApi: '/api',
};
