import { BehaviorSubject } from 'rxjs';

import { IList, IListState, ISort, IPaging } from '../models';
import { deepCopy } from '../functions/utils.function';
import { AppConstants } from '../config';
import { emptyList } from '../functions';

import { Loader } from './loader.class';


export class SortedFilteredList<ItemModel, SortPropEnum> {

	list: IList<ItemModel> = emptyList();

	loader = new Loader();

	private _state = new BehaviorSubject<IListState<SortPropEnum>>({});
	readonly state$ = this._state.asObservable();

	constructor(sort?: ISort<SortPropEnum>, pageSize: number = AppConstants.LIST_PAGE_SIZE_DEFAULT) {
		this._state.next({
			page: 1,
			pageSize: pageSize,
			originalPageSize: AppConstants.LIST_PAGE_SIZE_DEFAULT,
			sorts: sort ? [sort] : [],
			filters: [],
		});
	}

	setList(list: IList<ItemModel>) {
		this.list = list;
	}

	get items(): ItemModel[] {
		return this.list.items || [];
	}

	get nbItems(): number {
		return this.items.length;
	}

	get paging(): IPaging | undefined {
		return this.list.paging;
	}

	get meta(): any {
		return this.list.meta || {};
	}

	get state(): IListState<SortPropEnum> {
		// Creates a copy of the original state => Prevents children components to modify the reference
		return deepCopy(this._state.value);
	}

	get shouldShowPagination(): boolean {
		if (!this.list.paging?.totalPageCount) return false;

		return  this.list.paging.totalPageCount > 1
			|| (this.list.paging.totalItemCount ?
				this.list.paging.totalItemCount : 0) > AppConstants.LIST_PAGE_SIZES[0];
	}

	setPageSize(pageSize: number, originalPageSize?: number) {
		this._state.next({
			...this.state,
			pageSize: pageSize,
			originalPageSize: originalPageSize || pageSize,
			page: 1 // Restart from the first page
		});
	}

	setPageNumber(page: number) {
		this._state.next({
			...this.state,
			page: page
		});
	}

	setSort(sort: ISort<SortPropEnum>) {
		this._state.next({
			...this.state,
			sorts: [sort],
			page: 1 // Restart from the first page
		});
	}

	setSorts(sorts: ISort<SortPropEnum>[]) {
		this._state.next({
			...this.state,
			sorts,
			page: 1 // Restart from the first page
		});
	}
}
