import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, switchMap, withLatestFrom, debounceTime, tap } from 'rxjs/operators';
import { AccountingDocumentsService } from 'apps/debtor-portal/src/app/accounting-module/services';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from '@aston/foundation';
import { saveAs } from 'file-saver';
import { DocumentFilesViewerComponent } from 'apps/debtor-portal/src/app/shared-module/components/document-files-viewer/document-files-viewer.component';

import { CommentsStoreActions } from '../../comments-store';
import { catchWithAppError as catchError } from '../../app-store';
import * as notifsActions from '../../notifications-store/actions';
import * as appSelectors from '../../app-store/selectors';
import * as appActions from '../../app-store/actions';
import * as featureSelectors from '../selectors';
import * as featureActions from '../actions';


@Injectable({providedIn: 'root'})
export class AccountingStoreAccountingEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		protected modalService: NgbModal,
		protected translateService: TranslateService,
		private accountingService: AccountingDocumentsService,
	) { }

	onDebug$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.Debug),
		tap(action => console.log(`Debug action %o triggered by`, action.message, action.origin))
	), { dispatch: false });

	loadKpis$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDebtorKPIsRequest),
		switchMap(_ => this.accountingService.getDebtorKPIs().pipe(
			map(entity => featureActions.LoadDebtorKPIsSuccess({ entity })),
			catchError(error => of(featureActions.LoadDebtorKPIsFailure({error})))
		))
	));

	loadAccountingByDAIdEffect$ = createEffect(() => this.actions$.pipe(
		ofType(
			featureActions.LoadAccountingRequestForDunningActionId,
			featureActions.LoadAccountingRequest,
			CommentsStoreActions.DiscussModalClose,
		),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		debounceTime(500),
		switchMap(([_, state]) => {
			const { paging } = state.documentsList;
			return this.accountingService.getAccountingDocumentsByDunningActionId(state.currentDunningActionId, { ...paging }).pipe(
				map(list => featureActions.LoadAccountingSuccess({ list })),
				catchError(error => of(featureActions.LoadAccountingFailure({ error })))
			);
		})
	));

	onOpenDocument$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenAccountingDocument),
		tap(action => {
			const modalRef = this.modalService.open(DocumentFilesViewerComponent, AppConstants.DEFAULT_MODAL_OPTIONS);
			modalRef.componentInstance.item = { id: action.accountingDocumentId };
			modalRef.componentInstance.title = this.translateService.instant('PageTitles.AccountingDetail', { data: { id: action.accountingNumber } });
		})
	), { dispatch: false });

	loadSuccessEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAccountingSuccess),
		map(_ => appActions.StartScrollToTop())
	));

	exportFactoringEventsEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ExportAccountingRequest),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		switchMap(([action, state]) => {
			const { paging } = state.documentsList;
			return this.accountingService.exportAccountingList({ ...paging }).pipe(
				map(response => {
					saveAs(response.blob, response.fileName);
					return featureActions.ExportAccountingSuccess({ correlationParams: action.correlationParams });
				}),
				catchError(error => of(
					featureActions.ExportAccountingFailure({ error }),
					appActions.ToastError('Errors.RetryableError')
				))
			);
		})
	));

	openPayLinkModal$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenPaymentModal),
		withLatestFrom(this.store.select(appSelectors.selectTenant)),
		map(([_, tenant]) => {
			return appActions.OpenConfirmationModal(
				{
					textsKey: 'AccountingDocuments.Options.PayLink.',
					cancelBtnText: 'AccountingDocuments.Options.PayLink.Cancel',
					confirmBtnText: 'AccountingDocuments.Options.PayLink.Confirm',
				},
				[
					appActions.OpenUrl({url: tenant.paymentLinkClipUrl, fileName: 'test'}),
					notifsActions.SendPaymentLinkNotificationToMORequest(),
					appActions.DiscardFocus(),
				],
				[
					appActions.DiscardFocus()
				])
		})
	));

	updateSettingsAction$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateAccountingSettings),
		map(_ => featureActions.LoadAccountingRequest())
	));
}
