

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { LoaderSpinnerComponent } from '@aston/foundation';

import { AuthenticationIssuePageComponent } from './pages/authent-issue-page/authentication-issue-page.component';
import { InactivityModalComponent } from './components/inactivity-modal/inactivity-modal.component';
import { AuthenticationGuard, AnonymousGuard } from './guards';
import { AuthenticationInterceptor } from './interceptors';
import { AuthenticationService } from './services';
import { AuthenticationLayoutComponent } from './layouts/authentication-layout/authentication-layout.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';

@NgModule({
	declarations: [
		AuthenticationIssuePageComponent,
		AuthenticationLayoutComponent,
		InactivityModalComponent,
		LoginPageComponent
	],
	imports: [
		CommonModule,
		FormsModule,
		LoaderSpinnerComponent,
		ReactiveFormsModule,
		RouterModule,
		TranslateModule,
	],
	providers: [
		AnonymousGuard,
		AuthenticationGuard,
		AuthenticationService,
		{provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true}
	],
})
export class AuthenticationModule {
}
