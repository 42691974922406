
import { Observable, OperatorFunction, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { HttpErrorMessage } from '@aston/foundation';

import { ErrorLog } from './actions';

export function catchWithAppError<T, A extends Action>(selector: (err: HttpErrorMessage) => Observable<T>): OperatorFunction<A, Action|T|A> {
	return catchError(originalError => selector(originalError).pipe(
		switchMap(appError => of(ErrorLog(originalError), appError))
	));
}
