import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineToList, StoreLoader } from '@aston/foundation';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AccountingStoreActions, AccountingStoreSelectors } from 'apps/debtor-portal/src/app/root-store/accounting-store';
import { AppStoreSelectors } from 'apps/debtor-portal/src/app/root-store/app-store';
import { ListActionHandler } from 'apps/debtor-portal/src/app/shared-module/handlers';

import { DunningActionListSortProp } from '../../enums';
import { IDunningActionList, IDunningAction } from '../../models';

@Component({
	selector: 'dunning-actions-page',
	templateUrl: './dunning-actions-page.component.html'
})
export class DunningActionsPageComponent implements OnInit {

	payLink$ = this.store.select(AppStoreSelectors.selectTenant).pipe(map(t => t.paymentLinkClipUrl));
	currency$ = this.store.select(AppStoreSelectors.selectTenant).pipe(map(t => t.currencyCodeInvoicing));

	kpis$ = this.store.select(AccountingStoreSelectors.selectDebtorKPIs);

	loader = new StoreLoader(
		this.store.select(AccountingStoreSelectors.selectDebtorKPIsIsLoading),
		this.store.select(AccountingStoreSelectors.selectDebtorKPIsError));

	list$ = combineToList<IDunningActionList, IDunningAction, DunningActionListSortProp>(
		this.store.select(AccountingStoreSelectors.selectDunningActions),
		this.store.select(AccountingStoreSelectors.selectDunningActionsPaging));

	routeParams$ = this.route.params;

	listHandler: ListActionHandler;

	constructor(
		private store: Store,
		private route: ActivatedRoute,
	) {}

	ngOnInit() {
		this.store.dispatch(AccountingStoreActions.LoadDebtorKPIsRequest());
		this.store.dispatch(AccountingStoreActions.LoadDunningActionsRequest())

		this.listHandler = new ListActionHandler()
			.withPagingDispatcher(this.dispatchPagingAction.bind(this));

	}

	dispatchPagingAction(props) {
		this.store.dispatch(AccountingStoreActions.UpdateDunningActionsSettings(props));
	}
}
