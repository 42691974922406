const ASTON_TENANT_ID = '__aston_federation_id';

export function guessTenantId(): string {
	const queryParams: any = location.search.slice(1).split('&').reduce((acc, q) => {
		const param = q.split('=');
		return {...acc, [param[0].toLowerCase()]: param[1]}
	}, {});

	// first, test the query param
	if (queryParams.tenant_id) {
		return queryParams.tenant_id;
	}

	// then, the numeric base url
	const fromUrl = location.pathname.split('/')[1];
	if (fromUrl.length && Number.isInteger(+fromUrl)) {
		return fromUrl;
	}

	// then, the sessionStorage
	// used by authentication between /login and /callback
	const fromStorage = sessionStorage.getItem(ASTON_TENANT_ID);
	if (fromStorage) {
		return fromStorage;
	}

	return null;
}

export function currentTenantId(): string {
	let tenantId = window[ASTON_TENANT_ID];
	if (!tenantId) {
		tenantId = guessTenantId();
		if (tenantId) {
			window[ASTON_TENANT_ID] = tenantId;
			sessionStorage.setItem(ASTON_TENANT_ID, tenantId);
		}
	}
	return tenantId;
}
