import { ListActionHandlerBase } from '@aston/foundation';
import { Store } from '@ngrx/store';

export class ListActionHandler extends ListActionHandlerBase {
	domain: string;
	store: Store;

	withPagingDispatcherAndDomain(pagingDispatcher: any, domain: string, store: Store): this {
		super.withPagingDispatcher(pagingDispatcher);
		this.domain = domain;
		this.store = store;
		return this;
	}
}
