import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { currentTenantId } from './app/authentication-module/functions/utils.functions';

// check/store the tenant id before any redirection
console.log(`[init] app as T${currentTenantId()}`);

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
								.catch(err => console.error(err));
