
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ICommentItem } from 'apps/debtor-portal/src/app/comments-module/models';
import { ICommentModalContent } from 'apps/debtor-portal/src/app/comments-module/models/comment-modal-content.model';

import { createEntitySlice, IStoreEntityStateSlice } from '../functions';

export interface ICommentsState {
	discussForm: IStoreEntityStateSlice<ICommentModalContent>;
	discussFormModalRef: NgbModalRef;
	comments: IStoreEntityStateSlice<ICommentItem[]>;
	previewDocumentId: IStoreEntityStateSlice<number>;
	dunningActionId: number;
	accountingDocumentId: number;
}

export const initialState: ICommentsState = {
	discussForm: createEntitySlice(),
	discussFormModalRef: null,
	comments: createEntitySlice(),
	previewDocumentId: createEntitySlice(),
	dunningActionId: null,
	accountingDocumentId: null,
};
