import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, filter, debounceTime } from 'rxjs/operators';
import { NotificationsService } from 'apps/debtor-portal/src/app/shared-module/services/notifications.service';
import { Router } from '@angular/router';
import { WsEventType } from 'apps/debtor-portal/src/app/shared-module/enums';
import { WebSocketService } from 'apps/debtor-portal/src/app/shared-module/services';

import * as featureActions from './actions';

@Injectable({providedIn: 'root'})
export class NotificationsStoreEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private notificationsService: NotificationsService,
		private ws: WebSocketService,
		private router: Router) { }

	// loadRequestEffect$ = createEffect(() => this.actions$.pipe(
	// 	ofType(featureActions.LoadNotificationsRequest),
	// 	switchMap(action =>
	// 		this.notificationsService.getNotifications().pipe(
	// 			map(list => featureActions.LoadNotificationsSuccess({ entity: list })),
	// 			catchError(error =>
	// 				of(featureActions.LoadNotificationsFailure({ error }))
	// 			)
	// 		)
	// 	)
	// ));

	selectRequestEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.SelectRequest),
		switchMap(action =>
			this.notificationsService.markAsRead(action.id).pipe(
				map(_ => featureActions.SelectSuccess(action.id, action.route, action.extras)),
				catchError(error =>
					of(featureActions.SelectFailure({error}))
				)
			)
		)
	));

	selectSuccessEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.SelectSuccess),
		filter(action => !!action.route),
		switchMap(action => this.router.navigate([action.route], action.extras)
	)), { dispatch: false });

	onWSReloadEvent$ = createEffect(() => this.ws.ofType(WsEventType.RefreshUserNotifications).pipe(
		debounceTime(500),
		map(_ => featureActions.LoadNotificationsRequest())
		));

	sendPaymentLinkNotificationToMO$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.SendPaymentLinkNotificationToMORequest),
		switchMap(_ =>
			this.notificationsService.sendNotificationToMO().pipe(
				map(_ => featureActions.SendPaymentLinkNotificationToMOSuccess(null)),
				catchError(error =>
					of(featureActions.SendPaymentLinkNotificationToMOFailure({ error }))
				)
			)
		)
	));

}
