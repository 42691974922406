

import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map, withLatestFrom, tap, catchError, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ActionType, AppConstants, StoreLoader } from '@aston/foundation';
import { DunningAccountingDiscussComponent } from 'apps/debtor-portal/src/app/accounting-module/components/dunning-accounting-discuss/dunning-accounting-discuss.component';
import { CommentsService } from 'apps/debtor-portal/src/app/comments-module/services/comments.service';
import { DocumentFileService } from 'apps/debtor-portal/src/app/shared-module/services';
import { CommentBearing } from 'apps/debtor-portal/src/app/comments-module/enums';

import { AppStoreActions, AppStoreSelectors } from '../app-store';

import * as featureSelectors from './selectors';
import * as featureActions from './actions';

@Injectable({providedIn: 'root'})
export class CommentsStoreEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		protected modalService: NgbModal,
		protected translateService: TranslateService,
		protected commentsService: CommentsService,
		protected docFileService: DocumentFileService,
	) { }

	loadDunningActionCommentsRequest$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDunningActionCommentsRequest),
		withLatestFrom(this.store.select(featureSelectors.selectDunningActionId)),
		switchMap(([_, dunningActionId]) => {
			return this.commentsService.getDunningActionComments(dunningActionId).pipe(
				map(entity => featureActions.LoadDunningActionCommentsSuccess({ entity })),
				catchError(error =>
					of(featureActions.LoadDunningActionCommentsFailure({ error }))
				)
			)}
		)
	));

	loadAccountingDocumentCommentsRequest$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadAccountingDocumentCommentsRequest),
		withLatestFrom(this.store.select(featureSelectors.selectAccountingDocumentId)),
		switchMap(([_, accountingDocumentId]) => {
			return this.commentsService.getAccountingDocumentComments(accountingDocumentId).pipe(
				map(entity => featureActions.LoadAccountingDocumentCommentsSuccess({ entity })),
				catchError(error =>
					of(featureActions.LoadAccountingDocumentCommentsFailure({ error }))
				)
			)}
		)
	));

	previewActionDocument$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.PreviewActionDocumentRequest),
		switchMap(({id}) => this.docFileService.updateWithLocalDunningActionDocumentURL({
			id: null,
			size: null,
			mimeType: 'application/pdf',
			name: this.translateService.instant('DunningActions.DocumentTitle', { id }),
		}, id).pipe(
			switchMap(document => [
				featureActions.PreviewActionDocumentSuccess({entity: null}),
				AppStoreActions.ViewDocument({ document })
			]),
			catchError(error => of(featureActions.PreviewActionDocumentFailure({ error })))
		))
	));

	discussModalOpen$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DiscussModalOpen),
		withLatestFrom(this.store.select(AppStoreSelectors.selectState)),
		tap(([action]) => {
			const modal = this.modalService.open(DunningAccountingDiscussComponent, AppConstants.DEFAULT_MODAL_OPTIONS);
			const component = (modal.componentInstance as DunningAccountingDiscussComponent);

			component.title = 'Comments.TitleFor' + action.bearing;
			component.comments$ = this.store.select(featureSelectors.selectCommentsList);
			component.loader = new StoreLoader(
				this.store.select(featureSelectors.selectCommentsIsLoading),
				this.store.select(featureSelectors.selectCommentsError)
			);

			if (action.bearing === CommentBearing.AccountingDocument) {
				this.store.dispatch(featureActions.LoadAccountingDocumentCommentsRequest());
			} else if (action.bearing === CommentBearing.DunningAction) {
				this.store.dispatch(featureActions.LoadDunningActionCommentsRequest());
			}

			this.store.dispatch(featureActions.SetDiscussModal({ modal }));

			component.action.pipe(
				catchError(_ => {
					this.store.dispatch(featureActions.DiscussModalClose());
					return EMPTY;
				}),
				map(event => {
					const reason = event.type;

					if (reason === ActionType.CLOSE) {
						this.store.dispatch(featureActions.DiscussModalClose())
					}
					if (reason === ActionType.SUBMIT) {
						const comment = event.value;

						if (action.bearing === CommentBearing.AccountingDocument) {
							this.store.dispatch(featureActions.AddAccountingDocumentCommentRequest({ comment }));
						} else {
							this.store.dispatch(featureActions.AddDunningActionCommentRequest({ comment }));
						}
					}
				})
			).subscribe()
		})
	), { dispatch: false });

	discussModalClose$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DiscussModalClose),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		switchMap(([, state]) => {
			state.discussFormModalRef.dismiss();
			return [
				featureActions.SetDiscussModal({ modal: null }),
				AppStoreActions.DiscardFocus(),
			];
		})
	));

	addDunningActionCommentRequest$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AddDunningActionCommentRequest),
		withLatestFrom(this.store.select(featureSelectors.selectDunningActionId)),
		switchMap(([action, dunningActionId]) => {
			const comment = action.comment;
			return this.commentsService.sendDunningActionComment(dunningActionId, comment).pipe(
				switchMap(_ => [
					featureActions.AddDunningActionCommentSuccess({ comment }),
					featureActions.LoadDunningActionCommentsRequest()
				]),
				catchError(error => of(featureActions.AddDunningActionCommentFailure({ error })))
			);
		})
	));

	addAccountingDocumentCommentRequest$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.AddAccountingDocumentCommentRequest),
		withLatestFrom(this.store.select(featureSelectors.selectAccountingDocumentId)),
		switchMap(([action, accountingDocumentId]) => {
			const comment = action.comment;
			return this.commentsService.sendAccountingDocumentComment(accountingDocumentId, comment).pipe(
				switchMap(_ => [
					featureActions.AddAccountingDocumentCommentSuccess({ comment }),
					featureActions.LoadAccountingDocumentCommentsRequest()
				]),
				catchError(error => of(featureActions.AddAccountingDocumentCommentFailure({ error })))
			);
		})
	));
}
