import orderBy from 'lodash/orderBy';
import { Observable, of, EMPTY, combineLatest } from 'rxjs';
import { map, tap, switchMap, startWith, catchError } from 'rxjs/operators';

import { Loader, SortedFilteredList } from '../classes';
import { AnySort, IList, IListState, ISort } from '../models';
import { AppConstants } from '../config';



export function applyListPaging<T, S>(
	list: IList<T>,
	props: Partial<IListState<S>>,
	sorters?: { [key: string]: CallableFunction }
): SortedFilteredList<T, S> {
	let items = list.items.slice();
	const newList = new SortedFilteredList<T, S>();
	newList.setList(list);

	// handle sort
	if (props.sorts?.length) {
		if (sorters) {
			const sortFns = props.sorts.map(s => sorters[''+s.prop]);
			items = orderBy(items, sortFns, props.sorts.map(s => s.isDesc ? 'desc' : 'asc')) as T[]
		} else {
			items = orderBy(items, props.sorts.map(s => s.prop), props.sorts.map(s => s.isDesc ? 'desc' : 'asc')) as T[]
		}
		newList.setList({ ...list, items });
		newList.setPageSize(items.length);
		newList.setSorts(props.sorts);
	}

	return newList;
}

export function wrapListWithLoader<T>(source$: Observable<IList<T>>, loader: Loader): Observable<IList<T>> {
	return of(null).pipe(
		tap(_ => loader.loading(true)),
		switchMap(_ => source$.pipe(
			tap(_ => loader.loading(false)),
			startWith({ items: [] })
		)),
		catchError(_ => {
			loader.error(_);
			return EMPTY;
		})
	);
}

export function emptyList<T>(): IList<T> {
	return {
		items: [],
	}
}

// Creates a default list state (page 1, default pagination) with a default sort if provided
export function createDefaultListState<T>(defaultSort?: T, isDesc = false, pageSize: number = AppConstants.LIST_PAGE_SIZE_DEFAULT): IListState<T> {
	const returned: IListState<T> = {
		sorts: [],
		pageSize: pageSize,
		page: 1
	};

	if (defaultSort) {
		returned.sorts = [{
			prop: defaultSort,
			isDesc: isDesc
		}];
	}

	return returned;
}

export function createDefaultListStateAnySort<T>(defaultSort?: AnySort<T>, pageSize: number = AppConstants.LIST_PAGE_SIZE_DEFAULT): IListState<T> {
	const returned: IListState<T> = {
		sorts: [],
		pageSize: pageSize,
		page: 1
	};

	if (!Array.isArray(defaultSort)) {
		returned.sorts = [{ prop: defaultSort, isDesc: false }]

	} else if (typeof defaultSort[0] === 'object') {
		returned.sorts = defaultSort as ISort<T>[]

	} else if (Array.isArray(defaultSort[0])) {
		returned.sorts = (defaultSort as [T, boolean][])
			.map(([ prop, isDesc ]) => ({
				prop, isDesc }));
	} else {
		const [ prop, isDesc ] = defaultSort
		returned.sorts = [{ prop, isDesc: !!isDesc }]
	}

	return returned;
}

export function makeSortedList<TList extends IList<TItem>, TItem, TSortProp>(
	list: TList, state: IListState<TSortProp>): SortedFilteredList<TItem, TSortProp> {
	const sortedList = new SortedFilteredList<TItem, TSortProp>();

	if (state) {
		sortedList.setList(list);
		state.sorts && sortedList.setSorts(state.sorts);
		sortedList.setPageSize(state.pageSize ?? state.originalPageSize ?? 5, state.originalPageSize);
		sortedList.setPageNumber(state.page ?? 1);
	}
	return sortedList;
}

export function combineToList<TList extends IList<TItem>, TItem, TSortProp>(
	list$: Observable<TList>,
	paging$: Observable<IListState<TSortProp>>
): Observable<SortedFilteredList<TItem, TSortProp>> {
	return combineLatest([ list$, paging$ ]).pipe(
		map(([list, paging]) => makeSortedList(list, paging))
	);
}
