import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { INotification } from '../models';
import { NotificationType } from '../enums';


@Injectable({
	providedIn: 'root'
})
export class NotificationService {
	private notifications: BehaviorSubject<INotification[]> = new BehaviorSubject([] as INotification[]);
	readonly notifications$: Observable<INotification[]> = this.notifications.asObservable();

	notify(notification: INotification) {
		// Copy of the notifications array to modify it
		const notifications = this.getNotifications().slice(0);

		// In case it is null or undefined
		if (notification.autoClose !== false) {
			notification.autoClose = true;
		}
		notifications.push(notification);
		this.notifications.next(notifications);
	}
	
	success(notification: INotification) {
		this.notify({ ...notification, type: NotificationType.SUCCESS });
	}

	successMessage(key: string, autoClose = true) {
		this.success({ message: 'NotificationMessages.Success.' + key, autoClose });
	}

	error(notification: INotification) {
		this.notify({ ...notification, type: NotificationType.ERROR });
	}

	errorMessage(key: string, autoClose = true) {
		this.error({ message: 'NotificationMessages.Errors.' + key, autoClose });
	}

	warning(notification: INotification) {
		this.notify({ ...notification, type: NotificationType.WARNING });
	}

	warningMessage(key: string, autoClose = true) {
		this.warning({ message: 'NotificationMessages.Warnings.' + key, autoClose });
	}

	getNotifications(): INotification[] {
		return this.notifications.value;
	}

	remove(index: number) {
		const notifications = this.getNotifications().slice(0);
		notifications.splice(index, 1);

		this.notifications.next(notifications);
	}

	reset() {
		this.notifications.next([]);
	}
}
