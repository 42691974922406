
import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ValidationService, UnsubscriberClass, FormControlFeedbackComponent } from '@aston/foundation';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ICommentItem } from '../../models/comment.model';

@Component({
    selector: 'form-comments',
    templateUrl: './form-comments.component.html',
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, TranslateModule, FormControlFeedbackComponent]
})
export class FormCommentsComponent extends UnsubscriberClass implements OnInit {
	@Input() rows = 5;
	@Input() showCancel: boolean;
	@Output() submitForm: EventEmitter<ICommentItem> = new EventEmitter();
	@Output() cancel: EventEmitter<void> = new EventEmitter();

	form: UntypedFormGroup;

	@ViewChild('contentInput', { static: true }) contentInput: ElementRef;

	get contentField() {
		return this.form.get('content');
	}

	constructor(
		protected router: Router,
		protected formBuilder: UntypedFormBuilder,
		private validationService: ValidationService
	) {
		super();
	}

	ngOnInit() {
		this.form = this.formBuilder.group({});
		this.form.addControl('content', new UntypedFormControl(null, Validators.required));

		// reset form on navigation
		// useful when navigating to the same page with different params.
		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd && !!this.form),
			tap(_ => this.form.reset()),
			takeUntil(this.destroySubscriptions$),
		).subscribe();
	}

	onContentChange() {
		if (!this.contentField.value || !this.contentField.value.trim()) {
			this.resetForm();
		}
	}

	resetForm() {
		this.form.reset();
		this.contentInput.nativeElement.style.height = '';
	}

	getModelFromForm(): ICommentItem {
		return <ICommentItem>{
			content: this.contentField.value || ''
		};
	}

	submitComment(event) {
		event.preventDefault();
		event.stopPropagation();
		this.submitForm.emit(this.getModelFromForm());
		this.resetForm();
	}

	cancelComment() {
		this.resetForm();
		this.cancel.emit();
	}
}
