
import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { AccountingDocumentMiscOperationType, AccountingDocumentPaymentTypeCode, AccountingDocumentTypeCode } from 'apps/debtor-portal/src/app/accounting-module/enums';

export type MergedAccountingDocumentType = 	AccountingDocumentTypeCode |
											AccountingDocumentPaymentTypeCode |
											AccountingDocumentMiscOperationType;

export interface IAccountingDocumentTypeBase {
	type?: AccountingDocumentTypeCode;
	paymentType?: AccountingDocumentPaymentTypeCode;
	miscOperationType?: AccountingDocumentMiscOperationType;
}

export interface IAccountingDocumentTypePipeOptions {
	isFull?: boolean;
	displaySubType?: boolean;
	displayMiscType?: boolean;
}

@Pipe({
	name: 'accountingType',
	standalone: true,
	pure: false
})
export class AccountingDocumentTypePipe extends TranslatePipe implements PipeTransform {

	defaultOptions: IAccountingDocumentTypePipeOptions = {
		isFull: false,
		displaySubType: false,
	};

	constructor(private translateService: TranslateService, private changeDetectorRef: ChangeDetectorRef) {
		super(translateService, changeDetectorRef);
	}

	transform<T extends IAccountingDocumentTypeBase>(document: T | string, options: IAccountingDocumentTypePipeOptions): string {
		options = {
			...this.defaultOptions,
			...options
		};

		// When displaySubType but displayMiscType is unknown, assume we want displayMiscType
		if (options.displaySubType && options.displayMiscType === undefined) {
			options.displayMiscType = true;
		}

		let documentType: string;

		if (typeof document === 'string') {
			documentType = document;
		} else  {
			documentType = document.type;

			if (options.displaySubType) {
				documentType = (document.type === AccountingDocumentTypeCode.Cashing && !!document.paymentType) ?
								document.paymentType :
								(document.type === AccountingDocumentTypeCode.Misc && !!document.miscOperationType && options.displayMiscType) ?
									document.miscOperationType :
									document.type;
			}
		}

		return super.transform(`AccountingDocuments.Types.${options.isFull ? 'Full' : 'Short'}.${documentType}`);
	}
}
