<div class="modal-header">
	<h3 class="modal-title">{{ contentConfig.textsKey + 'Title' | translate: contentConfig.titleParams }}</h3>
	<button type="button" class="close" data-cy="CloseModal" *ngIf="!contentConfig.hideCancelBtn" (click)="dismiss()">
		<i class="fal fa-times"></i>
	</button>
</div>
<div class="modal-body" [formGroup]="form">
	<p>{{ (contentConfig.questionKey || contentConfig.textsKey) + 'Question' | translate: contentConfig.questionParams }}</p>

	<div class="form-group" *ngIf="contentConfig.withComment">
		<label class="form-label" for="field-comment" translate="DataList.Columns.Comment"></label>
		<textarea id="field-comment" class="form-control form-control-alt" formControlName="comment" rows="2"></textarea>
		<form-control-feedback [field]="form.get('comment')"/>
	</div>
</div>
<div class="modal-footer modal-footer-reverse">
	<button type="button" class="btn btn-primary" ngbAutofocus
		[disabled]="contentConfig.disableConfirmBtn || (contentConfig.withComment && form.get('comment').invalid)"
		(click)="submit()">{{ (contentConfig.confirmBtnText || 'CommonTexts.Yes') | translate }}</button>
	<button type="button" class="btn btn-default" *ngIf="!contentConfig.hideCancelBtn"
		(click)="dismiss()">{{ (contentConfig.cancelBtnText || 'CommonTexts.No') | translate }}</button>
</div>
