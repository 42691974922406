import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { JsonPipe, NgIf } from '@angular/common';

import { ActionType } from '../../enums';
import { IConfirmModalContent, IConfirmModalResult } from '../../models';
import { FormControlFeedbackComponent } from '../form-control-feedback/form-control-feedback.component';
import { UnsubscriberClass } from '../../classes/unsubscriber.class';

@Component({
	selector: 'app-confirm-modal',
	templateUrl: './confirm-modal.component.html',
	standalone: true,
	imports: [JsonPipe, NgIf, FormsModule, ReactiveFormsModule, TranslateModule, FormControlFeedbackComponent]
})
export class ConfirmModalComponent extends UnsubscriberClass {

	@Input() contentConfig: IConfirmModalContent;
	@Output() answer = new EventEmitter<IConfirmModalResult>();

	form: UntypedFormGroup;

	constructor(
		private activeModal: NgbActiveModal,
		private formBuilder: UntypedFormBuilder
	) {
		super();
		this.form = this.formBuilder.group({
			comment: new UntypedFormControl('', Validators.maxLength(200)),
		})
	}

	submit() {
		this.answer.next({
			action: ActionType.VALIDATE,
			comment: this.form.get('comment').value
		});
		this.activeModal.close(ActionType.VALIDATE);
	}

	dismiss() {
		this.answer.next({
			action: ActionType.CANCEL,
			comment: this.form.get('comment').value
		});
		this.activeModal.dismiss(ActionType.CANCEL)
	}
}
