import { MemoizedSelector, createSelector, on, props, ReducerTypes, ActionCreator, Creator } from '@ngrx/store';

import { IFilter, ISort, IListState } from '../models';
import { CorrelationParams, newAggregationId } from '../models';
import { AppConstants } from '../config';
import { makeFiltersPermanent, removeTransientFilters } from '../functions';

import { createTypedAction } from './action-creators.function';


/**
 * ========================================================================
 * List slice creation helpers
 * Source: https://github.com/vincent/ng-stator
 * ========================================================================
 */

/**
 * State Model
 */
export interface IStoreListStateSlice<List, Props, Filter extends IFilter> {
	list: List;
	paging: {
		sorts: ISort<Props>[],
		pageSize: number,
		originalPageSize: number,
		page: number
	};
	filters: Filter[];
	isLoading: boolean;
	isExporting?: boolean;
	exportError?: any,
	error: any;
	domain: string;
}

export function createListSlice<List, Props, Filter extends IFilter>(
	defaultSort: ISort<Props>|ISort<Props>[],
	defaultPageSize: number = AppConstants.LIST_PAGE_SIZE_DEFAULT,
	defaultFilters: Filter[] = [],
	domain?: string,
)
	: IStoreListStateSlice<List, Props, Filter> {
	return {
		list: <List> <unknown> {
			items: []
		},
		paging: {
			sorts: [].concat(defaultSort),
			pageSize: defaultPageSize,
			originalPageSize: defaultPageSize,
			page: 1
		},
		filters: defaultFilters,
		isLoading: false,
		error: null,
		isExporting: false,
		exportError: null,
		domain,
	};
}

/**
 * List Action Types
 */
export enum ListActionType {
	LoadListByIdRequest = 'LoadListByIdRequest',
	LoadListRequest = 'LoadListRequest',
	LoadListSuccess = 'LoadListSuccess',
	LoadListFailure = 'LoadListFailure',

	ExportListRequest = 'ExportListRequest',
	ExportListSuccess = 'ExportListSuccess',
	ExportListFailure = 'ExportListFailure',

	ListSettings = 'ListSettings',

	AddListFilters = 'AddListFilters',
	RemoveListFilters = 'RemoveListFilters',
	ResetListFilters = 'ResetListFilters',
	BindListFilters = 'BindListFilters',
}

/**
 * Action Creators
 */
export function createListActions<List, Props, Filter extends IFilter>(storeName: string, listName: string) {
	return {
		LoadListByIdRequest: createTypedAction(
			ListActionType.LoadListRequest,
			`[${storeName}] Load ${listName} Request`,
			(id: string|number, correlationParams: CorrelationParams = {correlationId: newAggregationId()}, append?: boolean, noLoading?: boolean) => ({
				id, correlationParams, append, noLoading
			})
		),

		LoadListRequest: createTypedAction(
			ListActionType.LoadListRequest,
			`[${storeName}] Load ${listName} Request`,
			(correlationParams: CorrelationParams = {correlationId: newAggregationId()}, append?: boolean, noLoading?: boolean) => ({correlationParams, append, noLoading})
		),

		LoadListSuccess: createTypedAction(
			ListActionType.LoadListSuccess,
			`[${storeName}] Load ${listName} Success`,
			props<{ list: List, correlationParams?: CorrelationParams, append?: boolean }>()
		),

		LoadListFailure: createTypedAction(
			ListActionType.LoadListFailure,
			`[${storeName}] Load ${listName} Failure`,
			props<{ error: Error }>()
		),

		ExportListRequest: createTypedAction(
			ListActionType.ExportListRequest,
			`[${storeName}] Export ${listName} Request`,
			(correlationParams: CorrelationParams = {correlationId: newAggregationId()}) => ({correlationParams})
		),

		ExportListSuccess: createTypedAction(
			ListActionType.ExportListSuccess,
			`[${storeName}] Export ${listName} Success`,
			props<{ correlationParams?: CorrelationParams }>()
		),

		ExportListFailure: createTypedAction(
			ListActionType.ExportListFailure,
			`[${storeName}] Export ${listName} Failure`,
			props<{ error: Error }>()
		),

		ListSettings: createTypedAction(
			ListActionType.ListSettings,
			`[${storeName}] Update ${listName} Settings`,
			props<{ props: IListState<Props> }>()
		),

		AddListFilters: createTypedAction(
			ListActionType.AddListFilters,
			`[${storeName}] Add ${listName} Filters`,
			props<{ filters: Filter[] }>()
		),

		RemoveListFilters: createTypedAction(
			ListActionType.RemoveListFilters,
			`[${storeName}] Remove ${listName} Filters`,
			props<{ filters: Filter[] }>()
		),

		ResetListFilters: createTypedAction(
			ListActionType.ResetListFilters,
			`[${storeName}] Reset ${listName} Filters`,
			(onlyTransient?: boolean) => ({onlyTransient})
		),

		BindListFilters: createTypedAction(
			ListActionType.BindListFilters,
			`[${storeName}] Bind ${listName} Transient Filters`
		),
	};
}

/**
 * Reducers
 */
export type ListReducer<S> = ReducerTypes<S, readonly ActionCreator<string, Creator<any[], object>>[]>;

export const reduceListLoading = (state, action) => ({
	...state,
	isLoading: !action.noLoading || !state.list.items.length,
	error: false
});
export const reduceListSuccess = (state, action) => ({
	...state,
	isLoading: false,
	error: false,
	list: !action.append ? action.list : {
		...state.list,
		paging: action.list.paging,
		items: state.list.items.concat(action.list.items)
	}
});
export const reduceListFailure = (state, action) => ({
	...state,
	isLoading: false,
	error: action.error
});
export const reduceExportLoading = (state, _action) => ({
	...state,
	isExporting: true,
	exportError: null,
});
export const reduceExportSuccess = (state, _action) => ({
	...state,
	isExporting: false,
	exportError: null,
});
export const reduceExportFailure = (state, action) => ({
	...state,
	isExporting: false,
	exportError: action.error,
});
export const reduceListSettings = (state, action) => ({
	...state,
	options: {
		...state.options,
		hasPaging: true,
		hasSort: action.sort || (state.paging.sorts && state.paging.sorts.length > 0)
	},
	paging: {
		...state.paging,
		...action.props ? action.props : action
	}
});
export const reduceListAddFilters = (state, action) => ({
	...state,
	paging: {...state.paging, page: 1},
	filters: [...state.filters, ...action.filters]
});
export const reduceListRemoveFilters = (state, action) => ({
	...state,
	paging: {...state.paging, page: 1},
	filters: state.filters.filter(f => !action.filters.includes(f))
});
export const reduceListResetFilters = (state, action) => ({
	...state,
	paging: {...state.paging, page: 1},
	filters: action.onlyTransient ? removeTransientFilters(state.filters) : []
});
export const reduceListBindFilters = (state, _action) => ({
	...state,
	paging: {...state.paging, page: 1},
	filters: makeFiltersPermanent(state.filters)
});

export function withListReducerUnsafe<S extends object>(slice: keyof S, {
	LoadListByIdRequest, LoadListRequest, LoadListSuccess, LoadListFailure,
	ListSettings, AddListFilters, RemoveListFilters, ResetListFilters, BindListFilters,
	ExportListRequest, ExportListSuccess, ExportListFailure
}) {
	return [
		on(LoadListByIdRequest, (state: S, action) => ({...state, [slice]: reduceListLoading(state[slice], action)})),
		on(LoadListRequest, (state: S, action) => ({...state, [slice]: reduceListLoading(state[slice], action)})),
		on(LoadListSuccess, (state: S, action) => ({...state, [slice]: reduceListSuccess(state[slice], action)})),
		on(LoadListFailure, (state: S, action) => ({...state, [slice]: reduceListFailure(state[slice], action)})),

		on(ExportListRequest, (state: S, action) => ({...state, [slice]: reduceExportLoading(state[slice], action)})),
		on(ExportListSuccess, (state: S, action) => ({...state, [slice]: reduceExportSuccess(state[slice], action)})),
		on(ExportListFailure, (state: S, action) => ({...state, [slice]: reduceExportFailure(state[slice], action)})),

		on(ListSettings, (state: S, action) => ({...state, [slice]: reduceListSettings(state[slice], action)})),

		on(AddListFilters, (state: S, action) => ({...state, [slice]: reduceListAddFilters(state[slice], action)})),
		on(RemoveListFilters, (state: S, action) => ({...state, [slice]: reduceListRemoveFilters(state[slice], action)})),
		on(ResetListFilters, (state: S, action) => ({...state, [slice]: reduceListResetFilters(state[slice], action)})),
		on(BindListFilters, (state: S, action) => ({...state, [slice]: reduceListBindFilters(state[slice], action)})),
	];
}

export function withListReducer<S extends object>(slice: keyof S, types): ListReducer<S>[] {
	return withListReducerUnsafe(slice, types).map(on => on as ListReducer<S>)
}

/**
 * Selectors
 */
export interface IStoreListStateSelectors<List, Props, Filter extends IFilter> {
	getSlice: (state: IStoreListStateSlice<List, Props, Filter>) => IStoreListStateSlice<List, Props, Filter>;
	selectSlice: MemoizedSelector<object, IStoreListStateSlice<List, Props, Filter>>;

	getList: (state: IStoreListStateSlice<List, Props, Filter>) => List;
	selectList: MemoizedSelector<object, List>;

	getFilters: (state: IStoreListStateSlice<List, Props, Filter>) => Filter[];
	selectFilters: MemoizedSelector<object, Filter[]>;

	getError: (state: IStoreListStateSlice<List, Props, Filter>) => any;
	selectError: MemoizedSelector<object, any>;

	getIsLoading: (state: IStoreListStateSlice<List, Props, Filter>) => boolean;
	selectIsLoading: MemoizedSelector<object, boolean>;

	getPaging: (state: IStoreListStateSlice<List, Props, Filter>) => IListState<Props>;
	selectPaging: MemoizedSelector<object, IListState<Props>>;

	getIsExporting: (state: IStoreListStateSlice<List, Props, Filter>) => boolean;
	selectIsExporting: MemoizedSelector<object, boolean>;

	getExportError: (state: IStoreListStateSlice<List, Props, Filter>) => any;
	selectExportError: MemoizedSelector<object, any>;
}

export function createListSliceSelectors<List, Props, Filter extends IFilter>
(sliceSelector: MemoizedSelector<object, IStoreListStateSlice<List, Props, Filter>>):
	IStoreListStateSelectors<List, Props, Filter> {

	const getSlice = (state: IStoreListStateSlice<List, Props, Filter>): IStoreListStateSlice<List, Props, Filter> => state;
	const selectSlice: MemoizedSelector<object, IStoreListStateSlice<List, Props, Filter>> = createSelector(sliceSelector, getSlice);

	const getList = (state: IStoreListStateSlice<List, Props, Filter>): List => state.list;
	const selectList: MemoizedSelector<object, List> = createSelector(sliceSelector, getList);

	const getFilters = (state: IStoreListStateSlice<List, Props, Filter>): Filter[] => state.filters;
	const selectFilters: MemoizedSelector<object, Filter[]> = createSelector(sliceSelector, getFilters);

	const getError = (state: IStoreListStateSlice<List, Props, Filter>): any => state.error;
	const selectError: MemoizedSelector<object, any> = createSelector(sliceSelector, getError);

	const getIsLoading = (state: IStoreListStateSlice<List, Props, Filter>): boolean => state.isLoading;
	const selectIsLoading: MemoizedSelector<object, boolean> = createSelector(sliceSelector, getIsLoading);

	const getPaging = (state: IStoreListStateSlice<List, Props, Filter>): IListState<Props> => state.paging;
	const selectPaging: MemoizedSelector<object, IListState<Props>> = createSelector(sliceSelector, getPaging);

	const getIsExporting = (state: IStoreListStateSlice<List, Props, Filter>): boolean => state.isExporting;
	const selectIsExporting: MemoizedSelector<object, boolean> = createSelector(sliceSelector, getIsExporting);

	const getExportError = (state: IStoreListStateSlice<List, Props, Filter>): boolean => state.exportError;
	const selectExportError: MemoizedSelector<object, boolean> = createSelector(sliceSelector, getExportError);

	return {
		getSlice,
		selectSlice,
		getList,
		selectList,
		getFilters,
		selectFilters,
		getPaging,
		selectPaging,
		getError,
		selectError,
		getIsLoading,
		selectIsLoading,
		getExportError,
		selectExportError,
		getIsExporting,
		selectIsExporting,
	};
}

