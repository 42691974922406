<debtor-kpis
    [data]="kpis$ | async"
    [currency]="currency$ | async"
/>

<section [class.block-loader]="loader.isLoading">
    <loader-spinner [model]="loader" />

    @if (list$ | async; as list) {
        <div class="row mt-5">
            <h2 class="col-12 col-lg-8" translate="DunningActions.List.Title"></h2>
        </div>

        <actions-list-table
            [list]="list"
            [listHandler]="listHandler"
            [currency]="currency$ | async"
        />
        <layout-list-paging
            [list]="list"
            [listHandler]="listHandler"
        />
    }
</section>
