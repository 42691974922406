<td class="col-id-lg" (click)="$event.stopPropagation()">
    {{ item.dunningActionId }}
</td>

<td class="col-small-type">
    {{ item | daType | translate }}
</td>

<td class="col-acc-date">{{ item.realizationDate | dateFormat }}</td>

<td class="col-amount">
    {{ item.dunningAmountInvoicing | amount : { considerZeroAsNaN: true, with:'symbol-narrow', currency: currency } }}
</td>

<td class="col-amount">
    {{ item.totalBalanceInvoicing | amount : { considerZeroAsNaN: false, with:'symbol-narrow', currency: currency } }}
</td>

<td class="col-file">
    <a class="btn btn-link" [routerLink]="getAccountingDetailPath(item.dunningActionId)">
        <i class="far fa-search"></i> {{ 'Actions.Show' | translate }}
    </a>
</td>

<td class="col-comment">
    <button class="icon-link btn-comment btn-badged" (click)="onDiscuss()">
        <i class="far fa-edit"></i>
        @if (item.hasNewComment) {
            <span class="badge badge-pill badge-pill-icon">
                <i class="fas fa-exclamation-circle"></i>
            </span>
        }
    </button>
</td>

<td class="col-file">
    @if (previewLoading$ | async) {
        <loader-spinner size="small"/>
    }
    @if ((previewLoading$ | async) === false) {
        <button class="icon-link btn-preview" (click)="onPreview()">
            <i class="far fa-eye"></i>
        </button>
    }
</td>
