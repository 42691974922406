<td class="col-id-lg" (click)="$event.stopPropagation()">
    {{ item.displayId }}
</td>

<td class="col-small-type">
    {{ item | accountingType : { isFull: true, displaySubType: true, displayMiscType: true } }}
</td>

<td class="col-acc-date">{{ item.accountingDate | dateFormat }}</td>

<td class="col-due-date">{{ item.dueDate | dateFormat }}</td>

<td class="col-amount">
    {{ item.amountInvoicing | amount : { considerZeroAsNaN: true, with:'symbol-narrow', currency: currency } }}
</td>

<td class="col-amount">
    {{ item.balanceInvoicing | amount : { considerZeroAsNaN: false, with:'symbol-narrow', currency: currency } }}
</td>

@if (showStatus) {
    <td class="col-small-type">
        @if (item.balanceInvoicing === 0) {
            <span class="alert-success-light bg-transparent font-weight-bold" translate="AccountingDocuments.Status.Complete"></span>
        }
    </td>
}
<td class="col-file">
    @if (item.documentId) {
        <button type="button" class="btn btn-sm btn-transparent" (click)="openDocument(item)">
            <i class="far fa-file-pdf"></i>
        </button>
    }
    @if (!item.documentId) {
        <span>-</span>
    }
</td>

<td class="col-comment">
    <button type="button" class="icon-link btn-comment btn-badged" (click)="onDiscuss()">
        <i class="far fa-edit"></i>
        @if (item.hasNewComment) {
            <span class="badge badge-pill badge-pill-icon">
                <i class="fas fa-exclamation-circle"></i>
            </span>
        }
    </button>
</td>
