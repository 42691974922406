import { createReducer, on } from '@ngrx/store';
import { Currency } from '@aston/foundation';

import { withEntityReducer } from '../functions';

import * as featureActions from './actions';
import { initialState, IAppState } from './state';



export const appReducer = createReducer<IAppState>(
	initialState,

	...withEntityReducer<IAppState>('tenant', featureActions.tenantActions),
	...withEntityReducer<IAppState>('factorConfig', featureActions.factorConfigActions),
	...withEntityReducer<IAppState>('countries', featureActions.countriesActions),
	...withEntityReducer<IAppState>('language', featureActions.userLanguageActions),
	...withEntityReducer<IAppState>('referential', featureActions.referentialActions),

	on(featureActions.StartInitializer, () => ({
		...initialState,
		isLoading: true,
		errorMessage: null,
		bootstrapError: null,
	})),

	on(featureActions.FinishInitializer, state => ({
		...state,
		isFinished: true,
		bootstrapError: null,
	})),

	on(featureActions.AppReady, state => ({
		...state,
		isLoading: false,
		bootstrapError: null,
	})),

	on(featureActions.CriticalFailure, (state, action) => ({
		...state,
		bootstrapError: action.bootstrapError,
	})),

	on(featureActions.SetTenantLogo, (state, action) => ({
		...state,
		logo: action.path,
		isPlatformCustomizationEnabled: action.enabled,
	})),

	on(featureActions.ChangeLanguage, (state, action) => ({
		...state,
		language: {
			...state.language,
			entity: {language: action.language}
		}
	})),

	on(featureActions.UpdateAppConfigurationSuccess, (state, action) => ({
		...state,
		config: action.config
	})),

	on(featureActions.LoadTenantIdentitySuccess, (state, action) => {
		const config = state.config;
		config.currency = action.entity.currencyCodeInvoicing as Currency;
		return {
			...state,
			config
		};
	}),

	on(featureActions.LoadValidatorsRequest, state => ({
		...state,
		validatorsLoaded: false
	})),

	on(featureActions.LoadValidatorsSuccess, state => ({
		...state,
		validatorsLoaded: true
	})),

	on(featureActions.StartScrollToTop, state => ({
		...state,
		isScrolling: true
	})),

	on(featureActions.FinishScrollToTop, state => ({
		...state,
		isScrolling: false
	})),

	on(featureActions.Login, (state, action) => ({
		...state,
		user: action.userData
	})),

	on(featureActions.Logout, state => ({
		...state,
		user: null,
		scripts: {}
	})),

	on(featureActions.UpdateUserSessionSuccess, (state, action) => ({
		...state,
		userSession: {
			...state.userSession,
			...action.userSession
		}
	})),

	on(featureActions.UpdateInactivityTimer, state => ({
		...state,
		userSession: {
			...state.userSession,
			inactivityTime: state.userSession.lastActivityDate ?
				new Date().getTime() - state.userSession.lastActivityDate.getTime() :
				0
		}
	})),

	on(featureActions.UserDataSuccess, (state, action) => ({
		...state,
		user: action.userData
	})),

	on(featureActions.LoadScriptRequest, (state, action) => {
		if (state.scripts[action.script]) {
			return state;
		} else {
			return {
				...state,
				scripts: {
					...state.scripts,
					[action.script]: false
				}
			};
		}
	}),

	on(featureActions.LoadScriptSuccess, (state, action) => ({
		...state,
		scripts: {
			...state.scripts,
			[action.script]: true
		}
	}))
);
