import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'plural',
	standalone: true,
})

export class PluralPipe implements PipeTransform {
	transform(singular: string, count: number): string {
		if (count !== null && ( count === -1 || count === 0 || count === 1) ) {
			return singular;
		}
		return `${singular}Plural`;
	}
}
