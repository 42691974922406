import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { AppLanguage, LanguagePipe } from '@aston/foundation';
import { ITenantInfos, IUserSession } from 'apps/debtor-portal/src/app/authentication-module/models';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import * as RoutesDefinitions from '../../../routes-definitions';

@Component({
    selector: 'layout-header',
    templateUrl: './layout-header.component.html',
    standalone: true,
    imports: [NgbDropdownModule, RouterLinkActive, TranslateModule, RouterLink, LanguagePipe]
})
export class LayoutHeaderComponent implements OnChanges {
	@Input() isAuthenticated = false;
	@Input() userSession: IUserSession;
	@Input() tenant: ITenantInfos;
	@Input() currentLanguage: AppLanguage;
	@Input() currentActionId: number;
	@Output() lang: EventEmitter<AppLanguage> = new EventEmitter();
	@Output() logout: EventEmitter<void> = new EventEmitter();

	accountingListRoutePath: string = RoutesDefinitions.getDunningAccountingFullPath(null);
	dunningActionsListRoutePath: string = RoutesDefinitions.getDunningActionsListFullPath();
	invoicingListRoutePath: string = RoutesDefinitions.getDunningInvoicingFullPath();
	AppLanguage = AppLanguage;

	ngOnChanges() {
		this.accountingListRoutePath = RoutesDefinitions.getDunningAccountingFullPath(this.currentActionId);
	}

	onLogout() {
		this.logout.emit();
	}

	setLanguage(lang: AppLanguage) {
		this.lang.emit(lang);
	}
}
