<div class="loader loader-{{ size }}" [class.revert]="revertStyle" *ngIf="model.isLoading$ | async">
	<svg *ngIf="spinning$ | async" class="loader-spinner" viewBox="0 0 50 50">
		<circle class="loader-spinner-path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
	</svg>
	<div *ngIf="loadingMessage" class="loader-message" [translate]="loadingMessage"></div>
</div>

<ngb-alert type="danger" [dismissible]="false" *ngIf="(model.error$ | async) && (onLine$ | async)">
	{{ model.errorMessage }}
</ngb-alert>
	

