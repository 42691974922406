import { FormGroup } from '@angular/forms';

import { ObjectPropertyNamesHolder } from '../types';


export * from './object-to-formdata';

export function nameof<T>(key: keyof T, _instance?: T): keyof T {
	return key;
}

export function uuid(): string {
	return `${Date.now()}-${Math.round(Math.random() * 1000000)}`;
}

export function getQueryStringFromObject(queryObj: any): string {
	return Object.keys(queryObj).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(queryObj[key])).join('&');
}

export function deepCopy<T>(obj: T): T {
	return JSON.parse(JSON.stringify(obj));
}

export function camelCaseToPascalCase(str: string): string {
	if (str && str.length > 1) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	return str;
}

export function pascalCaseToCamelCase(str: string): string {
	if (str && str.length > 1) {
		return str.charAt(0).toLowerCase() + str.slice(1);
	}

	return str;
}

export type NativeType = 'string' | 'number' | 'bigint' | 'boolean' | 'symbol' | 'undefined' | 'object' | 'function';
export type Type = NativeType | 'null';

export function getType(obj: any): Type {
	// Override default typeof behavior
	if (obj === null) {
		return 'null';
	}

	return typeof obj;
}

export function mapToCharPadder(value: number, padlen = 6, padchar = '0'): string {
	const pad = new Array(1 + padlen).join(padchar);
	return (pad + value).slice(-pad.length);
}

export function objectToQueryString(obj: any, isStart = true): string {
	return (isStart ? '?' : '&')
		+ Object.keys(obj).reduce((a, k) => a.concat(`${k}=${encodeURIComponent(obj[k])}`), [] as string[]).join('&');
}

export function extractFormControlNames<T>(form: FormGroup) {
	return <ObjectPropertyNamesHolder<T>>Object.keys(form.controls).reduce((prv, cur) => {
		(prv[cur] = cur);
		return prv;
	}, {} as any);
}

export function astonCacheId(skipQSprefix?: boolean): string {
	const key = 'ASTON_CACHE_ID';
	const cacheId = (key in window) ? window[key] : null;
	if (cacheId && typeof cacheId === 'string' && !cacheId.startsWith('s#')) {
		return (skipQSprefix ? '' : '?') + '_' + cacheId;
	}
	return '';
}

export function mergeRecursive(obj1: any, obj2: any) {
	Object.keys(obj2).forEach(p => {
		try {
			// Property in destination object set; update its value.
			if (obj2[ p ].constructor === Object && obj1[ p ]) {
				obj1[ p ] = mergeRecursive(obj1[ p ], obj2[ p ]);

			} else {
				obj1[ p ] = obj2[ p ];
			}

		} catch (e) {
			// Property in destination object not set; create it and set its value.
			obj1[ p ] = obj2[ p ];
		}
	});

	return obj1;
}

// from https://stackoverflow.com/a/7616484/1689894
export function stringHash(string: string) {
	let hash = 0, i, chr;
	if (string.length === 0) {
		return hash;
	}
	for (i = 0; i < string.length; i++) {
		chr = string.charCodeAt(i);
		hash = ( ( hash << 5 ) - hash ) + chr;
		hash |= 0;
	}
	return hash;
}

export function isNone(v: any): boolean {
	return v?.toString() === 'None';
}

export function notNone(v: any): boolean {
	return v?.toString() !== 'None';
}

export function enumWithoutNone<T extends object>(v: T, ...ors: any[]): { [key: string]: T } {
	return Object.keys(v).filter(notNone).filter(e => !ors || !ors.includes(e as unknown as T)).reduce((acc, value) => ({ ...acc, [value]: value }), {});
}

export function isDefined(v: number): boolean {
	return v !== null && v !== undefined;
}

export function toNumber(v: any): number {
	return v
		? +(`${v}`.replace(/,/g, '.'))
		: 0;
}

export function moveItem(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
}

export function toggleAllFieldsDisability(form: FormGroup, disabled: boolean) {
	Object.values(form.controls).forEach(c => {
		if (disabled) c.disable();
		else c.enable();
	})
}
