import { DEBUG_BAR_CONFIG, ScrolledByService, DebugBarConfig, AppErrorHandler } from '@aston/foundation';
import { ErrorHandler } from '@angular/core';
import { throttleTime, map } from 'rxjs';
import { Store } from '@ngrx/store';

import { WebSocketService } from './shared-module/services';
import { AppStoreSelectors } from './root-store/app-store';
import { AppConfiguration } from './app.configuration';
import { AppConstants } from './app.constants';


export function provideDebugBar() {
	return [{
		provide: DEBUG_BAR_CONFIG,
		useFactory: (
			errorHandler: ErrorHandler,
			configuration: AppConfiguration,
			scrollService: ScrolledByService,
			store: Store,
			websocket: WebSocketService,
		): DebugBarConfig => ({
			configuration,
			scrollService,
			errors: errorHandler as AppErrorHandler,
			tenant$: store.select(AppStoreSelectors.selectTenant).pipe(map(t => ({ id: t.superDebtorId, name: t.tenantName }))),
			sessionId$: store.select(AppStoreSelectors.selectSessionId),
			inactivityService: {
				inactivityCountdown$: store.select(AppStoreSelectors.selectInactivityCountDown).pipe(throttleTime(1000)),
				inGracefulPeriod$: store.select(AppStoreSelectors.selectInactivityTime).pipe(map(inactivity => inactivity >= AppConstants.MAX_INACTIVITY_TIME - AppConstants.INACTIVITY_WARNING_COUNTDOWN_BEFORE_LOGOUT)),
			},
			websocket,
		}),
		deps: [
			ErrorHandler,
			AppConfiguration,
			ScrolledByService,
			Store,
			WebSocketService
		]
	}]
}