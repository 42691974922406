import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { fr as dateLocaleFr } from 'date-fns/locale/fr';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { mergeRecursive, AppBaseConfiguration, AppConstants, astonCacheId, AppErrorHandler, NotificationMessageComponent, CacheInterceptor, CriticalStateService, ErrorInterceptor, LanguageService, TelemetryService, VersionInterceptor, getDatepickerConfig, getDaterangepickerConfig, TelemetryInitializer, ValidationService, FactorConfigurationService, LoaderSpinnerComponent, AppMissingTranslationHandler, registerDateLocale } from '@aston/foundation';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { RouterOutlet } from '@angular/router';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationModule } from './authentication-module/authentication.module';
import { AppConfiguration } from './app.configuration';
import { RootStoreModule } from './root-store';
import { AppStoreActions, AppStoreSelectors } from './root-store/app-store';
import { AccountingModule } from './accounting-module/accounting.module';
import { AppConstants as MiddleAppConstants } from './app.constants';
import { FederationFactorConfigurationService, FederationValidationService } from './shared-module/services';
import { provideDebugBar } from './app.connectors';


// override constants with our own
AppConstants.overrideWith(MiddleAppConstants);

// import the locale, used in currencyPipe for format
registerLocaleData(localeFr);
registerDateLocale('fr', dateLocaleFr);

export function translateLoader(
	http: HttpClient,
	config: AppConfiguration) {
	console.log('environment', environment)
	const filesToConsider = environment[ 'translations' ].filenames.map(f => {
		return { prefix: './assets/i18n/' + f + '.', suffix: '.json' };
	});
	return new MultiTranslateHttpLoader(http, config, filesToConsider);
}

/* custom translation loader, extends the translation file to merge multiple files into a single one */

/* original => https://github.com/ngx-translate/http-loader/issues/44#issuecomment-412510056 */
export class MultiTranslateHttpLoader implements TranslateLoader {
	constructor(
		private http: HttpClient,
		private config: AppConfiguration,
		public resources: { prefix: string, suffix: string }[] = [{ prefix: '/assets/i18n/', suffix: '.json' }]) {
	}

	public getTranslation(lang: string): any {
		return this.config.appConfigLoaded$.pipe(
			map(_ => {
				const resourcesNames = this.resources
				.sort((a, b) => a.prefix.indexOf('v2') < b.prefix.indexOf('v2') ? -1 : 1);

				return resourcesNames.map(resourceName => this.http.get(`${resourceName.prefix}${lang}${resourceName.suffix}${astonCacheId()}`));
			}),
			mergeMap(calls => forkJoin(calls)),
			map(responses => responses.reduce((a, b) => mergeRecursive(a, b))));
	}
}

@NgModule({
	declarations: [
		AppComponent
	],
	bootstrap: [AppComponent],
	imports: [
		AccountingModule,
		AppRoutingModule,
		AuthenticationModule,
		BrowserAnimationsModule,
		BrowserModule,
		TranslateModule.forRoot({
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useClass: AppMissingTranslationHandler,
			},
			loader: {
				provide: TranslateLoader,
				useFactory: (translateLoader),
				deps: [HttpClient, AppConfiguration]
			}
		}),
		LoaderSpinnerComponent,
		NgbTooltipModule,
		NotificationMessageComponent,
		RootStoreModule,
		RouterOutlet,
	],
	providers: [
		AppConfiguration,
		{
			provide: LOCALE_ID,
			useValue: 'fr-FR'
		},
		{
			provide: ValidationService,
			useClass: FederationValidationService,
		},
		{
			provide: FactorConfigurationService,
			useClass: FederationFactorConfigurationService,
		},
		{
			provide: AppBaseConfiguration,
			useExisting: AppConfiguration
		},
		{
			provide: APP_INITIALIZER,
			useFactory: AppConfigurationFactory,
			deps: [Store],
			multi: true
		},
		{
			provide: ErrorHandler,
			useClass: AppErrorHandler
		},
		{ provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true, deps: [TelemetryService] },
		{ provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true, deps: [TranslateService, LanguageService, CriticalStateService, TelemetryService] },
		{
			provide: BsDatepickerConfig,
			useFactory: getDatepickerConfig
		},
		{
			provide: TelemetryInitializer,
			useValue: null,
		},
		{
			provide: BsDaterangepickerConfig,
			useFactory: getDaterangepickerConfig
		},
		provideDebugBar(),
		provideHttpClient(withInterceptorsFromDi())
	]
})
export class AppModule {
}

export function AppConfigurationFactory(store: Store): CallableFunction {
	return () => new Promise(resolve => {
		store.dispatch(AppStoreActions.StartInitializer());
		store.select(AppStoreSelectors.selectIsInitFinished).pipe(filter(ready => ready)).subscribe(_ => {
			resolve(true);
		});
	});
}
