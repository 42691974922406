
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class BrowserNavigationService {

    private _onAppLeave = new Subject<void>();
    private counter = -1;

    get onAppLeave() {
        return this._onAppLeave.asObservable();
    }

    get hasPrevious() {
        return this.counter > 0;
    }

	constructor(private router: Router, private location: Location) {

		router.events.pipe(
			filter(event => event instanceof NavigationEnd),
		).subscribe(_ => {
			this.counter++;
		});

		this.location.subscribe(_ => {
            if (this.counter - 1 <= 0) {
                this._onAppLeave.next();
            }
			this.counter--;
        });
    }
}
