import { ValidatorFn, AbstractControl } from '@angular/forms';

import { IValidationPropertyValidator } from '../models';
import { ValidationPropertyType } from '../enums';

import { CustomValidators } from './custom-validators.class';



export class ControlValidators {
	static createControlValidators(control: AbstractControl, rules: ValidatorFn[] = [], apiRules: IValidationPropertyValidator[] = []) {
		let validatorsSet: ValidatorFn[] = rules;

		// Angular doesn't expose any method to add validators after AbstractControl declaration
		// We have to create the AbstractControl here and merge front rules with api rules
		if (apiRules.length) {
			validatorsSet = validatorsSet.concat(this.buildControlValidators(apiRules));
		}

		this.applyValidatorsToControl(control, validatorsSet);
	}

	static buildControlValidators(validators: IValidationPropertyValidator[]): ValidatorFn[] {
		const validatorsSet: ValidatorFn[] = [];

		validators.forEach((validator: IValidationPropertyValidator) => {
			switch (validator.name) {
				case ValidationPropertyType.PASS:
					validatorsSet.push(CustomValidators.pass);
					break;
				case ValidationPropertyType.NOT_IN_LIST_OF_VALUES_VALIDATOR:
					validatorsSet.push(CustomValidators.notInValues(validator.additionalData.refusedValues));
					break;
				case ValidationPropertyType.NOT_NULL:
					validatorsSet.push(CustomValidators.notNull);
					break;
				case ValidationPropertyType.NOT_EMPTY:
					validatorsSet.push(CustomValidators.notNull);
					validatorsSet.push(CustomValidators.notEmpty(validator.additionalData.defaultValueForType));
					break;
				case ValidationPropertyType.EMAIL:
					validatorsSet.push(CustomValidators.email(validator.additionalData.expression));
					break;
				case ValidationPropertyType.NUMBER:
					validatorsSet.push(CustomValidators.number);
					break;
				case ValidationPropertyType.NOTNUMBER:
					validatorsSet.push(CustomValidators.notNumber);
					break;
				case ValidationPropertyType.INTEGER:
					validatorsSet.push(CustomValidators.integer);
					break;
				case ValidationPropertyType.LESS_THAN:
					validatorsSet.push(CustomValidators.lessThan(validator.additionalData.valueToCompare));
					break;
				case ValidationPropertyType.LESS_THAN_OR_EQUAL:
					validatorsSet.push(CustomValidators.lessThanOrEqual(validator.additionalData.valueToCompare));
					break;
				case ValidationPropertyType.GREATER_THAN:
					validatorsSet.push(CustomValidators.greaterThan(validator.additionalData.valueToCompare));
					break;
				case ValidationPropertyType.GREATER_THAN_OR_EQUAL:
					if (validator.additionalData.valueToCompare ?? undefined === undefined) {
						console.debug(`[validators] ignoring rule ${validator.name}, the comparison value is not defined`);
						break;
					}
					validatorsSet.push(CustomValidators.greaterThanOrEqual(validator.additionalData.valueToCompare));
					break;
				case ValidationPropertyType.LENGTH:
					validatorsSet.push(CustomValidators.minimumLength(validator.additionalData.min));
					validatorsSet.push(CustomValidators.maximumLength(validator.additionalData.max));
					break;
				case ValidationPropertyType.EXACT_LENGTH:
					validatorsSet.push(CustomValidators.exactLength(validator.additionalData.min));
					break;
				case ValidationPropertyType.MINIMUM_LENGTH:
					validatorsSet.push(CustomValidators.minimumLength(validator.additionalData.min));
					break;
				case ValidationPropertyType.MAXIMUM_LENGTH:
					validatorsSet.push(CustomValidators.maximumLength(validator.additionalData.max));
					break;
				case ValidationPropertyType.REGULAR_EXPRESSION:
					validatorsSet.push(CustomValidators.regularExpression(validator.additionalData.expression));
					break;
				case ValidationPropertyType.AT_LEAST_DIGIT_CHAR:
					validatorsSet.push(CustomValidators.atLeastDigitChar(validator.additionalData.howMuch));
					break;
				case ValidationPropertyType.AT_LEAST_LOWERCASE_CHAR:
					validatorsSet.push(CustomValidators.atLeastLowercaseChar(validator.additionalData.howMuch));
					break;
				case ValidationPropertyType.AT_LEAST_UPPERCASE_CHAR:
					validatorsSet.push(CustomValidators.atLeastUppercaseChar(validator.additionalData.howMuch));
					break;
				case ValidationPropertyType.AT_LEAST_SPECIAL_CHAR:
					validatorsSet.push(CustomValidators.atLeastSpecialChar(validator.additionalData.howMuch));
					break;
				case ValidationPropertyType.BEFORE_NOW:
				case ValidationPropertyType.BEFORE_FACTOR_NOW:
					validatorsSet.push(CustomValidators.validDate);
					validatorsSet.push(CustomValidators.beforeNow(validator.additionalData.doNotCheckHours));
					break;
				case ValidationPropertyType.AFTER_NOW:
				case ValidationPropertyType.AFTER_FACTOR_NOW:
					validatorsSet.push(CustomValidators.validDate);
					validatorsSet.push(CustomValidators.afterNow(validator.additionalData.doNotCheckHours));
					break;
				case ValidationPropertyType.OLDER_THAN_DAYS:
					validatorsSet.push(CustomValidators.validDate);
					validatorsSet.push(CustomValidators.olderThanDays(validator.additionalData.numberOfDays));
					break;
				case ValidationPropertyType.NOT_OLDER_THAN_DAYS:
					validatorsSet.push(CustomValidators.validDate);
					validatorsSet.push(CustomValidators.notOlderThanDays(validator.additionalData.numberOfDays));
					break;
				case ValidationPropertyType.YOUNGER_THAN_DAYS:
					validatorsSet.push(CustomValidators.validDate);
					validatorsSet.push(CustomValidators.youngerThanDays(validator.additionalData.numberOfDays));
					break;
				case ValidationPropertyType.IBAN:
					validatorsSet.push(CustomValidators.iban);
					validatorsSet.push(CustomValidators.frenchIban);
					break;
				case ValidationPropertyType.CHILD_VALIDATOR_ADAPTOR:
				case ValidationPropertyType.PREDICATE:
				case ValidationPropertyType.ASYNC_PREDICATE:
				case ValidationPropertyType.ENUM:
					break;
				default:
					console.warn(`No validator defined for ${validator.name} property`);
					break;
			}
		});

		return validatorsSet;
	}

	static applyValidatorsToControl(control: AbstractControl, validatorsSet: ValidatorFn[]) {
		if (validatorsSet.length > 0) {
			control.setValidators(validatorsSet);
			control.updateValueAndValidity();
		}
	}

	static resetValidators(control: AbstractControl) {
		control.clearValidators();
		control.updateValueAndValidity();
	}
}
