
import { Component, Input, ChangeDetectionStrategy, OnChanges, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppConstants } from 'apps/debtor-portal/src/app/app.constants';
import { TextEllipsisPipe, DunningLevel, CurrencyCodeType, formatDate } from '@aston/foundation';
import { IPopoverContent } from '@aston/foundation';
import { TranslateService } from '@ngx-translate/core';
import { AccountingStoreActions } from 'apps/debtor-portal/src/app/root-store/accounting-store';
import { CommentsStoreActions } from 'apps/debtor-portal/src/app/root-store/comments-store';
import { CommentBearing } from 'apps/debtor-portal/src/app/comments-module/enums';

import { AccountingDocumentTypeCode } from '../../enums';
import { IAccountingItem } from '../../models';

@Component({
	// Must be an attribute based selector to prevent <table> layout break
	selector: '[accounting-list-table-row]',
	templateUrl: './accounting-list-table-row.component.html',
	providers: [TextEllipsisPipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountingListTableRowComponent implements OnChanges {
	@Input() showStatus = false;
	@Input() item: IAccountingItem;
	@Input() currency: CurrencyCodeType;

	colNameMaxChar: number = AppConstants.COMPANY_NAME_COL_MAX_CHAR;
	accountingDocumentPopoverContent: IPopoverContent[];
	dunningLevelPopoverContent: IPopoverContent[];
	subsidiaryCodePopoverContent: IPopoverContent[];
	invoiceType: AccountingDocumentTypeCode = AccountingDocumentTypeCode.Invoice;
	cashingType: AccountingDocumentTypeCode = AccountingDocumentTypeCode.Cashing;

	constructor(
		protected cd: ChangeDetectorRef,
		protected store: Store,
		public textEllipsisPipe: TextEllipsisPipe,
		protected translateService: TranslateService,
	) { }

	ngOnChanges() {
		this.accountingDocumentPopoverContent = [];

		if (this.item.dunningLevel === DunningLevel.LTG){
			this.dunningLevelPopoverContent = [
				{
					label: 'Accounting.DunningInfo.ClaimType',
					value: this.item.claimName
				}
			];
		 }

		 if (this.item.dunningLevel === DunningLevel.PAA){
			this.dunningLevelPopoverContent = [
				{
					label: 'Accounting.DunningInfo.PaymentDate',
					value: formatDate(this.item.paymentDate, this.translateService.instant('Formats.Date.Base'))
				}
			];
		 }
	}

	get showDunningLevelTooltip(): boolean{
		return this.item.dunningLevel === DunningLevel.LTG
			|| this.item.dunningLevel === DunningLevel.PAA;
	}

	openDocument(item: IAccountingItem) {
		this.store.dispatch(AccountingStoreActions.OpenAccountingDocument({ accountingDocumentId: item.documentId, accountingNumber: item.functionalId }));
	}

	onDiscuss() {
			const accountingDocumentId = this.item.accountingDocumentId;
			const bearing = CommentBearing.AccountingDocument;

			this.store.dispatch(CommentsStoreActions.DiscussModalOpen({ dunningActionId: null, accountingDocumentId, bearing }))
	}
}
