import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { Observable, OperatorFunction, throwError, of, combineLatest } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import * as AppStoreSelectors from 'apps/debtor-portal/src/app/root-store/app-store/selectors';
import { IDocumentFile, HttpErrorMessage, IUnavailableDocumentFile, IFileUploaderConfig } from '@aston/foundation';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'apps/debtor-portal/src/app/app.constants';

function nameFromHeaders(headers: HttpHeaders) {
	const cdh = headers.get('content-disposition').match(/filename=([^;]*);/)
	return cdh?.length ? cdh[0] : null
}

@Injectable({
	providedIn: 'root'
})
export class DocumentFileService {

	toastTranslationKeyPrefix = 'Toasts.File';

	constructor(
		private http: HttpClient,
		private sanitizer: DomSanitizer,
		private store: Store,
		private translateService: TranslateService) { }

	base$: Observable<string> = combineLatest([
		this.store.select(AppStoreSelectors.selectConfig),
		this.store.select(AppStoreSelectors.selectTenant),
	]).pipe(map(([config, tenant]) => `${config.apiUrl}/${tenant.superDebtorId}`))
	
	catchDocumentError<T>(): OperatorFunction<T, T> {
		return catchError((error: HttpErrorMessage) => {

			if (error.key.includes('404')) {
				error.key = error.key.replace('404', 'DocumentNotFound');
				error.translated = this.translateService.instant(error.key);
			}

			return throwError(error);
		});
	}

	orUnavailableDocumentWithPath<T>(unavailableDocumentBase: IDocumentFile): OperatorFunction<T, T | IUnavailableDocumentFile> {
		return catchError(error => {

			if (error.key && error.key.includes('404') && unavailableDocumentBase) {
				return of({ ...unavailableDocumentBase, error });
			}

			return of(error);
		});
	}

	getLocalFileURL(documentFile: IDocumentFile): Observable<IDocumentFile> {
		return this.base$.pipe(
			switchMap(apiUrl => this.http.get(`${apiUrl}/${documentFile.id}/documentcontent`, { observe: 'response', responseType: 'blob' })),
			map(response => {
				const name = documentFile.name || nameFromHeaders(response.headers);
				const file = new File([response.body], name, { type: response.headers.get('content-type') })
				return {
					...documentFile,
					name,
					url: this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file))
				}
			}),
			this.orUnavailableDocumentWithPath(documentFile),
			this.catchDocumentError()
		);
	}

	updateWithLocalDunningActionDocumentURL(documentFile: IDocumentFile, dunningActionId: number): Observable<IDocumentFile> {
		const headers = {'Content-Type': 'application/json'};
		return this.base$.pipe(
			switchMap(apiUrl => this.http.get(`${apiUrl}/${dunningActionId}/previewContent`,
				{ headers, observe: 'response', responseType: 'blob'})),
			map(response => {
				const name = documentFile.name || nameFromHeaders(response.headers);
				const file = new File([response.body], name, { type: response.headers.get('content-type') })
				return {
					...documentFile,
					url: this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file))
				}
			}),
			this.orUnavailableDocumentWithPath(documentFile),
			this.catchDocumentError()
		)
	}

	checkFile(file: File, config: IFileUploaderConfig, existingFiles: string[] = [], translationKeyPrefix: string = this.toastTranslationKeyPrefix): Observable<string> {
		// Size check
		if (file.size > config.maxSize) {
			// return throwError(`${translationKeyPrefix}.Size`);
			return this.customThrowError(translationKeyPrefix, 'Size');
		}

		// File name check
		if (file.name.length >= AppConstants.FILE_NAME_MAX_LENGTH) {
			// return throwError(`${translationKeyPrefix}.NameLength`);
			return this.customThrowError(translationKeyPrefix, 'NameLength');
		}

		if (existingFiles.find(existing => existing === file.name)) {
			// return throwError(`${translationKeyPrefix}.AlreadyExist`);
			return this.customThrowError(translationKeyPrefix, 'AlreadyExist');
		}

		// Extension check
		let isTypeAccepted = false;
		let i = 0;

		while (i < config.acceptExtensions.length && !isTypeAccepted) {
			isTypeAccepted = file.type.includes(config.acceptExtensions[i]);
			i++;
		}

		if (!isTypeAccepted) {
			// return throwError(`${translationKeyPrefix}.Format`);
			return this.customThrowError(translationKeyPrefix, 'Format');
		}

		return of(null);
	}

	customThrowError(prefix: string, suffix: string): Observable<string> {
		const key = `${prefix}.${suffix}`;
		const defaultKey = `${this.toastTranslationKeyPrefix}.${suffix}`;

		return this.translateService.get(key).pipe(
			switchMap((text: string) => text.includes(key) ? throwError(defaultKey) : throwError(key))
		);
	}
}
