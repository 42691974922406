<debtor-kpis
    [data]="kpis$ | async"
    [currency]="currency$ | async"
/>

<section [class.block-loader]="loader.isLoading">
    <loader-spinner [model]="loader" />

    @if (list$ | async; as list) {
        <div class="row mt-5">
            <h2 class="col-12 col-lg-8" translate="InvoicingList"></h2>
            <div class="mb-3 d-flex justify-content-end col-12 col-lg-4">
                @if (payLink$ | async) {
                    <button
                        class="btn btn-primary mr-2"
                        translate="Actions.Pay" data-cy="Pay"
                        (click)="onPay()"
                    ></button>
                }
            </div>
        </div>

        <accounting-list-table
            [list]="list"
            [listHandler]="listHandler"
            [currency]="currency$ | async"
            [showStatus]="true"
        />

        <layout-list-paging
            [list]="list"
            [listHandler]="listHandler"
        />
    }
</section>
