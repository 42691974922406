import { Observable, ReplaySubject, Subject } from 'rxjs';

import { Currency } from './enums';
import { astonCacheId } from './functions/utils.function';


export abstract class AppBaseConfiguration {
	name: string;
	apiUrl: string;
	authenticationServerUrl: string;
	clientId: string;
	local: boolean;
	dev: boolean;
	saveTokenInLocalStorage: boolean;
	staging: boolean;
	production: boolean;
	version: string;
	currency: Currency;

	// set by the build process
	buildMetadata: string;
	telemetryKey: string;

	// Loading state
	// Because OIDC now subscribe later, we use re ReplaySubject
	// to reemit the config in case it has been already fetched.
	protected appConfigLoaded: Subject<boolean> = new ReplaySubject();
	readonly appConfigLoaded$: Observable<boolean> = this.appConfigLoaded.asObservable();

	ensureInit(): Promise<any> {
		return new Promise((r, e) => {

			// Do not use HttpClient : https://sebastienollivier.fr/blog/angular/pourquoi-il-ne-faut-pas-utiliser-httpclient-dans-appinitializer
			const xhr = new XMLHttpRequest();
			xhr.open('GET', './assets/configurations/config.json' + astonCacheId());

			xhr.addEventListener('readystatechange', () => {
				if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
					// Workaround to allow semi-complex flag expressions, like `!true`
					// using a temporary window-hosted attribute to work with https://esbuild.github.io/content-types/#direct-eval
					window['__aston_config'] = {};
					window.eval(`window.__aston_config = ${xhr.responseText}`);
					Object.assign(this, window['__aston_config']);
					delete window['__aston_config'];

					this.appConfigLoaded.next(true);
					r(this);
				} else if (xhr.readyState === XMLHttpRequest.DONE) {
					e();
				}
			});

			xhr.send(null);
		});
	}
}
