<div data-cy="ListPager" class="list-paging" *ngIf="list && list.paging">
	<div class="list-paging-sizes">
		<ng-container *ngIf="showPageSize && list.paging.totalItemCount > pageSizes[0]">
			<label for="page-size-field" translate="Paging.PageSizeLabel1"></label>
			<select data-testid="ListPageSizeField" class="form-control form-control-alt" id="page-size-field" [ngModel]="list.state.pageSize" (change)="onSetPageSize($event.target)">
				<option *ngFor="let size of pageSizes" [value]="size" data-testid="ListPageSizeOption">{{ size }}</option>
			</select>
			<label for="page-size-field" translate="Paging.PageSizeLabel2"></label>
			<span data-testid="ListPageSizeTotal" class="list-paging-total">{{ 'Paging.Total' | plural: list.paging.totalItemCount | translate: { total: list.paging.totalItemCount } }}</span>
		</ng-container>
	</div>
	<nav *ngIf="list.paging.totalPageCount > 1">
		<ngb-pagination
			[page]="list.state.page"
			[collectionSize]="list.paging.totalItemCount"
			[pageSize]="list.state.pageSize"
			[maxSize]="pagingConfig.maxSize"
			[ellipses]="pagingConfig.ellipses"
			[rotate]="pagingConfig.rotate"
			[boundaryLinks]="pagingConfig.boundaryLinks"
			[directionLinks]="pagingConfig.directionLinks"
			(pageChange)="setPageNumber($event)">
		</ngb-pagination>
	</nav>
</div>
