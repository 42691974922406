import { createReducer, on } from '@ngrx/store';

import { withEntityReducer, withListReducer } from '../functions';

import { initialState, IAccountingState } from './state';
import * as featureActions from './actions';



export const accountingReducer = createReducer<IAccountingState>(
	initialState,

	...withEntityReducer<IAccountingState>('kpis', featureActions.kpisActions),
	...withListReducer<IAccountingState>('documentsList', featureActions.accountingListActions),
	...withListReducer<IAccountingState>('dunningActionsList', featureActions.dunningListActions),
	...withListReducer<IAccountingState>('invoicesList', featureActions.invoicingListActions),

	on(featureActions.LoadAccountingRequestForDunningActionId, (state, action) => ({
		...state,
		currentDunningActionId: action.dunningActionId,
	})),
);
