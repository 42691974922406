import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { accountingReducer } from './reducer';
import { FeatureName } from './feature';
import { AccountingStoreAccountingEffects } from './effects/effects.accounting';
import { AccountingStoreDunningActionsEffects } from './effects/effects.dunning-actions';
import { AccountingStoreInvoicingEffects } from './effects/effects.invoicing';



@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(FeatureName, accountingReducer),
		EffectsModule.forFeature([AccountingStoreAccountingEffects, AccountingStoreDunningActionsEffects, AccountingStoreInvoicingEffects])
	]
})

export class AccountingStoreModule { }
