
import { formatNumber } from '@angular/common';
import { ValidatorFn, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Predicate } from '@angular/core';

import { IFilter, IFilterOperatorSpec, stringExactOperatorsSpecs } from '../models';
import { IFilterOperator } from '../enums';
import { stringOperatorsSpecs, IFilterSpec, IFieldAutocompleters, autocompleteOperatorsSpecs, IFilterCategories, IFilterFieldSpec, decimal } from '../models';
import { FilterGroups } from '../classes/filter-groups.class';
import { AppConstants } from '../config';
import { IListState, ISort } from '../models';
import { CustomListApiHttpUrlEncodingCodec } from '../classes/CustomListApiHttpUrlEncodingCodec.class';

import { fromDateExpression } from './utils.date';
import { formatDateFullToApi, formatDateOnlyToApi } from './utils.date.localized';
import { uuid } from './utils.function';

/**
 * Utility functions to encode filters as string and back.
 * see https://github.com/Biarity/Sieve#operators
 *
 * encodeFiltersQuery(filters: IFilter[]): string
 * decodeFiltersQuery(filters: string, spec: IFilterSpec): IFilter[]
 * withFieldAutocompleters(spec: IFilterSpec, autocompleters: IFieldAutocompleters): IFilterSpec
 *
 */

interface RawDecodedFilter {
	operator: IFilterOperator;
	operatorChar: string;
	values: any[];
	field: string;
}

export function sortFilters(t: TranslateService) {
	return (a: IFilterFieldSpec | null, b: IFilterFieldSpec | null) => {
		if (!a) return -1;
		if (!b) return 1;
		const ta = a.field.rawLabel ? a.field.label : t.instant(a.field.label, { failSilently: true });
		const tb = b.field.rawLabel ? b.field.label : t.instant(b.field.label, { failSilently: true });

		return ta < tb ? -1 : 1;
	};
}

export function allFilterSpecsFromCategories(specs: IFilterCategories): IFilterSpec {
	return {
		items: ([] as IFilterFieldSpec[]).concat(...Object.values(specs).map(cs => cs.value.items))
	};
}

export function withFieldAutocompletersInCategories(specs: IFilterCategories, autocompleters: IFieldAutocompleters): IFilterCategories {
	return Object.keys(specs).reduce((acc, key) => {
		return {...acc, [key]: {...specs[key], value: withFieldAutocompleters(specs[key].value, autocompleters)}};
	}, {});
}

export function withFieldAutocompleters(spec: IFilterSpec, autocompleters: IFieldAutocompleters): IFilterSpec {
	return {
		...spec,
		items: spec.items.filter(item => !!item).map(item => {
			const autocompleter = autocompleters[item.field.value];
			const specHasAmongOp = item.operatorSpecs.find(s => s.operator === IFilterOperator.Among || s.operator === IFilterOperator.NotAmong);

			if (!autocompleter) {
				return item;
			}
			let validators: ValidatorFn[] = [];
			if (item.chipsOptions && item.chipsOptions.validators) {
				validators = [...item.chipsOptions.validators];
			}
			if (item.validation && typeof item.validation === 'string') {
				validators = [...validators, Validators.pattern(item.validation)];
			}
			if (item.validation && typeof item.validation === 'function') {
				validators = [...validators, item.validation];
			}
			return {
				...item,
				operatorSpecs: specHasAmongOp ? item.operatorSpecs : [...autocompleteOperatorsSpecs, ...item.operatorSpecs],
				chipsOptions: {
					...item.chipsOptions,
					validators
				},
				autocompleter
			};

		})
	};
}

export function getApiSortParam<T>(sortModel: ISort<T>[]): string {
	return sortModel
	.map(s => (s.isDesc ? '-' : '') + s.prop)
	.join(',');
}

export function isPagingRequestedOnListState(pageSize: number) {
	return (pageSize && pageSize > 0) || pageSize === AppConstants.LIST_PAGE_SIZE_IGNORED;
}

export function getListApiHttpParam(params: IListState<any>): HttpParams {
	let httpParams = new HttpParams({encoder: new CustomListApiHttpUrlEncodingCodec()});

	if (params.sorts && params.sorts.length) {
		httpParams = httpParams.append('sorts', getApiSortParam(params.sorts));
	}

	if (isPagingRequestedOnListState(params.pageSize || 0)) {
		if (params.pageSize === AppConstants.LIST_PAGE_SIZE_IGNORED) {
			httpParams = httpParams
			.append('disablePagination', 'true');
		} else {
			httpParams = httpParams
			.append('page', '' + params.page)
			.append('pageSize', '' + params.pageSize);
		}
	}

	if (params.filters && params.filters.length) {
		httpParams = httpParams
			.append('filters', '' + encodeFiltersQuery(params.filters));
	}

	return httpParams;
}

export function encodeFiltersQuery(filters: IFilter[]): string {
	return filters
	.map(f => encodeFilterQuery(f))
	.join(',');
}

export function encodeFilterValue(value: any): string {
	return value.toString().replace(/(,|\\)/g, '\\$1');
}

export function decodeFilterValue(value: string): string {
	return value.replace(/\\(,|\\)/, '$1');
}

export function decodeFiltersQuery(rawFiltersString: string, spec: IFilterSpec): IFilter[] {
	const filterStrings = rawFiltersString.replace(/\\,/g, '.').split(',');
	const filterGroups = new FilterGroups();

	filterStrings.forEach(fs => {
		const rawFilter = decodeFiltersOperatorAndValues(fs);
		if (rawFilter) {
			const filter: IFilter = buildFilterFromRaw(rawFilter, spec);
			filterGroups.add(filter);

		} else {
			console.warn('Could not decode filter', fs);
		}
	});

	return filterGroups.all();
}

export function encodeFilterQuery(filter: IFilter, caseSensitive = false) {
	let operator = filter.operator;
	const spec = filter.spec as IFilterFieldSpec;
	if (!spec) throw 'Cannot encode a filter with no specification';
	const operatorSpec = spec.operatorSpecs.find(ops => ops.operator === operator) as IFilterOperatorSpec;
	let values = [...filter.values];
	const backend = spec.field.backend || spec.field.value;

	// apply transformations
	if (spec.valueTransform) {
		values = spec.valueTransform(values);
	}

	// cast Among => Equal
	if (operator === IFilterOperator.Among) {
		operator = IFilterOperator.Equals;

	// cast NotAmong => NotEquals
	} else if (operator === IFilterOperator.NotAmong) {
		operator = IFilterOperator.NotEquals;

	// cast [= notnull] => [!= null]
	} else if (operator === IFilterOperator.Equals && values.length === 1 && values[0] === AppConstants.FILTERS_VALUE_NOT_NULL) {
		operator = IFilterOperator.NotEquals;
		values[0] = AppConstants.FILTERS_VALUE_NULL;

	// cast [!= notnull] => [= null]
	} else if (operator === IFilterOperator.NotEquals && values.length === 1 && values[0] === AppConstants.FILTERS_VALUE_NOT_NULL) {
		operator = IFilterOperator.Equals;
		values[0] = AppConstants.FILTERS_VALUE_NULL;

	// cast [!= notAll] => [= All]
	} else if (operator === IFilterOperator.NotEquals && values.length === 1 && values[0] === AppConstants.FILTERS_VALUE_NOT_ALL) {
		operator = IFilterOperator.Equals;
		values[0] = AppConstants.FILTERS_VALUE_ALL;

	// cast [= notAll] => [!= All]
	} else if (operator === IFilterOperator.Equals && values.length === 1 && values[0] === AppConstants.FILTERS_VALUE_NOT_ALL) {
		operator = IFilterOperator.NotEquals;
		values[0] = AppConstants.FILTERS_VALUE_ALL;
	}

	// force case-sensitive on non-string fields
	caseSensitive = caseSensitive
		|| operatorSpec?.caseSensitive
		|| spec.operatorSpecs === stringExactOperatorsSpecs
		|| spec.operatorSpecs !== stringOperatorsSpecs;

	// parse decimal numbers
	if (spec.validation === decimal) {
		values = values.map(v => {
			// decimal-null should be used as is (see #32773)
			if (v?.value === AppConstants.FILTERS_VALUE_NULL) return v.value;
			// A few decimals are also choice objects
			const value = (typeof v === 'object' && 'value' in v) ? v.value : v;
			// JS number format
			let result = ('' + value).replace(/,/g, '.');
			// API number format
			result = formatNumber(+result, AppConstants.API_LANGUAGE, '1.0-4');
			// Sieve model format : replace ',' by '\,'
			// eslint-disable-next-line no-useless-escape
			result = ('' + result).replace(/\s/g, '').replace(/,/g, '\,');
			return result;
		});

	// format objects
	} else {
		values = values.map(v => (v && v.label) ? v.value : v);
	}

	// Format dates
	if (filter.isDate) {
		if (filter.isDateExpression) {
			values = values.map(item => fromDateExpression(item));
		}

		if (spec.isDateOnly) {
			values = values.map(item => formatDateOnlyToApi(item));
		} else {
			values = values.map(item => formatDateFullToApi(item));
		}
	}

	// handle special operator "Not in" with multiple values
	// so one filter `field not in (val1, val2)` will become `field:not(val1), field:not(val2)`
	if (operator === IFilterOperator.NotEquals && (operatorSpec.allowMultiple || values.length > 1)) {
		return values.map(val => `${backend}${encodeFiltersOperator(operator, caseSensitive)}${encodeFilterValue(val)}`).join(',');
	}
	// handle special operator Between which does not exist in Sieve
	// strict low boundary version
	else if ((operator === IFilterOperator.Between || operator === IFilterOperator.DateBetween) && operatorSpec.strictLowBoundary) {
		return `${backend}${encodeFiltersOperator(IFilterOperator.GreaterThan)}${encodeFilterValue(values[0])},`
			 + `${backend}${encodeFiltersOperator(IFilterOperator.LessThanOrEqualTo)}${encodeFilterValue(values[1])}`;

	// handle special operator Between which does not exist in Sieve
	// lose boundaries version
	} else if (operator === IFilterOperator.Between || operator === IFilterOperator.DateBetween) {
		return `${backend}${encodeFiltersOperator(IFilterOperator.GreaterThanOrEqualTo)}${encodeFilterValue(values[0])},`
			 + `${backend}${encodeFiltersOperator(IFilterOperator.LessThanOrEqualTo)}${encodeFilterValue(values[1])}`;

	// common cases
	} else {
		return `${backend}${encodeFiltersOperator(operator, caseSensitive)}${values.map(v => encodeFilterValue(v)).join('|')}`;
	}
}

export function allFiltersOperator(caseSensitive = false): any {
	const i = caseSensitive ? '' : '*';
	return {
		[IFilterOperator.Equals]: `==${i}`,
		[IFilterOperator.NotEquals]: `!=`,
		[IFilterOperator.GreaterThanOrEqualTo]: `>=`,
		[IFilterOperator.LessThanOrEqualTo]: `<=`,
		[IFilterOperator.GreaterThan]: `>`,
		[IFilterOperator.LessThan]: `<`,
		[IFilterOperator.NotContains]: `!@=${i}`,
		[IFilterOperator.Contains]: `@=${i}`,
		[IFilterOperator.NotStartsWith]: `!_=${i}`,
		[IFilterOperator.StartsWith]: `_=${i}`,
	};
}

export function encodeFiltersOperator(operator: IFilterOperator, caseSensitive = false): string {
	return allFiltersOperator(caseSensitive)[operator];
}

export function decodeFiltersOperatorAndValues(filterString: string): RawDecodedFilter | null {
	const ops: { [key: string]: IFilterOperator } = {...invert(allFiltersOperator(false)), ...invert(allFiltersOperator(true))};
	for (const operator in ops) {
		const matches = filterString.match(asRegExp(operator));
		if (matches && matches.length === 4) {
			return {
				operator: ops[operator],
				field: matches[1],
				operatorChar: matches[2],
				values: matches[3].split('|').map(v => decodeFilterValue(v))
			};
		}
	}
	return null;
}

function invert(object: { [key: string]: string }) {
	return Object.keys(object).reduce((acc, key) => {
		const prop = object[key];
		return {...acc, [prop]: key};
	}, {});
}

function asRegExp(operatorString: string): RegExp {
	return new RegExp(
		'^(.*)('
		+ operatorString
	.replace(/\^/g, '\\^')
	.replace(/\$/g, '\\$')
	// eslint-disable-next-line no-useless-escape
	.replace(/\*/g, '\\\*')
		+ ')(.*)$');
}

function buildFilterFromRaw(rawFilter: RawDecodedFilter, spec: IFilterSpec): IFilter {
	const filter: IFilter = {
		field: rawFilter.field,
		operator: rawFilter.operator,
		values: rawFilter.values,
		spec: spec.items.find(spec => (spec.field.backend || spec.field.value) === rawFilter.field),
	};

	filter.category = undefined; // TODO
	filter.isDate = filter.spec?.isDate;

	const specHasAmongOp = filter.spec?.operatorSpecs.find(s => s.operator === IFilterOperator.Among || s.operator === IFilterOperator.NotAmong);
	const isEqualityTest = filter.operator === IFilterOperator.Equals || filter.operator === IFilterOperator.NotEquals;

	if (specHasAmongOp && isEqualityTest && Array.isArray(filter.values)) {
		filter.operator = filter.operator === IFilterOperator.Equals ? IFilterOperator.Among : IFilterOperator.NotAmong;
		filter.values = filter.values.map(value => value.label ? value : {label: value, value});
	}

	return filter;
}

export function removeFilterByField(filters: IFilter[], ...fields: string[]): IFilter[] {
	const found: IFilter[] = [];

	fields.forEach(s => {
		const index = filters.findIndex(f => f.field === s);

		if (index > -1) {
			found.push(filters[index])
			filters.splice(index, 1);
		}
	})

	return found;
}

export function removeFilterBySpec(filters: IFilter[], ...specs: IFilterFieldSpec[]): IFilter[] {
	const found: IFilter[] = [];

	specs.forEach(s => {
		const index = filters.findIndex(f => f.field === s.field.value);

		if (index > -1) {
			found.push(filters[index])
			filters.splice(index, 1);
		}
	})

	return found;
}

export function removeFiltersMatching(filters: IFilter[], testFn: Predicate<IFilter>): IFilter[] {
	const found: IFilter[] = [];

	filters.forEach((filter, index) => {
		if (testFn(filter)) {
			found.push(filters[index])
			filters.splice(index, 1);
		}
	})

	return found;
}

export function excludeFilterBySpec(filters: IFilter[], spec: IFilterFieldSpec): IFilter[] {
	return filters.filter(f => f.field !== spec.field.value)
}

export function removeTransientFilters(filters: IFilter[]): IFilter[] {
	return filters.filter(f => !f.isTransient);
}

export function makeFiltersPermanent(filters: IFilter[]): IFilter[] {
	return filters.map(f => ({ ...f, isTransient: false }));
}

export function makeFilter(spec: IFilterFieldSpec = makeFilterSpec(), operator = IFilterOperator.Equals, ...values: any[]): IFilter {
	return { field: spec.field, spec, operator, values }
}

export function makeFilterSpec(fieldValue = uuid(), operatorSpecs: IFilterOperatorSpec[] = []): IFilterFieldSpec {
	return { field: { label: fieldValue, value: fieldValue }, operatorSpecs };
}
