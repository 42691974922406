
import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { switchMap, filter, debounceTime, map } from 'rxjs/operators';
import { combineLatest, Observable, BehaviorSubject, Subject, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { IPageAnchor } from '../models';
import { ITitleSegment } from '../models/title-segment.model';



@Injectable({
	providedIn: 'root'
})
export class PageService {

	private pageTitleParams$ = new BehaviorSubject<ITitleSegment>({ key: '', hasTranslationKey: false });
	private browserTitleParams$ = new Subject<any>();

	// the fixed, common title parts
	private titleSuffix$ = this.translateService.onLangChange.pipe(
		switchMap(_ => combineLatest([
			this.translateService.stream('App.TitleSeparator'),
			this.translateService.stream('App.Name'),
		])),
		map(([separator, appName]) => separator + appName)
	);
	// the optionaly "to be translated" browser title
	private browserTitle$: Observable<string> = this.browserTitleParams$.pipe(
		filter(p => !!p.key || !p.hasTranslationKey),
		switchMap(p => this.translateIfNeeded(p)),
		debounceTime(100) // smoother flashes (page-with-tabs, etc..)
	);
	// the optionaly "to be translated" page title
	public pageTitle$: Observable<string> = this.pageTitleParams$.pipe(
		filter(p => !!p.key || !p.hasTranslationKey),
		switchMap(p => this.translateIfNeeded(p)),
		debounceTime(100) // smoother flashes (page-with-tabs, etc..)
	);
	// the raw page title key, for cypress
	public pageTitleKey$: Observable<string> = this.pageTitleParams$.pipe(
		filter(p => !!p.key || !p.hasTranslationKey),
		map(p => p.key),
		debounceTime(100)
	);

	private pageAnchorsParams$ = new BehaviorSubject<IPageAnchor[]>([]);
	// The delay operator prevents an "Expression has changed after it was checked" error
	public readonly pageAnchors$: Observable<IPageAnchor[]> = this.pageAnchorsParams$.pipe(delay(0));

	constructor(
		private titleService: Title,
		private translateService: TranslateService) {

		combineLatest(this.browserTitle$, this.titleSuffix$, (title, suffix) => title + suffix)
			.subscribe(title => this.titleService.setTitle(title));
	}

	private translateIfNeeded(segment: ITitleSegment): Observable<string> {
		if (segment.hasTranslationKey) {
			return this.translateService.get(`PageTitles.${segment.key}`, { data: segment.params });
		} else {
			return of(segment.key);
		}
	}

	setPageTitle(segment: ITitleSegment) {
		this.pageTitleParams$.next(segment);
	}

	setBrowserTitle(segment: ITitleSegment) {
		this.browserTitleParams$.next(segment);
	}

	setTitle(pageTitleKey: string, pageTitleParams?: Params, browserTitleKey?: string, browserTitleParams?: Params) {
		this.setPageTitle({ key: pageTitleKey, params: pageTitleParams, hasTranslationKey: true });
		if (!browserTitleKey) {
			this.setBrowserTitle({ key: pageTitleKey, params: pageTitleParams, hasTranslationKey: true });
		} else {
			this.setBrowserTitle({ key: browserTitleKey, params: browserTitleParams, hasTranslationKey: true });
		}
	}

	setAnchors(anchors: IPageAnchor[]) {
		this.pageAnchorsParams$.next(anchors);
	}

	resetAnchors() {
		this.pageAnchorsParams$.next([]);
	}
}
