
import { EMPTY, Observable, combineLatest } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { Loader } from './loader.class';

export class StoreLoader extends Loader {

	isLoading$: Observable<boolean> = combineLatest([
		this._isLoading$, this._count.pipe(map(count => count > 0))
	]).pipe(
		map(([loadingStore, loadingInternal]) => (loadingStore || loadingInternal)),
		// setTimeout because we're not in an Angular component
		tap(state => setTimeout(() => this.combinedIsLoading = state)),
		shareReplay(1));

	error$: Observable<string | null> = combineLatest(this._error$, this._errorMessage,
		(errorStore, errorInternal) => errorStore || errorInternal)
		// setTimeout because we're not in an Angular component
		.pipe(tap(state => setTimeout(() => this.combinederror = state)));

	neitherLoadingNorError: Observable<boolean> = combineLatest(this.isLoading$, this.error$, (r, l) => !(r || !!l));

	private combinedIsLoading = false;
	private combinederror: string | null = null;

	get isLoading() {
		return this.combinedIsLoading;
	}

	get errorMessage(): string | null {
		return this.combinederror;
	}

	static empty() {
		return new StoreLoader(EMPTY, EMPTY);
	}

	constructor(
		public _isLoading$: Observable<boolean>,
		public _error$: Observable<string | null>,
		autoStart = false,
	) {
		super(autoStart);
	}
}
