import {
	createFeatureSelector,
	createSelector,
	MemoizedSelector
} from '@ngrx/store';
import { INotificationItem } from 'apps/debtor-portal/src/app/shared-module/models';

import { createEntitySliceSelectors } from '../functions';

import { INotificationsState } from './state';
import { FeatureName } from './feature';





export const selectState: MemoizedSelector<object, INotificationsState> = createFeatureSelector<INotificationsState>(FeatureName);

export const {
	selectEntity: selectAllNotifications,
	selectError: selectNotificationsError,
	selectIsLoading: selectNotificationsIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, (state) => state.notifications));

const getNotificationsEntity = (state: INotificationsState): INotificationItem[] => state.notifications ? state.notifications.entity : [];

const getNotifications = (state: INotificationsState): INotificationItem[] => state.showUnreadOnly ? getUnread(state) : getNotificationsEntity(state);
const getUnreadCount = (state: INotificationsState): number => getNotificationsEntity(state).filter(n => !n.isRead).length;
const getAllCount = (state: INotificationsState): number => getNotificationsEntity(state).length;
const getUnread = (state: INotificationsState): INotificationItem[] => getNotificationsEntity(state).filter(n => !n.isRead);
const getIsOpened = (state: INotificationsState): boolean => state.isOpened;
const getShowUnreadOnly = (state: INotificationsState): boolean => state.showUnreadOnly;
const getHasUnread = (state: INotificationsState): boolean => getUnread(state).length > 0;


export const selectIsOpened = createSelector(selectState, getIsOpened);
export const selectNotifications = createSelector(selectState, getNotifications);
export const selectUnreadNotifications = createSelector(selectState, getUnread);
export const selectShowUnreadOnly = createSelector(selectState, getShowUnreadOnly);
export const selectAllCount = createSelector(selectState, getAllCount);
export const selectUnreadCount = createSelector(selectState, getUnreadCount);
export const selectHasUnread = createSelector(selectState, getHasUnread);
