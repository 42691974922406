import { ActivatedRouteSnapshot } from '@angular/router';


export function getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return '/' + route.pathFromRoot
        .filter(v => v.url.length > 0)
        .map(v => v.url.map(segment => segment.toString()).join('/'))
        .join('/');
}

export function getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    return '/' + route.pathFromRoot
        .filter(v => v.routeConfig)
        .map(v => v.routeConfig?.path)
        .join('/');
}

export function errorShouldBeSentToAI(error: Error): boolean {
	// Ugly but not really useful for insights
	if (error?.message?.match(/ExpressionChangedAfterItHasBeenCheckedError/)) {
		return false;
	}
	return true;
}
