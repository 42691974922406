
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable, from, of } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { HttpErrorMessage } from '../classes';
import { ICustomErrorApi } from '../models';
import { TelemetryService, LanguageService, CriticalStateService } from '../services';

export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private translateService: TranslateService,
		private languageService: LanguageService,
		private criticalStateService: CriticalStateService,
		private telemetryService: TelemetryService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next
			.handle(request)
			.pipe(catchError((err: HttpErrorResponse) => {
				return err['translated'] ? throwError(err) : this.manageHttpErrorResponse(err, request, next);
			}));
	}

	manageHttpErrorResponse(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.telemetryService.error(error);

		const errorMessage = new HttpErrorMessage(error);

		// Unwrapped error
		if (!error.error && error.status && error.name === 'HttpErrorResponse') error = { ...error, error };

		// Client Side Error
		if (error.error instanceof ErrorEvent) {
			errorMessage.appendKey('DefaultClient').withParams({ errorMessage: error.error.message });

		// Server Side Error
		} else if (error.error) {

			if (error.error instanceof Blob) {
				// It's a blob !! Read it and create a clone of the error with the read text.
				return this.handleBlobError(error, request, next);
			}

			// Unauthorized because user does not exist should be handled
			if ((<ICustomErrorApi><unknown>error).specificError === 'LoggedInUserDoesNotExists') {
				this.criticalStateService.registerCriticalError(error);
			}

			// Custom error unhandled
			if (!error.error.errorCode || !this.handleCustomError(error.error, errorMessage)) {
				// Generic status code error
				if (error.status) {
					switch (error.status) {
						case 400:
							// Token mismatch !
							// We are trying to reuse an old token ? Let the openid workflow do its thing.
							//
							// Bug 20849: [ACC] [MO] : Dysfonctionnement de la homepage suite à clic sur "Accéder à Cash Collection"
							// Improvement 20979: Afficher les éléments de la home page même si il n'y a pas de données
							// eslint-disable-next-line max-depth
							if (error.error?.error_description?.includes('match the client redirection endpoint')) {
								// Try to repeat the failed request, this may restart the openid flow.
								return of(null).pipe(delay(500), switchMap(_ => next.handle(request.clone())));
							}
							errorMessage.appendKey(error.error.error === 'invalid_grant' ? 'InvalidGrant' : error.status);
							break;
						case 401:
						case 402:
						case 403:
						case 404:
						case 500:
							errorMessage.appendKey(error.status);
							break;
						default:
							errorMessage.appendKey('DefaultServer');
					}

					// Unknown error
				} else {
					errorMessage.appendKey('UnknownServer');
				}
			}

			// Default error
		} else {
			errorMessage.fromCode(error.status)
				.appendKey('DefaultServer')
				.withParams({ errorMessage: `${error.message} (${error.status})` });
		}

		errorMessage.translate(this.translateService);

		if (error.error && error.error.extraData) {
			errorMessage.extraData = error.error.extraData;
		}

		if (error.error && error.error.specificError) {
			errorMessage.specificError = error.error.specificError;
		}

		console.error(errorMessage);
		return throwError(errorMessage);
	}

	handleCustomError(customError: ICustomErrorApi, errorMessage: HttpErrorMessage): boolean {
		const specificError = this.getSpecificErrorCode(customError);

		if (this.hasTranslation(specificError)) {
			errorMessage.appendKey(specificError).withParams(customError);
			return true;
		} else if (this.hasTranslation(customError.errorCode)) {
			errorMessage.asFunctional().appendKey(customError.errorCode).withParams(customError);
			return true;
		}

		return false;
	}

	handleBlobError(error: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler) {
		return from(new Promise<any>((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (e: Event) => {
				try {
					const errmsg = JSON.parse((<any>e.target).result);
					const cloneError = new HttpErrorResponse({
						error: errmsg,
						headers: error.headers,
						status: error.status,
						statusText: error.statusText,
						url: error.url
					});
					return resolve(cloneError);
					// reject();
				} catch (e) {
					reject(error);
				}
			};
			reader.onerror = _ => {
				reject(error);
			};
			reader.readAsText(error.error);
		})).pipe(
			switchMap(input => {
				return this.manageHttpErrorResponse(input, request, next);
			})
		);
	}

	getSpecificErrorCode(customError: ICustomErrorApi): string {
		return customError.specificError.split('.').pop();
	}

	hasTranslation(key: string): boolean {
		const translations = this.translateService.translations;
		const currentLanguage = this.languageService.getCurrentLanguage();
		return !!this.translateService.parser.getValue(translations[currentLanguage], 'Errors.' + key);
	}
}
