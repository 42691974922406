
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { FeatureName } from './feature';
import { appReducer } from './reducer';
import { AppStoreEffects } from './effects';


@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(FeatureName, appReducer, ),
		EffectsModule.forFeature([AppStoreEffects])
	]
})

export class AppStoreModule { }
