import { NgbPaginationConfig, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { AppLanguage } from '../enums';
import { IFactorConfiguration, IFileUploaderConfig } from '../models';
import { NgbOffcanvasOptions } from '../ngbootstrap-backports/offcanvas/offcanvas-config';

export class AppConstants {
	static API_LANGUAGE = AppLanguage.FR;
	static STORE_LANGUAGE = true;

	static KILOBYTE = 1024;
	static MEGABYTE: number = AppConstants.KILOBYTE * 1000;
	static GIGABYTE: number = AppConstants.MEGABYTE * 1000;

	static FILE_NAME_MAX_LENGTH = 60;

	static MILLISECONDS = 1000;
	static SECOND: number = 1 * AppConstants.MILLISECONDS;
	static MINUTE: number = 60 * AppConstants.SECOND;
	static HOUR: number = 60 * AppConstants.MINUTE;

	// waiting time for multiple tabs to acknowledge a logout
	static SHARED_POST_LOGOUT_DELAY = 100 * AppConstants.MILLISECONDS;
	static WEBSOCKET_INIT_RECONNECT_TIMEOUT = 20 * AppConstants.SECOND;
	static WEBSOCKET_INIT_RECONNECT_MAX_RETRIES = 10;

	static ANIMATED_SCROLL_ELEMENT_DEFAULT: any = 'body';

	static COMMENT_NEWLINE_WEIGHT = ' ' + '-------newline-padding--------'; // no spaces here

	static LOCALSTORAGE_KEYS = {
		APP_LANGUAGE: 'app-language',
		USER_INFOS: 'user-info',
		USER_NAVIGATION: 'user-navigation',
		ACCESS_TOKEN: 'access-token',
		REFRESH_TOKEN: 'refresh-token'
	};

	static DEFAULT_MODAL_OPTIONS: NgbModalOptions = {
		size: 'lg', centered: true, keyboard: false, backdrop: 'static'
	};

	static DEFAULT_OFFCANVAS_OPTIONS: NgbOffcanvasOptions = {
		position: 'end'
	};

	static FILE_UPLOADER_CONFIGS: { [key: string]: IFileUploaderConfig } = {
		DEFAULT: {
			acceptExtensions: ['jpg', 'jpeg', 'png', 'gif', 'pdf'],
			maxSize: 4 * AppConstants.MEGABYTE
		},
	}

	static DEFAULT_FACTOR_CONFIGURATION: IFactorConfiguration = {
		isKycRegistrationChecklistEnabled: false,
		isSellerCreditLimitationEnabled: false,
		isCreditLimitEnabled: false,
		isInsuranceProviderEnabled: true,
		isDigitalSignatureEnabled: false,
		isFinancingContractEnabled: true,
		billingDefaultCountryCode: 'FR',
		apiTimeZone: 'Europe/Paris',
		countries: [],
		currencies: []
	};

	static LIST_ENUM_SEP_CHAR = ', ';


	static LIST_PAGE_SIZES = [5, 10, 25, 50, 100];
	static LIST_PAGE_SIZE_DEFAULT = 10;
	static LIST_PAGE_SIZE_IGNORED = -1;
	static LIST_PAGE_SIZE_MAX = 5000;

	static PAGING_CONFIG_DEFAULT = <NgbPaginationConfig>{
		boundaryLinks: false,
		directionLinks: true,
		ellipses: true,
		rotate: true,
		maxSize: 3
	};

	static BULK_EDIT_MAX_SIZE = 1000;

	static MIN_AUTOCOMPLETER_INPUT_LENGTH = 3;

	static MAX_MULTI_FILTERS_ROWS = 5;
	static MAX_USER_FILTERS_PER_DOMAIN = 10;
	static DEFAULT_USER_FILTERS_PER_DOMAIN = 3;
	static FILTERS_MULTISELECT_SIZE = 5;
	static FILTERS_VALUE_NULL = 'null';
	static FILTERS_VALUE_NOT_NULL = 'notnull';
	static FILTERS_VALUE_ALL = 'All';
	static FILTERS_VALUE_NOT_ALL = 'notAll';

	static DATE_ONLY_FORMAT = 'yyyy-MM-dd';
	static DATE_FULL_FORMAT = 'yyyy-MM-ddTHH:mm:ss';

	// Currently used in an input mask
	static IBAN_MASK = 'AAAA | AAAA | AAAA | AAAA | AAAA | AAAA | AAA';
	// Currently used in a text pipe
	static IBAN_MASK_TEXT = 'AAAA AAAA AAAA AAAA AAAA AAAA AAA';

	static BIC_MASK = 'AAAAAAAAAAA';
	static IBAN_MASK_SPECIAL_CHAR = ['|', ' '];
	static SPACE_MASK_SPECIAL_CHAR = [' '];
	static PHONE_MASK = '00 00 00 00 00';
	static SIRET_MASK = '000 000 000 00000';
	static AMOUNT_MASK = '0*.00';
	static MAIL_VERIFICATION_CODE = '000000';

	static DATE_EXPRESSION_TOKEN_TYPES = {
		DAY: 'D',
		WEEK: 'W',
		MONTH: 'M'
	};

	static QUICKSTART_PAGES = [
		'KB.QuickStart1', // 'definitions/page/quickstart1',
		'KB.QuickStart2', // 'definitions/page/quickstart2',
	];

	static DATE_EXPRESSION_PATTERNS = {
		TOKENS: 'D|s|W|s|M|m', 		// Day, Week, Month
		OPERATORS: '\\+|-',			// Plus, Minus
		NUMBERS:  '[0-9]{1,3}'		// 0 => 999
	};

	static DATE_EXPRESSION_REGEX = `^(${AppConstants.DATE_EXPRESSION_PATTERNS.TOKENS})(${AppConstants.DATE_EXPRESSION_PATTERNS.OPERATORS})${AppConstants.DATE_EXPRESSION_PATTERNS.NUMBERS}$`;

	static DATE_EXPRESSION_MASK_PATTERNS = {
		'T': { pattern: new RegExp(AppConstants.DATE_EXPRESSION_PATTERNS.TOKENS) },
		'O': { pattern: new RegExp(AppConstants.DATE_EXPRESSION_PATTERNS.OPERATORS) },
		'N': { pattern: new RegExp('[0-9]') },
		'n': { pattern: new RegExp('[0-9]'), optional: true }
	};

	static DATE_EXPRESSION_MASK = 'TONnn';
	static DECIMAL_EXPRESSION_MASK = /^-?[0-9]{0,15}([\\.,][0-9]{0,2})?$/;

	static DATEPICKER_THEME = 'theme-dark-blue';

	static LIST_HANDLER_FILTERS_MULTI = false;

	static BOOKMARKS_LIMIT = 5;

	static overrideWith(newValues: AppConstants) {
		Object.assign(AppConstants, newValues);
	}

	static updateDateExpressionWithTranslation(tokens: { [key: string]: string }) {
		// Based on "Filters.DateExpression" translations keys

		AppConstants.DATE_EXPRESSION_TOKEN_TYPES.DAY = tokens.DayToken;
		AppConstants.DATE_EXPRESSION_TOKEN_TYPES.WEEK = tokens.WeekToken;
		AppConstants.DATE_EXPRESSION_TOKEN_TYPES.MONTH = tokens.MonthToken;

		AppConstants.DATE_EXPRESSION_PATTERNS.TOKENS = tokens.AllTokens;
		AppConstants.DATE_EXPRESSION_MASK_PATTERNS.T = { pattern: new RegExp(tokens.AllTokens) };

		AppConstants.DATE_EXPRESSION_REGEX = `^(${tokens.AllTokens})(${AppConstants.DATE_EXPRESSION_PATTERNS.OPERATORS})${AppConstants.DATE_EXPRESSION_PATTERNS.NUMBERS}$`;
	}
}
