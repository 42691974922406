
import { createAction, props } from '@ngrx/store';
import { IAccountingItemList, IDebtorKPIs, IDunningActionList } from 'apps/debtor-portal/src/app/accounting-module/models';
import { AccountingListSortProp, DunningActionListSortProp } from 'apps/debtor-portal/src/app/accounting-module/enums';
import { IFilter } from '@aston/foundation';

import { createListActions, createEntityActions, createDebugAction, createTypedAction, EntityActionType } from '../functions';

const ActionPrefix = 'Accounting';

export const Debug = createDebugAction(`[${ActionPrefix}]`);

export const accountingListActions = {
	...createListActions<IAccountingItemList, AccountingListSortProp, IFilter>(ActionPrefix,
	'Accounting'),
	LoadAccountingRequestForDunningActionId: createTypedAction(EntityActionType.LoadEntityByIdRequest, `[${ActionPrefix}] Load Accounting By DunningActionId Request`, props<{ dunningActionId: number }>()),
};
export const {
	LoadListRequest: LoadAccountingRequest,
	LoadListSuccess: LoadAccountingSuccess,
	LoadListFailure: LoadAccountingFailure,
	ListSettings: UpdateAccountingSettings,
	ExportListRequest: ExportAccountingRequest,
	ExportListSuccess: ExportAccountingSuccess,
	ExportListFailure: ExportAccountingFailure,
	LoadAccountingRequestForDunningActionId,
} = accountingListActions;

export const OpenAccountingDocument = createAction(
	`[${ActionPrefix}] Open Accounting Document`,
	props<{ accountingDocumentId: number; accountingNumber: string; }>()
);

export const OpenAccountingDocumentInNewTab = createAction(
	`[${ActionPrefix}] Open Accounting Document In New Tab`,
	props<{ accountingDocumentId: number; accountingNumber: string; }>()
);

export const debtRecoveryAdvisorsActions = createEntityActions(ActionPrefix, 'Debt Recovery Advisors');

export const kpisActions = createEntityActions<IDebtorKPIs>(ActionPrefix, 'KPIs');

export const {
	LoadEntityRequest: LoadDebtorKPIsRequest,
	LoadEntitySuccess: LoadDebtorKPIsSuccess,
	LoadEntityFailure: LoadDebtorKPIsFailure
} = kpisActions;

export const OpenPaymentModal = createAction(`[${ActionPrefix}] Open Payment Modal`);

export const dunningListActions = createListActions<IDunningActionList, DunningActionListSortProp, IFilter>(ActionPrefix, 'Dunning Actions');
export const {
	LoadListRequest: LoadDunningActionsRequest,
	LoadListSuccess: LoadDunningActionsSuccess,
	LoadListFailure: LoadDunningActionsFailure,
	ListSettings: UpdateDunningActionsSettings,
} = dunningListActions;

export const invoicingListActions = createListActions<IAccountingItemList, AccountingListSortProp, IFilter>(ActionPrefix, 'Invoicing');

export const {
	LoadListRequest: LoadInvoicingRequest,
	LoadListSuccess: LoadInvoicingSuccess,
	LoadListFailure: LoadInvoicingFailure,
	ListSettings: UpdateInvoicingSettings,
	ExportListRequest: ExportInvoicingRequest,
	ExportListSuccess: ExportInvoicingSuccess,
	ExportListFailure: ExportInvoicingFailure,
} = invoicingListActions;

export const OpenInvoice = createAction(
	`[${ActionPrefix}] Open Invoice`,
	props<{ invoiceId: number; invoiceNumber: string; }>()
);

export const OpenInvoiceInNewTab = createAction(
	`[${ActionPrefix}] Open Invoicing Document In New Tab`,
	props<{ invoiceId: number; invoiceNumber: string; }>()
);