<h2 translate="KPI.Title"></h2>
@if (data) {
    <div class="invoices-recap kpis-list">
        <div class="kpis-list-item">
            <div class="value">
                <div class="icon">
                    <i class="fal fa-coins"></i>
                </div>
                <strong class="amount">{{
                    data.invoicingTotalOutstandingAmount | amount: { with: 'symbol-narrow', currency: currency }
                }}</strong>
            </div>
            <div class="label" translate="KPI.TotalOutstandingAmount"></div>
        </div>
        <div class="kpis-list-item">
            <div class="value">
                <div class="icon">
                    <i class="fal fa-wallet"></i>
                </div>
                <strong class="amount">{{
                    data.invoicingExpiredOutstandingAmount | amount: { with: 'symbol-narrow', currency: currency }
                }}</strong>
            </div>
            <div class="label" translate="KPI.ExpiredOutstandingAmount"></div>
        </div>
        <div class="kpis-list-item">
            <div class="value">
                <div class="icon">
                    <i class="fal fa-exchange"></i>
                </div>
                <strong class="amount">{{
                    data.invoicingTotalOutstandingAmount -
                        data.invoicingExpiredOutstandingAmount
                        | amount: { with: 'symbol-narrow', currency: currency }
                }}</strong>
            </div>
            <div class="label" translate="KPI.ToExpiredOutstandingAmount"></div>
        </div>
        <div class="kpis-list-item">
            <div class="value">
                <div class="icon">
                    <i class="fal fa-credit-card"></i>
                </div>
                <strong class="amount">{{
                    'CommonPluralTexts.Day'
                        | plural: data.averageOverdueDays
                        | translate: { count: +data.averageOverdueDays }
                }}</strong>
            </div>
            <div class="label" translate="KPI.AverageOverdue"></div>
        </div>
    </div>
}
