export enum DunningLevel {
	None = 'None',
    STD = 'STD',
    PAA = 'PAA',
    PPA = 'PPA',
    LTG = 'LTG',
    CTX = 'CTX',
    CTF = 'CTF',
    TPP = 'TPP',
    RGD = 'RGD',
    DIC = 'DIC',

    EXR = 'EXR', // always last
}
