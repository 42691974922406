
import animateScrollTo, { IUserOptions } from 'animated-scroll-to';
import { fromEvent, merge, Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConstants } from '../config';

export function scrollToElement(target: string | number = 0, options?: IUserOptions): Observable<boolean> {
	const animComplete: Subject<boolean> = new Subject();

	const config: IUserOptions = {
		// duration of the scroll per 1000px, default 500
		speed: 500,

		// minimum duration of the scroll
		minDuration: 250,

		// maximum duration of the scroll
		maxDuration: 1500,

		// DOM element to scroll, default window
		// Pass a reference to a DOM object
		// Example: document.querySelector('#element-to-scroll'),
		elementToScroll: typeof AppConstants.ANIMATED_SCROLL_ELEMENT_DEFAULT === 'string' ?
			document.querySelector(<string>AppConstants.ANIMATED_SCROLL_ELEMENT_DEFAULT) :
			window,

		// should animated scroll be canceled on user scroll/keypress
		// if set to "false" user input will be disabled until animated scroll is complete
		// (when set to false, "passive" will be also set to "false" to prevent Chrome errors)
		cancelOnUserAction: true,

		...options,
	};

	if (typeof(target) === 'string') {
		const element = <HTMLElement>document.querySelector(target);
		if (element) {
			animateScrollTo(element, config).then(() => {
			animComplete.next(true);
			animComplete.complete();
		});
		} else {
			console.warn(`[ui] cannot scroll to "${target}": no such element in DOM`);
		}
	} else {
		animateScrollTo(target, config).then(() => {
			animComplete.next(true);
			animComplete.complete();
		});
	}

	return animComplete.asObservable();
}

export function scrollToTop(element?: string): Observable<boolean> {
	const options: any = {};
	if (element) {
		options.element = <HTMLElement>document.querySelector(element)
	}
	return scrollToElement(0, options);
}

export const onLine$ = merge(
	of(null),
	fromEvent(window, 'online'),
	fromEvent(window, 'offline')
).pipe(map(() => navigator.onLine))
