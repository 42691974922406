import { ApplicationRef, Injectable, Injector } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, EMPTY, from } from 'rxjs';
import { map, switchMap, withLatestFrom, first, catchError, filter, tap, debounceTime, mergeMap, delay } from 'rxjs/operators';
import { AppConfiguration } from 'apps/debtor-portal/src/app/app.configuration';
import * as RoutesDefinitions from 'apps/debtor-portal/src/app/routes-definitions';
import { Router } from '@angular/router';
import { ValidationService, FactorConfigurationService, LanguageService } from '@aston/foundation';
import { TelemetryService, NotificationService } from '@aston/foundation';
import { scrollToTop } from '@aston/foundation';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionType, BrowserNavigationService, ConfirmModalComponent, IConfirmModalResult } from '@aston/foundation';
import { ScriptLoaderService } from 'apps/debtor-portal/src/app/shared-module/services/script-loader.service';
import { DocumentFileService, WebSocketService } from 'apps/debtor-portal/src/app/shared-module/services';
import { saveAs } from 'file-saver';
import { AuthenticationService } from 'apps/debtor-portal/src/app/authentication-module/services';
import { ITenantInfos } from 'apps/debtor-portal/src/app/authentication-module/models';
import { AppConstants } from 'apps/debtor-portal/src/app/app.constants';
import { TranslateService } from '@ngx-translate/core';
import { IConfigurationReferential } from 'apps/debtor-portal/src/app/shared-module/models';
import { TenantUrl } from 'apps/debtor-portal/src/app/shared-module/functions';
import { DocumentFilesViewerComponent } from 'apps/debtor-portal/src/app/shared-module/components/document-files-viewer/document-files-viewer.component';

import { AccountingStoreSelectors } from '../accounting-store';

import * as featureSelectors from './selectors';
import * as featureActions from './actions';

@Injectable({providedIn: 'root'})
export class AppStoreEffects {

	isInactivityWarningModalActive = false;

	constructor(
		private appRef: ApplicationRef,
		private router: Router,
		private actions$: Actions,
		private store: Store,
		private appConfig: AppConfiguration,
		private validationService: ValidationService,
		private factorConfigurationService: FactorConfigurationService,
		private modalService: NgbModal,
		private notificationService: NotificationService,
		private languageService: LanguageService,
		private translateService: TranslateService,
		private scriptLoaderService: ScriptLoaderService,
		private documentFileService: DocumentFileService,
		private authenticationService: AuthenticationService,
		private browserNav: BrowserNavigationService,
		private injector: Injector,
		private ws: WebSocketService
	) {
	}

	toastsResetEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ToastReset),
		tap(_ => {
			this.notificationService.reset();
		})
	), {dispatch: false});

	toastEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ToastError, featureActions.ToastWarning, featureActions.ToastSuccess),
		tap(action => {
			this.notificationService.notify({
				type: action.level,
				message: action.message,
				messageParams: action.messageParams,
				options: action.options
			});
		})
	), {dispatch: false});

	errorLogEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ErrorLog),
		tap(action => {
			console.error(action.error);
			// FIXME: TelemetryService cannot be injected directly yet
			this.injector.get(TelemetryService).error(action.error, action.data);
		})
	), {dispatch: false});

	infoLogEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InfoLog),
		tap(action => {
			// tslint:disable-next-line:no-console
			console.info(action.message); // eslint-disable-line no-restricted-syntax
			// FIXME: TelemetryService cannot be injected directly yet
			this.injector.get(TelemetryService).event(action.message, action.data);
		})
	), {dispatch: false});

	startInitializerEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.StartInitializer),
		switchMap(_ => [
			// plug here config-independent actions
			featureActions.UpdateAppConfigurationRequest(),
			featureActions.ChangeLanguage({ language: this.languageService.getCurrentLanguage() })
		])
	));

	loadValidatorsEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadValidatorsRequest),
		switchMap(_ => this.validationService.loadValidationRuleset()),
		map(_ => featureActions.LoadValidatorsSuccess()),
		catchError((bootstrapError: Error) => of(featureActions.CriticalFailure({bootstrapError})))
	));

	onCriticalFailure$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.CriticalFailure),
		withLatestFrom(this.store.select(featureSelectors.selectConfig)),
		tap(([action, config]) => {
			if (action.goToConsole) {
				console.error(`Encountered a critical error, you will be redirected.`, action.error);
				setTimeout(_ => location.assign(config.consoleUrl), AppConstants.CRITICAL_ERROR_RELOAD_TIMEOUT)
			} else if (!action.bootstrapError) {
				console.error(`Encountered a critical error, authentication issue page will be displayed.`, action.error);
				const issueFullPath = RoutesDefinitions.getAuthenticationIssueFullPath();
				this.router.navigate([issueFullPath]);
			} else {
				// there is not much we can do
				// just reload and hope for the best
				setTimeout(_ => location.reload(), 10000)
			}

		})
	), {dispatch: false});

	updateConfigEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateAppConfigurationRequest),
		switchMap(_ => from(this.appConfig.ensureInit())),
		withLatestFrom(this.store.select(featureSelectors.selectCurrentLanguage)),
		tap(([config, currentLang]) => {
			if (!currentLang?.language && config.defaultLang) {
				this.languageService.setCurrentLanguage(config.defaultLang)
			}
			if (!this.translateService.defaultLang && config.defaultLang) {
				this.translateService.setDefaultLang(config.defaultLang)
			}
		}),
		switchMap(_ => [
			featureActions.UpdateAppConfigurationSuccess({config: this.appConfig}),
			// plug here config-dependant pre-user actions
			featureActions.LoadValidatorsRequest(),
			featureActions.LoadFactorConfigurationRequest()
		])
	));

	checkInitializersEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateAppConfigurationSuccess,
			featureActions.LoadValidatorsSuccess,
			featureActions.LoadTenantIdentitySuccess,
			featureActions.LoadFactorConfigurationSuccess),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		switchMap(([_, store]) => this.translateService.get('App.Name', {failSilently: true}).pipe(map(t => [store, t]))),
		switchMap(([store, i18nLoaded]) => {
			const configIsPresent = !!store.config;
			const actions = [];

			actions.push(featureActions.FinishInitializer());

			if (configIsPresent && i18nLoaded) {
				actions.push(featureActions.AppReady());
			}

			return actions;
		})
	));

	loadFactorConfiguration$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadFactorConfigurationRequest),
		switchMap(_ => this.factorConfigurationService.getFactorConfig().pipe(
			switchMap(entity => [
				featureActions.LoadFactorConfigurationSuccess({entity}),
				featureActions.LoadCountriesSuccess({ entity: entity.countries })
			]),
			catchError(error => of(featureActions.LoadFactorConfigurationFailure({error})))
		))
	));

	openConfirmationModal$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenConfirmationModal, featureActions.OpenSmallConfirmationModal),
		tap(action => {
			const modalRef = this.modalService.open(ConfirmModalComponent, {...AppConstants.DEFAULT_MODAL_OPTIONS, ...action.options});
			modalRef.componentInstance.contentConfig = action.content;
			from(modalRef.componentInstance.answer).pipe(
				map((reason: IConfirmModalResult) => {
					this.store.dispatch(featureActions.CloseConfirmationModal({
						actionType: reason.action, correlationParams: action.correlationParams
					}));
					if (reason.action === ActionType.CANCEL && action.cancelAction) {
						[].concat(action.cancelAction).forEach(a => this.store.dispatch(a));
					}
					if (reason.action === ActionType.VALIDATE && action.confirmationAction) {
						if (action.confirmationAction instanceof Function) {
							action.confirmationAction(reason).forEach(a => this.store.dispatch(a));
						} else {
							[].concat(action.confirmationAction).forEach(a => this.store.dispatch(a));
						}
					}
				}),
				first(),
				catchError(reason => {
					this.store.dispatch(featureActions.CloseConfirmationModal({
						actionType: reason, correlationParams: action.correlationParams
					}));
					if (reason === ActionType.CANCEL && action.cancelAction) {
						[].concat(action.cancelAction).forEach(a => this.store.dispatch(a));
					}
					return EMPTY;
				}),
			).subscribe();
		})
	), {dispatch: false});

	startScrollToTop$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.StartScrollToTop),
		switchMap(action => scrollToTop(action.element).pipe(
			map(_ => featureActions.FinishScrollToTop())
		)),
	));

	discardFocus$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DiscardFocus),
		debounceTime(200),
		tap(_ => (document.activeElement as HTMLDivElement).blur()),
	), {dispatch: false});

	onNavigate$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.Navigate),
		tap(action => {
			this.router.navigate(action.to);
		})
	), {dispatch: false});

	onNotFound$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.NotFound),
		tap(context => this.router.navigateByUrl('/not-found', {skipLocationChange: true, state: {context}}))
	), {dispatch: false});

	onNavigateBack$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.NavigateBack),
		map(_ => {
			if (this.browserNav.hasPrevious) {
				window.history.back();
				return featureActions.Noop();
			} else {
				return featureActions.Navigate({
					to: [RoutesDefinitions.getBasePath()]
				});
			}
		})
	));

	loadUserOnFinishInit$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.Login),
		withLatestFrom(this.store.select(featureSelectors.selectConfig)),
		tap(_ => {
			if (window.parent) {
				try {
					// Dispatch an event to the parent window (aka the Console)
					window.parent.dispatchEvent(new CustomEvent('aston-auth-done'));
				} catch (e) {
					// Task 33713: [MO] Front / E2E / Play nice with Cypress
					console.warn(`[init] couldn't dispatch auth event higher up`)
				}
			}
		}),
		switchMap(([action, config]) => [
			// Put here post-user actions
			featureActions.ChangeLanguage({ language: action.userData.language || config.defaultLang }),
			featureActions.LoadValidatorsRequest(),
			featureActions.LoadTenantIdentityRequest(),
			featureActions.LoadConfigurationReferentialRequest(),
		])
	));

	configurationReferentialEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadConfigurationReferentialRequest),
		switchMap(_ => of({} as IConfigurationReferential).pipe(
			map(entity => featureActions.LoadConfigurationReferentialSuccess({ entity })),
			catchError(error => of(featureActions.LoadConfigurationReferentialFailure({ error })))
		))
	));

	loadTenantSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadTenantIdentityRequest),
		switchMap(_ => of({} as ITenantInfos).pipe(
			map(entity => featureActions.LoadTenantIdentitySuccess({entity})),
			catchError(error => of(
				featureActions.LoadTenantIdentityFailure({error}),
				featureActions.CriticalFailure({bootstrapError: error}),
			))
		))
	));

	navigateToLastSavedRoute$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.NavigateToLastSavedRoute),
		debounceTime(500),
		filter(action => !window.location.pathname.endsWith(action.route)),
		map(action => featureActions.OpenConfirmationModal(
			{textsKey: 'UserSession.PostAuthenticationNavigation.'},
			featureActions.Navigate({to: [action.route]}),
			featureActions.UpdateUserSessionRequest({userSession: {lastRoute: null}})
		))
	));

	// Ugly workaround to reload all components (and pure pipes results)
	reloadPageEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ReloadPage),
		withLatestFrom(this.store.select(AccountingStoreSelectors.selectDunningActionId)),
		tap(([_, id]) => {
			const currentRoute = this.router.url;
			const tmpRoute = this.router.url.match(/actions/) ?
				RoutesDefinitions.getDunningAccountingFullPath(id) :
				RoutesDefinitions.getDunningActionsListFullPath();
			this.router
				.navigateByUrl(tmpRoute)
				.then(_ => this.appRef.tick())
				.then(_ => setTimeout(_ => this.router.navigateByUrl(currentRoute), 1000));
		})
	), {dispatch: false});

	onLanguageChange$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ChangeLanguage),
		tap(action => this.languageService.setCurrentLanguage(action.language))
	), {dispatch:false});

	loadScript$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadScriptRequest),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		filter(([action, state]) => !state.scripts[action.script]),
		switchMap(([action]) => this.scriptLoaderService.load(action.script).pipe(
			map(_ => featureActions.LoadScriptSuccess({script: action.script})),
			catchError(error => of(featureActions.ErrorLog(error)))
		))
	));

	downloadDocument$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DownloadDocument),
		switchMap(action => {
			if (action.document.id) {
				this.store.dispatch(featureActions.ToastWarning('Actions.PreparingFile'));
				return this.documentFileService.getLocalFileURL(action.document).pipe(
					tap(document => saveAs(document.url['changingThisBreaksApplicationSecurity'], (action.name || action.document.name))),
					delay(800),
					tap(_ => this.store.dispatch(featureActions.ToastReset())),
					catchError(error => of(featureActions.ErrorLog(error)))
				);
			} else {
				return of(featureActions.ErrorLog(`Cannot open document ${JSON.stringify(document)}`));
			}
		})
	), {dispatch: false});

	openDocument$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenDocument),
		switchMap(action => {
			// local file, or URL already fetched
			if (action.document.url && !!action.document.url['changingThisBreaksApplicationSecurity']) {
				return of(featureActions.OpenUrl({
					url: action.document.url['changingThisBreaksApplicationSecurity'],
					fileName: action.document.name
				}));
				// a document with an an id
			} else if (action.document.id) {
				return this.documentFileService.getLocalFileURL(action.document).pipe(
					map(document => featureActions.OpenUrl({
						url: document.url['changingThisBreaksApplicationSecurity'],
						fileName: action.document.name
					})),
					catchError(error => of(featureActions.ErrorLog(error)))
				);
				// unhandled
			} else {
				return of(featureActions.ErrorLog(`Cannot open document ${JSON.stringify(document)}`));
			}
		})
	));

	viewDocument$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ViewDocument),
		tap(action => {
			const modalRef = this.modalService.open(DocumentFilesViewerComponent, AppConstants.DEFAULT_MODAL_OPTIONS);
			modalRef.componentInstance.item = action.document;
			modalRef.componentInstance.title = action.document.name;
		})
	), { dispatch: false });

	openLink$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.OpenUrl),
		tap(action => {
			const a = document.createElement('a');
			let url = action.url;
			if (url.startsWith('/')) {
				// relative urls should use current location+tenantId
				url = (new TenantUrl(url)).toString();
			}
			a.setAttribute('href', url);
			a.setAttribute('target', '_blank');
			if (action.download) {
				a.setAttribute('download', action.fileName);
			}
			a.click();
		}),
		catchError(error => {
			// the browser probably blocked our window to open
			error.message = 'Cannot open window. ' + error.message;
			return of(featureActions.ErrorLog(error));
		})
	), {dispatch: false});

	printDocument$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.PrintDocument),
		mergeMap(action => {
			// local file, or URL already fetched
			if (action.document.url && !!action.document.url['changingThisBreaksApplicationSecurity']) {
				return of(featureActions.PrintUrl(action.document.url['changingThisBreaksApplicationSecurity']));
				// a document with an an id
			} else if (action.document.id) {
				return this.documentFileService.getLocalFileURL(action.document).pipe(
					map(document => featureActions.PrintUrl({url: document.url['changingThisBreaksApplicationSecurity']})),
					catchError(error => of(featureActions.ErrorLog(error)))
				);
				// unhandled
			} else {
				return of(featureActions.ErrorLog(`Cannot print document ${JSON.stringify(document)}`));
			}
		})
	));

	printURL$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.PrintUrl),
		tap(action => {
			window.open(action.url, '_blank').print();
		}),
		catchError(error => {
			// the browser probably blocked our window to open
			error.message = 'Cannot open print window. ' + error.message;
			return of(featureActions.ErrorLog(error));
		})
	), {dispatch: false});

	clipboardCopy$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ClipboardCopy),
		tap(action => {
			const selBox = document.createElement('textarea');
			selBox.style.position = 'fixed';
			selBox.style.left = '0';
			selBox.style.top = '0';
			selBox.style.opacity = '0';
			selBox.value = action.text;
			document.body.appendChild(selBox);
			selBox.focus();
			selBox.select();
			document.execCommand('copy');
			document.body.removeChild(selBox);
		}),
		map(_ => featureActions.ToastSuccess('Toasts.TextCopied'))
	));

	logoutEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.Logout),
		tap(_ => {
			this.authenticationService.logout();
		})
	), {dispatch: false});

	navigateToConsoleEffect = createEffect(() => this.actions$.pipe(
		ofType(featureActions.NavigateToConsole),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		map(([action, state]) => {
			window.location.href = `${state.config.consoleUrl}${action.path}`;
		})
	), {dispatch: false});

	navigateToGateEffect = createEffect(() => this.actions$.pipe(
		ofType(featureActions.NavigateToGate),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		map(([action, state]) => {
			window.open(`${state.config.authenticationServerUrl}${action.path}`, '_blank');
		})
	), {dispatch: false});

	navigateToHelpPageEffect = createEffect(() => this.actions$.pipe(
		ofType(featureActions.NavigateToHelpPage),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		map(([_, state]) => {
			window.open(`${state.config.helpUrl}`, '_blank');
		})
	), {dispatch: false});

	updateLanguage$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UserDataSuccess),
		filter(action => !!action.userData?.language),
		map(action => featureActions.ChangeLanguage({ language: action.userData.language }))
	));
}
