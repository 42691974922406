@if (msol(); as msol) {
	@if (field && field.dirty && field.invalid) {
		<div class="form-feedbacks">
			@for (name of simpleCases; track name) {
				@if (has(name[0]); as rule) {
					<message [rule]="rule">{{ msol[rule] | translate: field.errors[rule] }}</message>
				}
			}
			@if (has('noEmptyValue'); as rule) {
				<message [rule]="rule">{{
					msol[rule] || ('Forms.FieldErrorMessages.NoEmpty' + field.errors[rule]['type'].charAt(0).toUpperCase() + field.errors[rule]['type'].substr(1)) | translate: field.errors[rule]
				}}</message>
			}
			@if (has('minlength'); as rule) {
				<message [rule]="rule">{{
					msol[rule]
						| plural: field.errors['minlength']['requiredLength']
						| translate: { count: field.errors['minlength']['requiredLength'] }
				}}</message>
			}
			@if (has('maxlength'); as rule) {
				<message [rule]="rule">{{
					msol[rule]
						| plural: field.errors['maxlength']['requiredLength']
						| translate : { count: field.errors['maxlength']['requiredLength'] }
				}}</message>
			}
			@if (has('exactLength'); as rule) {
				<message [rule]="rule">{{
					msol[rule]
						| plural: field.errors[rule]['requiredLength']
						| translate : { count: field.errors[rule]['requiredLength'] }
				}}</message>
			}
			@if (has('min'); as rule) {
				<message [rule]="rule">{{
					msol[rule] | translate: { count: field.errors[rule][rule] }
				}}</message>
			}
			@if (has('max'); as rule) {
				<message [rule]="rule">{{
					msol[rule] | translate : { count: field.errors[rule][rule] }
				}}</message>
			}
			@if (has('lowercaseCharacterRule'); as rule) {
				<message [rule]="rule">{{
					msol[rule]
						| plural: field.errors[rule]['required']
						| translate : { count: field.errors[rule]['required'] }
				}}</message>
			}
			@if (has('uppercaseCharacterRule'); as rule) {
				<message [rule]="rule">{{
					msol[rule]
						| plural: field.errors[rule]['required']
						| translate  : { count: field.errors[rule]['required'] }
				}}</message>
			}
			@if (has('specialCharacterRule'); as rule) {
				<message [rule]="rule">{{
					msol[rule]
						| plural: field.errors[rule]['required']
						| translate : { count: field.errors[rule]['required'] }
				}}</message>
			}
			@if (has('digitCharacterRule'); as rule) {
				<message [rule]="rule">{{
					msol[rule]
						| plural: field.errors[rule]['required']
						| translate : { count: field.errors[rule]['required'] }
				}}</message>
			}
			@if (has('olderThanDays'); as rule) {
				<message [rule]="rule">{{
					msol[rule]
						| plural: field.errors[rule]['days']
						| translate : { days: field.errors[rule]['days'] }
				}}</message>
			}
			@if (has('notOlderThanDays'); as rule) {
				<message [rule]="rule">{{
					msol[rule]
						| plural: field.errors[rule]['days']
						| translate : { days: field.errors[rule]['days'] }
				}}</message>
			}
			@if (has('youngerThanDays'); as rule) {
				<message [rule]="rule">{{
					msol[rule]
						| plural: field.errors[rule]['days']
						| translate : { days: field.errors[rule]['days'] }
				}}</message>
			}
			@if (has('noIncompleteIdentityAttachments'); as rule) {
				<message [rule]="rule">{{
					msol[rule]
						| plural: field.errors[rule]['min']
						| translate : { count: field.errors[rule]['min'] }
				}}</message>
			}
			@if (has('strictlyLessThan'); as rule) {
				<message [rule]="rule">{{
					msol[rule] | translate: { value: field.errors[rule]['max'] }
				}}</message>
			}
			@if (has('strictlyGreaterThan'); as rule) {
				<message [rule]="rule">{{
					msol[rule] | translate: { value: field.errors[rule]['min'] }
				}}</message>
			}
		</div>
	}
}
