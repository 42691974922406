import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CriticalStateService {

	private _criticalEvent$ = new Subject<Error>();
	public criticalEvent$ = this._criticalEvent$.asObservable();

	registerCriticalError(error: Error) {
		this._criticalEvent$.next(error);
	}
}
