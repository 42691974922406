<form
    [formGroup]="form"
    (submit)="submitComment($event)"
    autocomplete="off"
    class="comment-form"
>
    <label class="form-label form-comment-label" translate="Comments.New"></label>
    <div class="form-comment-input">
        <textarea
            #contentInput
            type="text"
            formControlName="content"
            autocomplete="off"
            (blur)="onContentChange()"
            (change)="onContentChange()"
            [rows]="rows"
            class="form-control form-control-alt"></textarea>

        <div class="ml-3 d-flex flex-column">
            <button [disabled]="form.invalid" type="submit" class="btn btn-primary" translate="Actions.Add" data-cy="Add"></button>
            @if (showCancel) {
                <button type="button" class="btn btn-default mt-3" translate="Actions.Cancel" data-cy="Cancel" (click)="cancelComment()"></button>
            }
        </div>
    </div>

    @if (! contentField.hasError('required')) {
        <form-control-feedback [field]="contentField"/>
    }
</form>
