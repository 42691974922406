<div class="form-comment">
    <form-comments (submitForm)="submitComment($event)" />

    @if (comments) {
        <div class="form-comment-list">
            @for (comment of comments; track comment) {
                <comment-item-wrapper
                    [comment]="comment"
                    [canReply]="!comment.children?.length"
                    (newComment)="submitComment($event)"
                />
            }
        </div>
    }
</div>
