import { HttpResponse } from '@angular/common/http';

export class ExportResponse {
	constructor(public response: HttpResponse<Blob>) {
	}

	get blob() {
		return URL.createObjectURL ? URL.createObjectURL(this.response.body) : 'blob:https://test';
	}

	get fileName() {
		 const content = this.response.headers.get('content-disposition') || '';
		 let parts = content.match(/filename="([^;]*)";/);
		 if (!parts || parts.length <= 1){
			 parts = content.match(/filename=([^;]*);/);
		 }
		 if (!parts || parts.length <= 1){
			 parts = [null, 'file'];
		 }
		return parts[1];
	}
}

export function exportResponse() {
	return new ExportResponse(new HttpResponse())
}
