import { mapToFullNameString, mapToFullNameInitials, IFullName, mapToFullCapitalizedNameString } from '@aston/foundation';

import { UserAvatarSize } from '../enums';
import { IUserAvatar, IUserItem } from '../models';
import { INotificationItemApi, IUserItemApi } from '../models/api';
import { INotificationItem } from '../models';

import { avatarColorFromString } from '.';


export function mapToUserItemModel(apiModel: IUserItemApi): IUserItem {
	return {
		id: apiModel.id,
		fullName: mapToFullCapitalizedNameString(apiModel.fullName)
	};
}

export function mapToUserAvatar(userFullName: IFullName, size: UserAvatarSize = UserAvatarSize.SM, defaults?: Partial<IUserAvatar>): IUserAvatar {
	let notdefined = false;
	if (!userFullName) {
		userFullName = { firstName: 'not', lastName: 'defined' };
		notdefined = true;
	}

	const fullName = (notdefined && defaults?.fullName) ? defaults.fullName : mapToFullNameString(userFullName);
	const initials = (notdefined && defaults?.initials) ? defaults.initials : mapToFullNameInitials(userFullName);
	const color = (notdefined && defaults?.color) ? defaults.color : avatarColorFromString(fullName);

	return { fullName, initials, size, color, notdefined };
}

export function mapToNotificationItemModel(apiModel: INotificationItemApi): INotificationItem {
	return {
		id: apiModel.id,
		sellerId: apiModel.sellerId,
		relatedEntityName: apiModel.sellerName ? apiModel.sellerName : apiModel.superDebtorName,
		category: apiModel.category,
		status: apiModel.status,
		isRead: apiModel.isRead,
		creationDate: apiModel.creationDate,
		user: mapToUserAvatar(apiModel.user),
		superDebtorId: apiModel.superDebtorId,
		realizedByUser: mapToUserAvatar(apiModel.realizedByUser)
	};
}