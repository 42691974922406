import { OnDestroy, Directive } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class UnsubscriberClass implements OnDestroy {
	destroySubscriptions$: Subject<boolean> = new Subject<boolean>();

	ngOnDestroy() {
		this.destroySubscriptions$.next(true);
		this.destroySubscriptions$.unsubscribe();
	}
}
