import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

export class Loader {
	protected _hasStarted = new BehaviorSubject<boolean>(false);
	protected _count = new BehaviorSubject<number>(0);
	protected _errorMessage = new BehaviorSubject<string | null>(null);

	isLoading$: Observable<boolean> = this._count.pipe(map(count => count > 0));
	error$: Observable<string | null> = this._errorMessage.asObservable();

	neitherLoadingNorError: Observable<boolean> = combineLatest(this.isLoading$, this.error$, (r, l) => !(r || !!l));

	static empty() {
		return new Loader();
	}

	constructor(autoStart = false) {
		if (autoStart) {
			this.loading(autoStart);
		}
	}

	get isLoading(): boolean {
		return this._count.getValue() > 0;
	}

	get hasStarted(): boolean {
		return this._hasStarted.getValue();
	}

	get hasLoaded(): boolean {
		return this.hasStarted && !this.isLoading;
	}

	get errorMessage(): string | null {
		return this._errorMessage.getValue();
	}

	get count(): number {
		return this._count.getValue();
	}

	reset(state = true) {
		this.loading(state);
		this._count.next(0);
	}

	loading(state = true) {
		if (!this.hasStarted && state) {
			this._hasStarted.next(true);
		}

		if (state) {
			this._count.next(this.count + 1);

		} else {
			this._count.next(Math.max(this.count - 1, 0));
		}

		this._errorMessage.next(null);
	}

	success() {
		this.loading(false);
	}

	error(message: string) {
		this._count.next(0);
		this._errorMessage.next(message);
	}
}
