export enum ActionType {
	OPEN,
	CLOSE,
	SELECT,
	CREATE,
	UPDATE,
	DELETE,
	TOGGLE,
	EXPAND,
	SHOW_COMMENTS,
	SORT,
	SEARCH,
	CANCEL,
	SUBMIT,
	VALIDATE,
	BLOCK,
	UNBLOCK,
	PREVIEW,
	DUPLICATE,
	SET_PAGE_NUMBER,
	SET_PAGE_SIZE,
	START,
	STOP,
	COMPLETE,
	SEND,
	DOWNLOAD,
	SAVE
}
