/* eslint-disable @typescript-eslint/no-unused-vars */
import { Observable, OperatorFunction, race, forkJoin, of, throwError, EMPTY } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { Action } from '@ngrx/store/src/models';


// Aggregatable actions pattern
// see https://medium.com/default-to-open/angular-splitter-and-aggregation-patterns-for-ngrx-effects-c6f2908edf26
// and https://www.youtube.com/watch?v=FQ6fzkHvCEY&t=1076s

export class CorrelationParams {
	public correlationId?: string;
	public parentActionType?: string;
}

export function newAggregationId() {
	return `${Date.now()}-${Math.round(Math.random() * 1000000)}`;
}

export type AggregatableAction = Action<any> & { correlationParams?: CorrelationParams };

export type FailActionForAggregation = Action<any> & { error?: Error, correlationParams?: CorrelationParams };

export function aggregate<T extends AggregatableAction,
	TAction1 extends AggregatableAction,
	TFailAction extends FailActionForAggregation>
	(
		action1$: Observable<TAction1>,
		failAction$: Observable<TFailAction>
	);

export function aggregate<T extends AggregatableAction,
	TAction1 extends AggregatableAction,
	TAction2 extends AggregatableAction,
	TFailAction extends FailActionForAggregation>
	(
		action1$: Observable<TAction1>,
		action2$: Observable<TAction2>,
		failAction$: Observable<TFailAction>
	);

export function aggregate<T extends AggregatableAction,
	TAction1 extends AggregatableAction,
	TAction2 extends AggregatableAction,
	TAction3 extends AggregatableAction,
	TFailAction extends FailActionForAggregation>
	(
		action1$: Observable<TAction1>,
		action2$: Observable<TAction2>,
		action3$: Observable<TAction3>,
		failAction$: Observable<TFailAction>
	);

export function aggregate<T extends AggregatableAction,
	TAction1 extends AggregatableAction,
	TAction2 extends AggregatableAction,
	TAction3 extends AggregatableAction,
	TAction4 extends AggregatableAction,
	TFailAction extends FailActionForAggregation>
	(
		action1$: Observable<TAction1>,
		action2$: Observable<TAction2>,
		action3$: Observable<TAction3>,
		action4$: Observable<TAction4>,
		failAction$: Observable<TFailAction>
	);

export function aggregate<T extends AggregatableAction,
	TAction1 extends AggregatableAction,
	TAction2 extends AggregatableAction,
	TAction3 extends AggregatableAction,
	TAction4 extends AggregatableAction,
	TAction5 extends AggregatableAction,
	TFailAction extends FailActionForAggregation>
	(
		action1$: Observable<TAction1>,
		action2$: Observable<TAction2>,
		action3$: Observable<TAction3>,
		action4$: Observable<TAction4>,
		action5$: Observable<TAction5>,
		failAction$: Observable<TFailAction>
	);

export function aggregate<T extends AggregatableAction,
	TAction1 extends AggregatableAction,
	TAction2 extends AggregatableAction,
	TAction3 extends AggregatableAction,
	TAction4 extends AggregatableAction,
	TAction5 extends AggregatableAction,
	TAction6 extends AggregatableAction,
	TFailAction extends FailActionForAggregation>
	(
		action1$: Observable<TAction1>,
		action2$: Observable<TAction2>,
		action3$: Observable<TAction3>,
		action4$: Observable<TAction4>,
		action5$: Observable<TAction5>,
		action6$: Observable<TAction6>,
		failAction$: Observable<TFailAction>
	);

export function aggregate<T extends AggregatableAction,
	TAction1 extends AggregatableAction,
	TAction2 extends AggregatableAction,
	TAction3 extends AggregatableAction,
	TAction4 extends AggregatableAction,
	TAction5 extends AggregatableAction,
	TAction6 extends AggregatableAction,
	TAction7 extends AggregatableAction,
	TFailAction extends FailActionForAggregation>
	(
		action1$: Observable<TAction1>,
		action2$: Observable<TAction2>,
		action3$: Observable<TAction3>,
		action4$: Observable<TAction4>,
		action5$: Observable<TAction5>,
		action6$: Observable<TAction6>,
		action7$: Observable<TAction7>,
		failAction$: Observable<TFailAction>
	);

export function aggregate<T extends AggregatableAction,
	TAction1 extends AggregatableAction,
	TAction2 extends AggregatableAction,
	TAction3 extends AggregatableAction,
	TAction4 extends AggregatableAction,
	TAction5 extends AggregatableAction,
	TAction6 extends AggregatableAction,
	TAction7 extends AggregatableAction,
	TAction8 extends AggregatableAction,
	TFailAction extends FailActionForAggregation>
	(
		action1$: Observable<TAction1>,
		action2$: Observable<TAction2>,
		action3$: Observable<TAction3>,
		action4$: Observable<TAction4>,
		action5$: Observable<TAction5>,
		action6$: Observable<TAction6>,
		action7$: Observable<TAction7>,
		action8$: Observable<TAction8>,
		failAction$: Observable<TFailAction>
	);


export function aggregate<T extends AggregatableAction,
	TAction1 extends AggregatableAction,
	TAction2 extends AggregatableAction,
	TAction3 extends AggregatableAction,
	TAction4 extends AggregatableAction,
	TAction5 extends AggregatableAction,
	TAction6 extends AggregatableAction,
	TAction7 extends AggregatableAction,
	TAction8 extends AggregatableAction,
	TAction9 extends AggregatableAction,
	TFailAction extends FailActionForAggregation>
	(
		action1$: Observable<TAction1>,
		action2$?: Observable<TAction2> | Observable<TFailAction>,
		action3$?: Observable<TAction3> | Observable<TFailAction>,
		action4$?: Observable<TAction4> | Observable<TFailAction>,
		action5$?: Observable<TAction5> | Observable<TFailAction>,
		action6$?: Observable<TAction6> | Observable<TFailAction>,
		action7$?: Observable<TAction7> | Observable<TFailAction>,
		action8$?: Observable<TAction8> | Observable<TFailAction>,
		action9$?: Observable<TAction9> | Observable<TFailAction>,
		failAction$?: Observable<TFailAction>
	): OperatorFunction<T, [TAction1, TAction2, TAction3, TAction4, TAction5, TAction6, TAction7, TAction8, TAction9]> {

	// Handle all implementations
	// Last arg must be the IFailAction
	if (!failAction$) {
		failAction$ = action9$ as Observable<TFailAction>;
		action9$ = undefined;
	}
	if (!failAction$) {
		failAction$ = action8$ as Observable<TFailAction>;
		action8$ = undefined;
	}
	if (!failAction$) {
		failAction$ = action7$ as Observable<TFailAction>;
		action7$ = undefined;
	}
	if (!failAction$) {
		failAction$ = action6$ as Observable<TFailAction>;
		action6$ = undefined;
	}
	if (!failAction$) {
		failAction$ = action5$ as Observable<TFailAction>;
		action5$ = undefined;
	}
	if (!failAction$) {
		failAction$ = action4$ as Observable<TFailAction>;
		action4$ = undefined;
	}
	if (!failAction$) {
		failAction$ = action3$ as Observable<TFailAction>;
		action3$ = undefined;
	}
	if (!failAction$) {
		failAction$ = action2$ as Observable<TFailAction>;
		action2$ = undefined;
	}

	const filterAction = (sourceAction: AggregatableAction, t: AggregatableAction) => !!(t.correlationParams
		&& sourceAction.correlationParams
		&& t.correlationParams.correlationId === sourceAction.correlationParams.correlationId
		&& t.correlationParams.parentActionType === sourceAction.type);

	const getAggregatedActions = (sourceAction: AggregatableAction): Observable<[TAction1, TAction2, TAction3, TAction4, TAction5, TAction6, TAction7, TAction8, TAction9]> => {
		const a1$ = !action1$ ? of(undefined) : (<Observable<TAction1>>action1$).pipe(filter(a => filterAction(sourceAction, a)), first());
		const a2$ = !action2$ ? of(undefined) : (<Observable<TAction2>>action2$).pipe(filter(a => filterAction(sourceAction, a)), first());
		const a3$ = !action3$ ? of(undefined) : (<Observable<TAction3>>action3$).pipe(filter(a => filterAction(sourceAction, a)), first());
		const a4$ = !action4$ ? of(undefined) : (<Observable<TAction4>>action4$).pipe(filter(a => filterAction(sourceAction, a)), first());
		const a5$ = !action5$ ? of(undefined) : (<Observable<TAction5>>action5$).pipe(filter(a => filterAction(sourceAction, a)), first());
		const a6$ = !action6$ ? of(undefined) : (<Observable<TAction6>>action6$).pipe(filter(a => filterAction(sourceAction, a)), first());
		const a7$ = !action7$ ? of(undefined) : (<Observable<TAction7>>action7$).pipe(filter(a => filterAction(sourceAction, a)), first());
		const a8$ = !action8$ ? of(undefined) : (<Observable<TAction8>>action8$).pipe(filter(a => filterAction(sourceAction, a)), first());
		const a9$ = !action9$ ? of(undefined) : (<Observable<TAction9>>action9$).pipe(filter(a => filterAction(sourceAction, a)), first());

		const f$ = (failAction$ || EMPTY).pipe(filter(a => filterAction(sourceAction, a)), first(), switchMap(b => throwError(b.error)));

		return race(<Observable<[TAction1, TAction2, TAction3, TAction4, TAction5, TAction6, TAction7, TAction8, TAction9]>>
			forkJoin([a1$, a2$, a3$, a4$, a5$, a6$, a7$, a8$, a9$]), f$).pipe(
			// TODO: This can greatly help debugging
			// when debbugger fires, you should see an array on observable results
			// tap(_ => { debugger; })
		);
	};

	return (source: Observable<AggregatableAction>) => source.pipe(
		switchMap(sourceAction => getAggregatedActions(sourceAction))
	);
}
