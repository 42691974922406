import { INotificationItem } from 'apps/debtor-portal/src/app/shared-module/models';

import { IStoreEntityStateSlice, createEntitySlice } from '../functions';


export interface INotificationsState {
	isOpened: boolean;
	notifications: IStoreEntityStateSlice<INotificationItem[]>;
	showUnreadOnly: boolean;
}

export const initialState: INotificationsState = {
	isOpened: false,
	notifications: createEntitySlice({ entity: [] }),
	showUnreadOnly: false
};

