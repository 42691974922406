import { Input, Output, EventEmitter, Directive } from '@angular/core';

import { ISort } from '../models';

import { SortedFilteredList } from './sorted-filtered-list.class';
import { ListActionHandlerBase } from './list-handler.class';
import { UnsubscriberClass } from './unsubscriber.class';



@Directive()
export abstract class SortedTableClass<ItemModel, SortPropEnum> extends UnsubscriberClass {
    @Input() list: SortedFilteredList<ItemModel, SortPropEnum>;
    @Input() listHandler: ListActionHandlerBase;
    @Output() sortUpdate: EventEmitter<ISort<SortPropEnum>|ISort<SortPropEnum>[]> = new EventEmitter();

    setSort(...props: SortPropEnum[]) {
        const actions = props.map(prop => {
            const inState = this.list.state.sorts && this.list.state.sorts.find(s => s.prop === prop);
            if (inState) {
                // If the property is already selected then change the direction
                return {prop, isDesc: !inState.isDesc};
            } else {
                // Otherwise change the property and reset the direction
                return {prop, isDesc: true};
            }
        });

        this.sortUpdate.emit(actions);

        if (this.listHandler) {
            this.listHandler.setSort(actions);
        }
    }

    getSortClasses(...props: SortPropEnum[]): any {
        const inState = this.list.state.sorts && this.list.state.sorts.filter(s => props.includes(s.prop));
        const isInState = inState && inState.length && this.list.state.sorts.length === props.length;
        const isDesc = inState && inState.every(sort => sort.isDesc);

        return {
            'is-sorted-asc': isInState && !isDesc,
            'is-sorted-desc': isInState && isDesc
        };
    }
}
