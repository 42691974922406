export enum IFilterOperator {
	Among = 'Among',
	NotAmong = 'NotAmong',
	Equals = 'Equals',
	NotEquals = 'NotEquals',
	GreaterThan = 'GreaterThan',
	LessThan = 'LessThan',
	GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
	LessThanOrEqualTo = 'LessThanOrEqualTo',
	Contains = 'Contains',
	NotContains = 'NotContains',
	StartsWith = 'StartsWith',
	NotStartsWith = 'NotStartsWith',
	Between = 'Between',
	DateBetween = 'DateBetween'
}

export function singleOp(op: IFilterOperator): boolean {
	return false
		|| op === IFilterOperator.Equals
		|| op === IFilterOperator.NotEquals
		|| op === IFilterOperator.Contains
		|| op === IFilterOperator.NotContains
		|| op === IFilterOperator.GreaterThan
		|| op === IFilterOperator.GreaterThanOrEqualTo
		|| op === IFilterOperator.LessThan
		|| op === IFilterOperator.LessThanOrEqualTo;
}
export function multiOp(op: IFilterOperator): boolean {
	return false
		|| op === IFilterOperator.Among
		|| op === IFilterOperator.NotAmong
		|| op === IFilterOperator.DateBetween
		|| op === IFilterOperator.Between;
}
