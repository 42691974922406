import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as RoutesDefinitions from 'apps/debtor-portal/src/app/routes-definitions';
import { PageLoaderService } from '@aston/foundation';

import { AuthenticationService } from '../services';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

	constructor(
		private authenticationService: AuthenticationService,
		private pageLoaderService: PageLoaderService,
		private router: Router
	) { }

	canActivate(): Observable<boolean> | boolean {
		return this.isAuthenticated();
	}

	canLoad(): Observable<boolean> | boolean {
		return this.isAuthenticated();
	}

	private isAuthenticated(): Observable<boolean> | boolean {
		this.pageLoaderService.start();

		return this.authenticationService.getIsAuthenticated().pipe(
			// tap() doesn't seem to trigger the canActivate
			map((isAuthorized: boolean) => {
				this.pageLoaderService.end();

				if (isAuthorized) {
					return true;
				}

				this.router.navigate([RoutesDefinitions.getLoginFullPath()]);
				return false;
			})
		);
	}
}
