import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import * as RoutesDefinitions from 'apps/debtor-portal/src/app/routes-definitions';
import { catchError, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppStoreActions } from 'apps/debtor-portal/src/app/root-store/app-store';
import { AppConstants } from 'apps/debtor-portal/src/app/app.constants';

import { AuthenticationService } from '../services';

@Injectable({providedIn: 'root'})
export class AuthenticationInterceptor implements HttpInterceptor {
	tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

	block401ManagementForNow: boolean;

	constructor(
		private authenticationService: AuthenticationService,
		private router: Router,
		private store: Store) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		// do not send authentication info for assets and connection request
		if (req.url.includes('/assets')) {
			return next.handle(req);
		}

		return next.handle(this.addSignedHashToRequest(req, this.authenticationService.getSSubscriptionKey(), this.authenticationService.getSignedHash())).pipe(
			tap(_ => {
				// Update activity date on each successful api request
				if (!AppConstants.REFRESH_ACTIVITY_EXCLUDED_APIS.some(v => req.url.includes(v))) {
					this.store.dispatch(AppStoreActions.UpdateUserSessionRequest({}));
				}
			}),
			catchError((err: any) => {
					if (err instanceof HttpErrorResponse) {
						switch ((<HttpErrorResponse> err).status) {
							case 400:
								return this.handle400Error(err);
							case 0:
							case 401:
								if (err.error?.SpecificError === 'LoggedInUserDoesNotExists') {
									this.store.dispatch(AppStoreActions.CriticalFailure({error: err, goToConsole: true}));
									return throwError(err);
								}

								if (err.error?.SpecificError === 'UserSignedOut') {
									this.authenticationService.logout();
									return throwError(err);
								}

								// probably a CORS error due to token expiration
								return this.handle401Error(req, next, err);
							case 403:
								// maybe the user clearances changed
								return this.handle403Error(req, next, err);
							default:
								return throwError(err);
						}
					} else {
						return throwError(err);
					}
				}
			));
	}

	addTenantToRequest(req: HttpRequest<any>, tenant: string): HttpRequest<any> {
		return req.clone({
			setHeaders: {
				'X-Aston-FederationId': tenant
			}
		});
	}

	addSignedHashToRequest(req: HttpRequest<any>, subscriptionKey :string, hash: string): HttpRequest<any> {
		return req.clone({
			setHeaders: {
				'Dbpt-Subscription-Key': subscriptionKey,
				'Dbpt-SignedHash': hash,
			}
		});
	}

	handle400Error(error) {
		if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
			// If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
			return this.logoutUser();
		}

		return throwError(error);
	}

	handle401Error(req: HttpRequest<any>, next: HttpHandler, err: any): Observable<never> {
		return throwError(err);
	}

	handle403Error(req: HttpRequest<any>, next: HttpHandler, err: any): Observable<never> {
		return throwError(err);
	}

	logoutUser() {
		this.authenticationService.logout();
		this.router.navigate([RoutesDefinitions.getLoginFullPath()]);
		return throwError('');
	}
}
