import { Component, OnInit, Input, ElementRef, AfterViewInit, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { takeUntil } from 'rxjs/operators';

import { BookmarkerComponent } from '../bookmarker/bookmarker.component';
import { BookmarksStore } from '../../store/bookmarks/bookmarks.store';
import { UnsubscriberClass } from '../../classes/unsubscriber.class';
import { PageService } from '../../services/page.service';
import { IBookmark } from '../../models/bookmark.model';

@Component({
	selector: 'page-header',
	templateUrl: './page-header.component.html',
	standalone: true,
	imports: [BookmarkerComponent, AsyncPipe],
})
export class PageHeaderComponent extends UnsubscriberClass implements OnInit, AfterViewInit {
	protected bookmarksStore = inject(BookmarksStore);
	private pageService = inject(PageService);
	private ref = inject(ElementRef);
	
	@Input() pageTitle: string = undefined;
	@Input() pageTitleKey: string = undefined;
	@Input() isCentered = false;
	@Input() bookmark: IBookmark = undefined;

	ngOnInit() {
		if (this.pageTitle === undefined) {
			this.pageService.pageTitle$
				.pipe(takeUntil(this.destroySubscriptions$))
				.subscribe(title => this.pageTitle = title);
			this.pageService.pageTitleKey$
				.pipe(takeUntil(this.destroySubscriptions$))
				.subscribe(key => this.pageTitleKey = key);
		}
	}

	ngAfterViewInit(): void {
		const el: HTMLElement = this.ref.nativeElement
		const attrs = el.attributes;

		if (!attrs.getNamedItem('data-cy')) el.setAttribute('data-cy', 'PageTitle')
		if (!attrs.getNamedItem('data-testid') && this.pageTitleKey) el.setAttribute('data-testid', `PageTitle.${this.pageTitleKey}`)
	}
}
