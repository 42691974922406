export enum DocumentPreviewType {
    Image,
    Iframe,
    Download
}

export type InlinePreviewAcceptExtensionType = 'jpg' | 'jpeg' | 'png' | 'gif' | 'pdf';

export const ImageFileExts = /\.(apng|png|avif|gif|jpg|jpeg|png|svg|webp|bmp)$/;
export const DocumentsFileExts = /\.(pdf)$/;

export enum FileType {
    Document = 'Document',
    Unknown = 'Unknown',
    Image = 'Image',
}

