import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { EventManager } from '@angular/platform-browser';

interface HotKeysOptions {
	element: any;
	description: string | undefined;
	keys: string;
}

// Inspired by https://netbasal.com/diy-keyboard-shortcuts-in-your-angular-application-4704734547a2
@Injectable({
	providedIn: 'root'
})
export class HotkeysService {
	hotkeys = new Map();
	defaults: Partial<HotKeysOptions> = {
		element: this.document
	};

	constructor(private eventManager: EventManager,
		@Inject(DOCUMENT) private document: Document) {
	}

	// Keys : https://www.w3.org/TR/DOM-Level-3-Events-key/#keys-modifier
	addShortcut(options: Partial<HotKeysOptions>) {
		const merged = { ...this.defaults, ...options };
		const event = `keydown.${merged.keys}`;

		this.hotkeys.set(merged.keys, merged.description);

		return new Observable(observer => {
			const handler = (e) => {
				e.preventDefault();
				observer.next(e);
			};

			const dispose = this.eventManager.addEventListener(merged.element, event, handler);

			return () => {
				dispose();
				this.hotkeys.delete(merged.keys);
			};
		});
	}
}
