
import { UserAvatarSize } from 'apps/debtor-portal/src/app/shared-module/enums';
import { mapToUserAvatar } from 'apps/debtor-portal/src/app/shared-module/functions';
import { IUserAvatar } from 'apps/debtor-portal/src/app/shared-module/models';

import { ICommentItem } from '../models/comment.model';
import { ICommentItemApi, ICommentThreadApi } from '../models/api';

export function mapToCommentModel(apiModel: ICommentItemApi, avatarDefaults?: Partial<IUserAvatar>): ICommentItem {
	return {
		id: apiModel.id,
		date: apiModel.creationDate,
		content: apiModel.content || '',
		user: mapToUserAvatar(
			apiModel.userName ?
				{
					firstName: apiModel.userName.split(' ').slice(0, 1).join(' '),
					lastName: apiModel.userName.split(' ').slice(1).join(' '),
				} :
				null,
			UserAvatarSize.SM,
			avatarDefaults
		),
		parentId: apiModel.parentId,
		children: []
	};
}

export function mapToCommentThread(apiModels: ICommentThreadApi, avatarDefaults?: Partial<IUserAvatar>): ICommentItem {
	const nest = (items, id = null) => items
			.filter(item => item.parentId === id)
			.map(item => ({
				...mapToCommentModel(item, avatarDefaults),
				children: nest(items, item.id)
			}));
	return nest(apiModels)[0]
}
