import { createAction, props } from '@ngrx/store';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { createEntityActions } from '@aston/foundation';
import { ICommentItem } from 'apps/debtor-portal/src/app/comments-module/models';
import { CommentBearing } from 'apps/debtor-portal/src/app/comments-module/enums';

const ActionPrefix = 'Comments';

export const DiscussModalOpen = createAction(`[${ActionPrefix}] Discuss Modal Open`,
	props<{dunningActionId: number, accountingDocumentId: number, bearing: CommentBearing}>());
export const DiscussModalClose = createAction(`[${ActionPrefix}] Discuss Modal Close`);
export const SetDiscussModal = createAction(`[${ActionPrefix}] Set Discuss Modal`,
	props<{ modal: NgbModalRef }>());

export const previewDunningActionDocumentActions = createEntityActions<number>(ActionPrefix, 'Load Dunning Action Document');
export const {
	LoadEntityByIdRequest: PreviewActionDocumentRequest,
	LoadEntitySuccess: PreviewActionDocumentSuccess,
	LoadEntityFailure: PreviewActionDocumentFailure,
} = previewDunningActionDocumentActions;

export const loadDunningActionCommentsEntityActions = createEntityActions<ICommentItem[]>(ActionPrefix, 'List Dunning Action Comments');
export const {
	LoadEntityRequest: LoadDunningActionCommentsRequest,
	LoadEntitySuccess: LoadDunningActionCommentsSuccess,
	LoadEntityFailure: LoadDunningActionCommentsFailure,
} = loadDunningActionCommentsEntityActions;

export const loadAccountingDocumentCommentsEntityActions = createEntityActions<ICommentItem[]>(ActionPrefix, 'List Accounting Document Comments');
export const {
	LoadEntityRequest: LoadAccountingDocumentCommentsRequest,
	LoadEntitySuccess: LoadAccountingDocumentCommentsSuccess,
	LoadEntityFailure: LoadAccountingDocumentCommentsFailure,
} = loadAccountingDocumentCommentsEntityActions;

export const AddDunningActionCommentRequest = createAction(`[${ActionPrefix}] Add Dunning Action Comment Request`, props<{ comment: ICommentItem }>());
export const AddDunningActionCommentSuccess = createAction(`[${ActionPrefix}] Add Dunning Action Comment Success`, props<{ comment: ICommentItem }>());
export const AddDunningActionCommentFailure = createAction(`[${ActionPrefix}] Add Dunning Action Comment Failure`, props<{ error: Error }>());

export const AddAccountingDocumentCommentRequest = createAction(`[${ActionPrefix}] Add Accounting Document Comment Request`, props<{ comment: ICommentItem }>());
export const AddAccountingDocumentCommentSuccess = createAction(`[${ActionPrefix}] Add Accounting Document Comment Success`, props<{ comment: ICommentItem }>());
export const AddAccountingDocumentCommentFailure = createAction(`[${ActionPrefix}] Add Accounting Document Comment Failure`, props<{ error: Error }>());