import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';

import { TelemetryService } from '../services/telemetry.service';


@Injectable({providedIn: 'root'})
export class AppErrorHandler implements ErrorHandler {
	_events = new Subject()
	events$ = this._events.asObservable()

	constructor(private injector: Injector) {
	}

	handleError(error: any): void {
		this._events.next(error)
		const monitoringService = this.injector.get(TelemetryService);
		monitoringService.error(error);
		console.error(error);
	}
}
