
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbCarousel, NgbCarouselModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { catchError, map } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { Loader, IDocumentFile, DocumentPreviewType, LoaderSpinnerComponent } from '@aston/foundation';
import { DocumentFileService } from 'apps/debtor-portal/src/app/shared-module/services/document-file.service';
import { TranslateModule } from '@ngx-translate/core';

import { previewType } from '../../functions';

@Component({
    selector: 'document-files-viewer',
    templateUrl: './document-files-viewer.component.html',
    standalone: true,
    imports: [LoaderSpinnerComponent, NgbCarouselModule, NgbAlertModule, TranslateModule]
})
export class DocumentFilesViewerComponent<T extends IDocumentFile> implements OnInit {
	@ViewChild('ngb-carousel', { static: true }) carousel: NgbCarousel;
	@Input() title: string;
	@Input() item: T;

	files = [];
	loader = new Loader(true);
	previewType = previewType;
	documentPreviewType = DocumentPreviewType;

	constructor(
		public activeModal: NgbActiveModal,
		protected documentFileService: DocumentFileService
	) {
	}

	ngOnInit() {
		if (this.item.url) {
			this.files = [this.item];
			this.loader.success();

		} else {
			// TODO: Call each getLocalFileURL() only the first time a slide is opened
			this.documentFileService.getLocalFileURL(this.item).pipe(
				catchError(e => {
					this.loader.error(e);
					return EMPTY;
				}),
				map(file => [].concat({ ...file, mimeType: 'application/pdf' }))
			).subscribe(files => {
				this.files = files;
				this.loader.success();
			});
		}
	}

}
