import { currentTenantId } from "apps/debtor-portal/src/app/authentication-module/functions";

export class TenantUrl {

    url: URL;
    tenantId: number;

    constructor(url: string) {
        this.tenantId = +currentTenantId();
        this.url = new URL(url, document.location.toString());

        if (this.url.searchParams.has('tenant_id')) {
            this.tenantId = +this.url.searchParams.get('tenant_id');
        }
        const m = this.url.pathname.match(/^\/(\d+)/);
        if (m) {
            this.tenantId = +m[1];
            // eslint-disable-next-line no-useless-escape
            this.url.pathname = this.url.pathname.replace(new RegExp(`^\/${this.tenantId}`), '');
        }
    }

    withPath(path: string) {
        this.url.pathname = path;
        return this;
    }

    toString() {
        const finalUrl = new URL(this.url.toString());
        if (this.tenantId) {
            finalUrl.pathname = `/${this.tenantId}${finalUrl.pathname}`;
        }
        return finalUrl.toString();
    }
}
