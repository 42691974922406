import { default as filenames } from '../assets/i18n/_i18nmappings.json';

export const environmentCommon = {
	appName: 'iTSingle invoice',
	appVersion: '1.0.0',
	translations: {
		/* if the website has translation */
		enabled: true,
		filenames: <string[]><unknown>filenames
	},
	loginConfig: {
		passwordMinLength: 8
	}
};
