export enum ValidationPropertyType {
	PASS = 'PASS', // for unit tests

	NOT_NULL = 'NotNullValidator',
	NOT_EMPTY = 'NotEmptyValidator',
	EMAIL = 'EmailValidator',
	NUMBER = 'NumberValidator',
	NOTNUMBER = 'NotNumberValidator',
	INTEGER = 'IntegerValidator',
	LESS_THAN = 'LessThanValidator',
	LESS_THAN_OR_EQUAL = 'LessThanOrEqualValidator',
	GREATER_THAN = 'GreaterThanValidator',
	GREATER_THAN_OR_EQUAL = 'GreaterThanOrEqualValidator',
	LENGTH = 'LengthValidator',
	EXACT_LENGTH = 'ExactLengthValidator',
	MINIMUM_LENGTH = 'MinimumLengthValidator',
	MAXIMUM_LENGTH = 'MaximumLengthValidator',
	REGULAR_EXPRESSION = 'RegularExpressionValidator',

	AT_LEAST_DIGIT_CHAR = 'AtLeastXDigitValidator',
	AT_LEAST_LOWERCASE_CHAR = 'AtLeastXLowerCaseCharValidator',
	AT_LEAST_UPPERCASE_CHAR = 'AtLeastXUpperCaseCharValidator',
	AT_LEAST_SPECIAL_CHAR = 'AtLeastXSpecialCharValidator',
	BEFORE_NOW = 'BeforeNowValidator',
	BEFORE_FACTOR_NOW = 'BeforeFactorNowValidator',
	AFTER_NOW = 'AfterNowValidator',
	AFTER_FACTOR_NOW = 'AfterFactorNowValidator',
	OLDER_THAN_DAYS = 'OlderThanXDaysValidator',
	NOT_OLDER_THAN_DAYS = 'NotOlderThanXDaysValidator',
	YOUNGER_THAN_DAYS = 'YoungerThanXDaysValidator',
	IBAN = 'IbanValidator',

	ENUM = 'EnumValidator',
	CHILD_VALIDATOR_ADAPTOR = 'ChildValidatorAdaptor',
	PREDICATE = 'PredicateValidator',
	ASYNC_PREDICATE = 'AsyncPredicateValidator',
	NOT_IN_LIST_OF_VALUES_VALIDATOR = "NotInListOfValuesValidator",
}

