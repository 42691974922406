<div class="page-header" [class.page-header-center]="isCentered">
	<h1 class="d-flex align-items-center">
		<ng-content select="[page-header-area=icon]"></ng-content>
		<span data-cy="TitleText" [attr.data-testid]="('PageTitle.' + pageTitleKey) || undefined">{{pageTitle}}</span>
		<ng-content select="[page-header-area=title]"></ng-content>
		<ng-content select="[page-header-area=subtitle]"></ng-content>

		@if (bookmark) {
			<bookmarker	[bookmark]="bookmark" />
		}
	</h1>
	<ng-content select="[page-header-area=button]"></ng-content>
	<ng-content select="[page-header-area=dropdown]"></ng-content>
</div>
