import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

import { createEntitySliceSelectors, createListSliceSelectors } from '../functions';

import { IAccountingState } from './state';
import { FeatureName } from './feature';


export const selectState: MemoizedSelector<object, IAccountingState> = createFeatureSelector<IAccountingState>(FeatureName);

export const {
	selectList: selectAccounting,
	selectError: selectAccountingError,
	selectIsLoading: selectAccountingIsLoading,
	selectIsExporting: selectAccountingIsExporting,
	selectPaging: selectAccountingPaging,
} = createListSliceSelectors(createSelector(selectState, (state) => state.documentsList));

export const {
	selectList: selectInvoices,
	selectError: selectInvoicesError,
	selectIsLoading: selectInvoicesIsLoading,
	selectIsExporting: selectInvoicesIsExporting,
	selectPaging: selectInvoicesPaging,
} = createListSliceSelectors(createSelector(selectState, (state) => state.invoicesList));

export const selectDunningActionId = createSelector(selectState, state => state.currentDunningActionId);

export const {
	selectEntity: selectDebtorKPIs,
	selectIsLoading: selectDebtorKPIsIsLoading,
	selectError: selectDebtorKPIsError,
} = createEntitySliceSelectors(createSelector(selectState, state => state.kpis));

export const {
	selectList: selectDunningActions,
	selectError: selectDunningActionsError,
	selectIsLoading: selectDunningActionsIsLoading,
	selectIsExporting: selectDunningActionsIsExporting,
	selectPaging: selectDunningActionsPaging,
} = createListSliceSelectors(createSelector(selectState, (state) => state.dunningActionsList));

