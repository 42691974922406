

import { AccountingListSortProp, DunningActionListSortProp } from 'apps/debtor-portal/src/app/accounting-module/enums';
import { IAccountingItemList, IDebtorKPIs, IDunningActionList } from 'apps/debtor-portal/src/app/accounting-module/models';
import { AppConstants } from 'apps/debtor-portal/src/app/app.constants';
import { IFilter } from '@aston/foundation';

import { createEntitySlice, createListSlice, IStoreEntityStateSlice, IStoreListStateSlice } from '../functions';

import { FeatureName } from './feature';

export interface IAccountingState {
	kpis: IStoreEntityStateSlice<IDebtorKPIs>;
	documentsList: IStoreListStateSlice<IAccountingItemList, AccountingListSortProp, IFilter>;
	dunningActionsList: IStoreListStateSlice<IDunningActionList, DunningActionListSortProp, IFilter>;
	invoicesList: IStoreListStateSlice<IAccountingItemList, AccountingListSortProp, IFilter>;
	currentDunningActionId: number;
}

export const initialState: IAccountingState = {
	kpis: createEntitySlice(),
	documentsList: createListSlice({ prop: AccountingListSortProp.DueDate, isDesc: false }, AppConstants.LIST_PAGE_SIZE_DEFAULT, [], `${FeatureName}.documentsList`),
	dunningActionsList: createListSlice({ prop: DunningActionListSortProp.RealizationDate, isDesc: false }, AppConstants.LIST_PAGE_SIZE_DEFAULT, [], `${FeatureName}.dunningActionsList`),
	invoicesList: createListSlice({ prop: AccountingListSortProp.DueDate, isDesc: false }, AppConstants.LIST_PAGE_SIZE_DEFAULT, [], `${FeatureName}.invoicesList`),
	currentDunningActionId: null,
};
