import { Injectable } from '@angular/core';
import { AppBaseConfiguration, AppLanguage } from '@aston/foundation';

@Injectable({providedIn: 'root'})
export class AppConfiguration extends AppBaseConfiguration {
	consoleUrl: string;
	helpUrl: string;
	federationApiUrl: string;
	defaultLang: AppLanguage;

	constructor() {
		super();
	}
}
