
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IListState, mapToListModel, getListApiHttpParam, ExportResponse } from '@aston/foundation';
import * as AppStoreSelectors from 'apps/debtor-portal/src/app/root-store/app-store/selectors';

import { AccountingListSortProp } from '../enums';
import { IAccountingItemApi, IAccountingItemListApi, IDunningAccountingItemListApi } from '../models/api';
import { IAccountingItemList, IAccountingListItem, IDebtorKPIs } from '../models';
import { mapToAccountingItemModel } from '../functions';

@Injectable({
	providedIn: 'root'
})
export class AccountingDocumentsService {
	constructor(
		private http: HttpClient,
		private store: Store,
	) { }

	base$: Observable<string> = combineLatest([
		this.store.select(AppStoreSelectors.selectConfig),
		this.store.select(AppStoreSelectors.selectTenant),
	]).pipe(map(([config, tenant]) => `${config.apiUrl}/${tenant.superDebtorId}`))

	getDebtorKPIs(): Observable<IDebtorKPIs> {
		return this.base$.pipe(
			switchMap(apiUrl => this.http.get<IDebtorKPIs>(`${apiUrl}/kpis`))
		);
	}

	getAccountingDocuments(params: IListState<AccountingListSortProp>): Observable<IAccountingItemList> {
		const httpParams = getListApiHttpParam(params);

		return this.base$.pipe(
			switchMap(apiUrl => this.http.get<IAccountingItemListApi>(`${apiUrl}/api/AccountingDocuments`, {params: httpParams}).pipe(
				map(apiModel => mapToListModel<IAccountingItemApi, IAccountingListItem>(apiModel, mapToAccountingItemModel))
			))
		);
	}

	getAccountingDocumentsByDunningActionId(dunningActionId: number, params: IListState<AccountingListSortProp>): Observable<IAccountingItemList> {
		const httpParams = getListApiHttpParam(params);

		return this.base$.pipe(
			switchMap(apiUrl => this.http.get<IDunningAccountingItemListApi>(`${apiUrl}/${dunningActionId}/identity`, {params: httpParams}).pipe(
				map(apiModel => ({
					...mapToListModel<IAccountingItemApi, IAccountingListItem>(apiModel.accountingDocuments, mapToAccountingItemModel),
					meta: {
						totalBalanceInvoicing: apiModel.totalBalanceInvoicing,
						hasNewComment: apiModel.hasNewComment,
					}
				}))
			))
		);
	}

	exportAccountingList(params: IListState<AccountingListSortProp>): Observable<ExportResponse> {
		const httpParams = getListApiHttpParam(params);
		return this.base$.pipe(
			switchMap(apiUrl => this.http.get(`${apiUrl}/api/AccountingDocuments/export?${httpParams}`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}

	getAccountingDocumentsCount(params: IListState<AccountingListSortProp>): Observable<number> {
		const httpParams = getListApiHttpParam(params);

		return this.base$.pipe(
			switchMap(apiUrl => this.http.get<number>(`${apiUrl}/api/AccountingDocuments/count`, {params: httpParams}))
		);
	}
}
