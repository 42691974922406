import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { IUserAvatar } from '../../models';
import { UserAvatarColor, UserAvatarSize } from '../../enums';


@Component({
    selector: 'user-avatar',
    templateUrl: './user-avatar.component.html',
    standalone: true,
    imports: [NgbTooltipModule, TranslateModule]
})
export class UserAvatarComponent {
	@Input() hasFullName = false;
	@Input() isSystem = false;

	@Input() user: IUserAvatar = {
		initials: 'AB',
		fullName: 'Username',
		color: UserAvatarColor.PINK,
		size: UserAvatarSize.MD
	};
}
