import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LowerCasePipe } from '@angular/common';
import { PluralPipe } from '@aston/foundation';

import { ICommentItem } from '../../models';
import { CommentItemComponent } from '../comment-item/comment-item.component';
import { FormCommentsComponent } from '../form-comments/form-comments.component';



@Component({
    selector: 'comment-item-wrapper',
    templateUrl: './comment-item-wrapper.component.html',
    standalone: true,
    imports: [CommentItemComponent, FormCommentsComponent, LowerCasePipe, PluralPipe, TranslateModule]
})
export class CommentItemWrapperComponent extends CommentItemComponent {
	@Input() showChildren = true;
	@Input() replyToCommentId: number;
	@Output() newComment = new EventEmitter<ICommentItem>();

	doShowReply: boolean;

	onNewSubComment(comment: ICommentItem, parentId: number) {
		this.newComment.emit({ ...comment, parentId })
		this.doShowReply = false
	}

	toggleReply(show: boolean): void {
		this.doShowReply = show
	}
}
