import { AppStoreState } from './app-store';
import { AccountingStoreState } from './accounting-store';
import { NotificationsStoreState } from './notifications-store';
import { CommentsStoreState } from './comments-store';
import { initialState as appInitialState } from './app-store/state';

export interface RootState {
	app: AppStoreState.IAppState;
	accounting: AccountingStoreState.IAccountingState;
	notifications: NotificationsStoreState.INotificationsState;
	comments: CommentsStoreState.ICommentsState;
}

export function getStateFromPreviousSession(state: RootState): Partial<RootState> {
	return {
		app: {
			...appInitialState,

			// only put here anonymous store data that
			// should be kept between sessions
			config: state.app.config,
			factorConfig: state.app.factorConfig,
		},
	};
}
