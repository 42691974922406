import { on, createReducer } from '@ngrx/store';

import { withEntityReducer } from '../functions';

import * as featureActions from './actions';
import { initialState, INotificationsState } from './state';



export const notificationsReducer = createReducer<INotificationsState>(
	initialState,

	...withEntityReducer<INotificationsState>('notifications', featureActions.LoadNotificationsEntityActions),

	on(featureActions.ToggleList, state => ({
		...state,
		isOpened: !state.isOpened
	})),

	on(featureActions.OpenList, state => ({
		...state,
		isOpened: true
	})),

	on(featureActions.CloseList, state => ({
		...state,
		isOpened: false
	})),

	on(featureActions.ShowUnreadOnly, state => ({
		...state,
		showUnreadOnly: false
	})),

	on(featureActions.ShowAll, state => ({
		...state,
		showUnreadOnly: true
	})),

	on(featureActions.SelectRequest, state => ({
		...state,
		notifications: {
			...state.notifications,
			isLoading: true,
			error: null
		},
	})),

	on(featureActions.SelectFailure, (state: INotificationsState, action) => ({
		...state,
		error: null,
		notifications: {
			...state.notifications,
			isLoading: false,
			error: action.error
		},
	})),

	on(featureActions.SelectSuccess, (state: INotificationsState, action) => {
		const entity = state.notifications.entity.map(n => n.id === action.id ? { ...n, isRead: true } : n);
		return {
			...state,
			error: null,
			notifications: {
				...state.notifications,
				entity,
				isLoading: false,
				error: null
			}
		};
	})
);
