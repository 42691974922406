
import { Action, createAction, props } from '@ngrx/store';
import { ITenantInfos, IUser, IUserSession } from 'apps/debtor-portal/src/app/authentication-module/models';
import { IConfigurationReferential } from 'apps/debtor-portal/src/app/shared-module/models';
import { ActionType, NotificationType, INotificationOptions, IDocumentFile, ICountryListItem, AppLanguage, IConfirmModalContent, IConfirmModalResult } from '@aston/foundation';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ScriptToLoad } from 'apps/debtor-portal/src/app/shared-module/enums';

import { createEntityActions } from '../functions';
import { CorrelationParams } from '../models/correlated-actions.model';
import { AppConfiguration } from '../../app.configuration';


const ActionPrefix = 'App';

export const CriticalFailure = createAction(`[${ActionPrefix}] Critical Failure`, props<{ error?: Error, bootstrapError?: Error, goToConsole?: boolean }>());

export const NotFound = createAction(`[${ActionPrefix}] Not Found`, props<{ fromError?: Error, message?: string }>());

export const Navigate = createAction(`[${ActionPrefix}] Navigate To`, props<{ to: any[] }>());

export const NavigateBack = createAction(`[${ActionPrefix}] Navigate back`);

export const StartInitializer = createAction(`[${ActionPrefix}] Start Initializer`);
export const FinishInitializer = createAction(`[${ActionPrefix}] Finish Initializer`);
export const AppReady = createAction(`[${ActionPrefix}] App Is Ready`);

export const UpdateAppConfigurationRequest = createAction(`[${ActionPrefix}] UpdateAppConfiguration Request`);

export const UpdateAppConfigurationSuccess = createAction(
	`[${ActionPrefix}] UpdateAppConfiguration Success`,
	props<{ config: AppConfiguration }>()
);

export const Login = createAction(`[${ActionPrefix}] Login`, props<{ userData: IUser }>());

export const Logout = createAction(`[${ActionPrefix}] Logout`);

export const UserDataSuccess = createAction(`[${ActionPrefix}] User Data Success`, props<{ userData: IUser }>());

export const StartUserSession = createAction(
	`[${ActionPrefix}] Start User Session`,
	props<{ userName: string }>()
);

export const UpdateUserSessionRequest = createAction(
	`[${ActionPrefix}] Update User Session Request`,
	props<{ userSession?: Partial<IUserSession> }>()
);

export const UpdateUserSessionSuccess = createAction(
	`[${ActionPrefix}] Update User Session Success`,
	props<{ userSession: IUserSession }>()
);

export const NavigateToLastSavedRoute = createAction(
	`[${ActionPrefix}] Navigate To Last Saved Route`,
	props<{ route: string }>()
);

export const ReloadPage = createAction(`[${ActionPrefix}] Reload Page`);

export const UpdateInactivityTimer = createAction(`[${ActionPrefix}] Update Inactivity Timer`);
export const ShowInactivityWarning = createAction(`[${ActionPrefix}] Show Inactivity Warning`);

export const LoadValidatorsRequest = createAction(`[${ActionPrefix}] Load Validators Request`);

export const LoadValidatorsSuccess = createAction(`[${ActionPrefix}] Load Validators Success`);

export const StartScrollToTop = createAction(
	`[${ActionPrefix}] Scroll to top start`,
	(element?: string) => ({ element })
);

export const FinishScrollToTop = createAction(`[${ActionPrefix}] Scroll to top finish`);

export const OpenConfirmationModal = createAction(
	`[${ActionPrefix}] Modal Open`,
	(
		content: IConfirmModalContent, confirmationAction?: Action | Action[] | ((result: IConfirmModalResult) => Action[]), cancelAction?: Action | Action[],
		correlationParams?: CorrelationParams) => ({
			content,
			confirmationAction,
			cancelAction,
			correlationParams,
			options: <NgbModalOptions>{ size: 'lg', centered: true }
		})
);

export const OpenSmallConfirmationModal = createAction(
	`[${ActionPrefix}] Modal Open Small`,
	(
		content: IConfirmModalContent, confirmationAction?: Action | Action[] | ((result: IConfirmModalResult) => Action[]), cancelAction?: Action | Action[],
		correlationParams?: CorrelationParams) => ({
			content,
			confirmationAction,
			cancelAction,
			correlationParams,
			options: <NgbModalOptions>{ size: 'sm', centered: true }
		})
);

export const CloseConfirmationModal = createAction(
	`[${ActionPrefix}] Modal Closed`,
	props<{ actionType: ActionType, correlationParams?: CorrelationParams }>()
);

export const OpenUrl = createAction(
	`[${ActionPrefix}] Open URL`,
	props<{ url: string, fileName: string, download?: boolean }>()
);

export const PrintUrl = createAction(`[${ActionPrefix}] Print URL`, props<{ url: string }>());

export const DownloadDocument = createAction(
	`[${ActionPrefix}] Download Document`,
	props<{ document: IDocumentFile, name?: string }>()
);

export const DiscardFocus = createAction(`[${ActionPrefix}] Discard Focus`);

export const OpenDocument = createAction(`[${ActionPrefix}] Open Document`, props<{ document: IDocumentFile }>());
export const ViewDocument = createAction(`[${ActionPrefix}] View Document`, props<{ document: IDocumentFile }>());
export const PrintDocument = createAction(`[${ActionPrefix}] Print Document`, props<{ document: IDocumentFile }>());

export const LoadScriptRequest = createAction(
	`[${ActionPrefix}] Load Script Request`,
	props<{ script: ScriptToLoad, correlationParams?: CorrelationParams }>()
);

export const LoadScriptSuccess = createAction(
	`[${ActionPrefix}] Load Script Success`,
	props<{ script: ScriptToLoad, correlationParams?: CorrelationParams }>()
);

export const ErrorLog = createAction(
	`[${ActionPrefix}] Error`,
	(message: string | Error, data?: any) => ({
		message,
		data,
		error: message instanceof Error ? message : new Error(message)
	})
);

export const InfoLog = createAction(
	`[${ActionPrefix}] Info`,
	props<{ message: string, data?: any }>()
);

export const Noop = createAction(`[${ActionPrefix}] Nothing To Do`);

export const ClipboardCopy = createAction(`[${ActionPrefix}] Clipboard Copy`, props<{ text: string }>());

export const ToastReset = createAction(`[${ActionPrefix}] Toast Reset`);
export const ToastError = createToastAction(ActionPrefix, 'Error', NotificationType.ERROR);
export const ToastWarning = createToastAction(ActionPrefix, 'Warning', NotificationType.WARNING);
export const ToastSuccess = createToastAction(ActionPrefix, 'Success', NotificationType.SUCCESS);

export const factorConfigActions = createEntityActions(ActionPrefix, 'Factor Configuration');
export const {
	LoadEntityRequest: LoadFactorConfigurationRequest,
	LoadEntitySuccess: LoadFactorConfigurationSuccess,
	LoadEntityFailure: LoadFactorConfigurationFailure
} = factorConfigActions;

export const tenantActions = createEntityActions<ITenantInfos>(ActionPrefix, 'Current Tenant');
export const {
	LoadEntityRequest: LoadTenantIdentityRequest,
	LoadEntitySuccess: LoadTenantIdentitySuccess,
	LoadEntityFailure: LoadTenantIdentityFailure
} = tenantActions;

export const SetTenantLogo = createAction(`[${ActionPrefix}] Set Tenant Logo`, props<{ path?: string, enabled: boolean }>());

export const referentialActions = createEntityActions<IConfigurationReferential>(ActionPrefix, 'Configuration Referential');
export const {
	LoadEntityRequest: LoadConfigurationReferentialRequest,
	LoadEntitySuccess: LoadConfigurationReferentialSuccess,
	LoadEntityFailure: LoadConfigurationReferentialFailure
} = referentialActions;

export const countriesActions = createEntityActions<ICountryListItem[]>(ActionPrefix, 'Countries');
export const {
	LoadEntityRequest: LoadCountriesRequest,
	LoadEntitySuccess: LoadCountriesSuccess,
	LoadEntityFailure: LoadCountriesFailure
} = countriesActions;

export const userLanguageActions = createEntityActions<{ language: string }>(ActionPrefix, 'User Language');
export const {
	UpdateEntityRequest: SetUserLanguageRequest,
	UpdateEntitySuccess: SetUserLanguageSuccess,
	UpdateEntityFailure: SetUserLanguageFailure
} = userLanguageActions;

export const ChangeLanguage = createAction(`[${ActionPrefix}] Change Language`, props<{ language: AppLanguage }>());

export const NavigateToConsole = createAction(`[${ActionPrefix}] Navigate to console`, props<{ path?: string }>());
export const NavigateToGate = createAction(`[${ActionPrefix}] Navigate to gate`, props<{ path?: string }>());

export const NavigateToHelpPage = createAction(`[${ActionPrefix}] Navigate to help page`);

function createToastAction(actionPrefix: string, name: string, level: NotificationType) {
	return createAction(
		`[${actionPrefix}] Toast ${name}`,
		(message: string, options?: INotificationOptions, messageParams?: any) => ({
			message,
			messageParams,
			options,
			level
		})
	);
}
