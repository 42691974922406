import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';

import { AppLanguage } from '../enums';
import { AppConstants } from '../config';



@Injectable({
	providedIn: 'root'
})
export class LanguageService {

	private language = new BehaviorSubject<AppLanguage>(AppLanguage.FR);
	language$ = this.language.asObservable();
	$language = toSignal(this.language)

	constructor() {
		if (AppConstants.STORE_LANGUAGE) this.restoreStoredLang();
	}

	restoreStoredLang() {
		const storedLang = localStorage.getItem(AppConstants.LOCALSTORAGE_KEYS.APP_LANGUAGE);

		if (!storedLang) {
			localStorage.setItem(AppConstants.LOCALSTORAGE_KEYS.APP_LANGUAGE, this.getCurrentLanguage());
		} else {
			this.setCurrentLanguage(<AppLanguage>storedLang);
		}
	}

	getCurrentLanguage(): AppLanguage {
		return this.language.value;
	}

	setCurrentLanguage(language: AppLanguage) {
		if (language === this.getCurrentLanguage()) { return; }

		this.language.next(language);

		if (AppConstants.STORE_LANGUAGE) {
			localStorage.setItem(AppConstants.LOCALSTORAGE_KEYS.APP_LANGUAGE, language);
		}
	}
}
