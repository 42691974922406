import { Component, OnInit } from '@angular/core';
import { combineToList } from '@aston/foundation';
import { Store } from '@ngrx/store';
import { AccountingStoreActions, AccountingStoreSelectors } from 'apps/debtor-portal/src/app/root-store/accounting-store';
import { ListActionHandler } from 'apps/debtor-portal/src/app/shared-module/handlers';
import { ActivatedRoute } from '@angular/router';

import { AccountingListSortProp } from '../../enums';
import { IAccountingItemList, IAccountingListItem } from '../../models';
import { DunningAccountingPageComponent } from '../dunning-accounting-page/dunning-accounting-page.component';

@Component({
	selector: 'dunning-invoicing-page',
	templateUrl: './dunning-invoicing-page.component.html'
})
export class DunningInvoicingPageComponent extends DunningAccountingPageComponent implements OnInit {

	list$ = combineToList<IAccountingItemList, IAccountingListItem, AccountingListSortProp>(
		this.store.select(AccountingStoreSelectors.selectInvoices),
		this.store.select(AccountingStoreSelectors.selectInvoicesPaging));

	listHandler: ListActionHandler;

	constructor(protected store: Store, protected route: ActivatedRoute) {
		super(store, route);
	}

	ngOnInit() {
		this.store.dispatch(AccountingStoreActions.LoadDebtorKPIsRequest());
		this.store.dispatch(AccountingStoreActions.LoadInvoicingRequest())

		this.listHandler = new ListActionHandler()
			.withPagingDispatcher(this.dispatchPagingAction.bind(this));
	}

	dispatchPagingAction(props) {
		this.store.dispatch(AccountingStoreActions.UpdateInvoicingSettings(props));
	}
}
