import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ValidationService } from '@aston/foundation';
import * as AppStoreSelectors from 'apps/debtor-portal/src/app/root-store/app-store/selectors';

import { MiddleDomainValidators } from '../enums/validators';

@Injectable({
	providedIn: 'root'
})
export class FederationValidationService extends ValidationService {

	constructor(private store: Store) {
		super();
	}

	loadValidationRulesetInternal(): Observable<any> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(_ => of({}) /* this.http.get<IValidationRuleset>(`${config.apiUrl}/api/Validation`) */),
			map(ruleset => this.useValidationRuleset(ruleset, MiddleDomainValidators)),
			catchError(_ => {
				this.setValidationRulesetInError();
				return of({});
			})
		);
	}
}
