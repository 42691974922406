
import { Injectable, forwardRef, inject } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { TelemetryInitializer } from '../classes';
import { AppBaseConfiguration } from '../app-base.configuration';

type Properties = Record<string, any>
type Measurements = Record<string, number>

// The documentation of the appInsight telemetry lib is : https://github.com/Microsoft/ApplicationInsights-JS
// and : https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md

@Injectable({
	providedIn: 'root'
})
export class TelemetryService {

	private router = inject(Router);
	private config = inject(AppBaseConfiguration);

	private appInsights!: ApplicationInsights;
	private routerSubscription!: Subscription;

	constructor() {
		if (!this.config.telemetryKey) {
			console.error('Cannot start TelemetryService without telemetryKey');
			return
		}
		this.appInsights = new ApplicationInsights({
			config: { instrumentationKey: this.config.telemetryKey }
		});
		this.appInsights.loadAppInsights();
		this.appInsights.context.application.ver = this.config.version;

		const telemetryInitializer = inject(forwardRef(() => TelemetryInitializer), { optional: true });
		if (telemetryInitializer) this.appInsights.addTelemetryInitializer(telemetryInitializer.handler.bind(telemetryInitializer));

		this.routerSubscription = this.router.events
			.pipe(filter(event => event instanceof ResolveEnd))
			.subscribe((evt: any) => {
				const event = evt as ResolveEnd;
				if (this.getActivatedComponent(event.state.root)) {
					this.pageView(this.getRouteTemplate(event.state.root), event.urlAfterRedirects);
				}
			});
	}

	private static addGlobalProperties(properties?: Properties): Properties {
		if (!properties) {
			properties = {};
		}

		return properties;
	}

	private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
		if (snapshot.firstChild) {
			return this.getActivatedComponent(snapshot.firstChild);
		}

		return snapshot.component;
	}

	private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
		let path = '';
		if (snapshot.routeConfig) {
			path += snapshot.routeConfig.path;
		}

		if (snapshot.firstChild) {
			return path + this.getRouteTemplate(snapshot.firstChild);
		}

		return path;
	}

	public pageView(
		name: string,
		uri?: string,
		properties?: Properties,
		measurements?: Measurements,
		duration?: number) {

		this.appInsights?.trackPageView({ name, uri, properties: { ...properties, duration }, measurements });
	}

	public event(name: string, properties?: Properties, measurements?: Measurements) {
		this.appInsights?.trackEvent({ name, properties, measurements });
	}

	public error(error: Error, properties?: Properties, measurements?: Measurements) {
		this.appInsights?.trackException({ error, properties, measurements });
	}
}
