import { Pipe, PipeTransform } from '@angular/core';

import { AppConstants } from '../config';


@Pipe({
	name: 'textEllipsis',
	standalone: true
})
export class TextEllipsisPipe implements PipeTransform {

	transform(value: string, size = 20, ellipsis = true): string {
		if (size < 0) { return this.clean(value); }
		return (value && value.length > size) ? this.clean(this.shorten(value, size)) + (ellipsis ? '…' : '') : this.clean(value);
	}

	// remove newline tags
	clean(text: string) {
		return (text || '').replace(new RegExp(AppConstants.COMMENT_NEWLINE_WEIGHT, 'g'), '\n');
	}

	// return a shorted string without breaking neither words nor tags
	shorten(text, max) {
		return text && text.length > max ? text.slice(0, max).split(' ').slice(0, -1).join(' ') : text
	}
}
