import { NotFoundPageComponent } from '@aston/foundation';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import * as RoutesDefinitions from './routes-definitions';
import { MasterLayoutComponent } from './shared-module/layouts/master-layout/master-layout.component';
import { LoginPageComponent } from './authentication-module/pages/login-page/login-page.component';
import { AuthenticationGuard } from './authentication-module/guards';
import { AuthenticationIssuePageComponent } from './authentication-module/pages/authent-issue-page/authentication-issue-page.component';
import { DunningAccountingPageComponent } from './accounting-module/pages/dunning-accounting-page/dunning-accounting-page.component';
import { DunningActionsPageComponent } from './accounting-module/pages/dunning-actions-page/dunning-actions-page.component';
import { DunningInvoicingPageComponent } from './accounting-module/pages/dunning-invoicing-page/dunning-invoicing-page.component';



const routes: Routes = [
	// Redirects to login page
	// {path: '', pathMatch: 'prefix', redirectTo: loginRoutePath},

	// Authentication process pages
	{
		path: `${RoutesDefinitions.LoginRoutePath}/:subscriptionKey/:superDebtorId/:signedHash`,
		component: LoginPageComponent,
		data: {titleTranslationKey: 'Login', layoutName: 'login'}
	},
	{
		path: RoutesDefinitions.AuthenticationIssueRoutePath, component: AuthenticationIssuePageComponent,
		data: {titleTranslationKey: 'Login', layoutName: 'authentication-issue'}
	},

	// Service pages
	{
		path: 'not-found', component: NotFoundPageComponent,
		data: {titleTranslationKey: 'NotFound'},
	},

	// Authenticated pages
	{
		path: '', component: MasterLayoutComponent,
		canActivate: [AuthenticationGuard],
		children: [
			{
				path: RoutesDefinitions.DunningActionsListRoutePath,
				component:  DunningActionsPageComponent,
				data: {
					titleTranslationKey: 'DunningActions',
					layoutName: 'Dunning'
				}
			},
			{
				path: RoutesDefinitions.DunningAccountingRoutePath,
				component:  DunningAccountingPageComponent,
				data: {
					titleTranslationKey: 'Accounting',
					layoutName: 'Accounting'
				}
			},
			{
				path: RoutesDefinitions.DunningInvoicingRoutePath,
				component:  DunningInvoicingPageComponent,
				data: {
					titleTranslationKey: 'Invoicing',
					layoutName: 'Invoicing'
				}
			},
		]
	},

	// Default redirection
	{path: '**', redirectTo: RoutesDefinitions.AuthenticationIssueRoutePath}
];

@NgModule({
	imports: [RouterModule.forRoot(routes,
		{enableTracing: false} // <-- debugging purposes only
	)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
