
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TelemetryService } from '../services';

@Injectable({providedIn: 'root'})
export class VersionInterceptor implements HttpInterceptor {

	appVersion: string;

	constructor(private telemetryService: TelemetryService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		return next
			.handle(request)
			.pipe(
				filter((event: HttpEvent<any>) => event instanceof HttpResponse),
				map((event: HttpResponse<any>) => {
					const version = event.headers.get('X-AFactoring-Version');
					// ignore responses without version header
					if (! version) return event;
					if (! this.appVersion) this.appVersion = version;
					// if the api version changed, reload
					if (this.appVersion !== version) {
						this.telemetryService.event('APP_VERSION_RELOAD', { from: this.appVersion, to: version });
						location.reload();
					}
					return event;
				})
			);
	}
}
