
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IListState, getListApiHttpParam, mapToListModel } from '@aston/foundation';
import * as AppStoreSelectors from 'apps/debtor-portal/src/app/root-store/app-store/selectors';

import { DunningActionListSortProp } from '../enums';
import { IDunningAction, IDunningActionList } from '../models';

@Injectable({
	providedIn: 'root'
})
export class DunningActionsService {
	constructor(
		private http: HttpClient,
		private store: Store,
	) { }

	base$: Observable<string> = combineLatest([
		this.store.select(AppStoreSelectors.selectConfig),
		this.store.select(AppStoreSelectors.selectTenant),
	]).pipe(map(([config, tenant]) => `${config.apiUrl}/${tenant.superDebtorId}`))

	getDunningActions(params: IListState<DunningActionListSortProp>): Observable<IDunningActionList> {
		const httpParams = getListApiHttpParam(params);

		return this.base$.pipe(
			switchMap(apiUrl => this.http.get<IDunningActionList>(`${apiUrl}/DunningActions`, {params: httpParams}).pipe(
				map(apiModel => mapToListModel<IDunningAction, IDunningAction>(apiModel, m => m))
			))
		);
	}
}
