<header id="header">
    <div class="header-content align-items-center">
        <div class="header-nav">
            <div [class.header-logo]="tenant?.customLogoUrl" [class.header-tenant-name]="!tenant?.customLogoUrl">
                @if (tenant?.customLogoUrl) {
                    <img [src]="tenant.customLogoUrl" alt="{{ 'App.Name' | translate }} - {{ 'App.Baseline' | translate }}">
                }
                @else {
                    <h1>{{ tenant.tenantName }}</h1>
                }
            </div>
            <div class="header-name mr-2">
                {{ tenant.superDebtorName }}
            </div>
        </div>
        <div class="header-actions">
            <div class="user-languages" ngbDropdown placement="bottom-right">
                <button class="btn btn-secondary" ngbDropdownToggle data-cy="DropdownToggle">
                    {{ currentLanguage | language : true }}
                </button>
                <div class="dropdown-menu" ngbDropdownMenu>
                    <button data-cy="UseLanguageFR" (click)="setLanguage(AppLanguage.FR)" class="dropdown-item">FR</button>
                    <button data-cy="UseLanguageEN" (click)="setLanguage(AppLanguage.EN)" class="dropdown-item">EN</button>
                </div>
            </div>
        </div>
    </div>
    @if (isAuthenticated) {
        <nav class="mt-4 header-menu">
            <ul>
                @if (currentActionId) {
                    <li><a [routerLink]="accountingListRoutePath" routerLinkActive="active" translate="PageTitles.Accounting" data-cy="MenuItemAccounting"></a></li>
                }

                <li><a [routerLink]="dunningActionsListRoutePath" routerLinkActive="active" translate="PageTitles.DunningActions" data-cy="MenuItemDunningActions"></a></li>

                @if (tenant.isManualInvoiceEnabled) {
                    <li><a [routerLink]="invoicingListRoutePath" routerLinkActive="active" translate="PageTitles.Invoicing" data-cy="MenuItemInvoicing"></a></li>
                }
            </ul>
        </nav>
    }
</header>
