import { BsDatepickerConfig, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import {
	compareAsc,
	endOfDay as endOfDayFn,
	startOfDay as startOfDayFn,
	startOfMonth as startOfMonthFn,
	endOfMonth as endOfMonthFn,
	addDays as addDaysFn,
	subDays as subDaysFn,
	addWeeks as addWeeksFn,
	subWeeks as subWeeksFn,
	addMonths as addMonthsFn,
	subMonths as subMonthsFn,
	addYears as addYearsFn,
	subYears as subYearsFn,
	isAfter as isAfterFn,
} from 'date-fns';

import { AppConstants } from '../config';
import { ValidationPropertyType } from '../enums';
import { DateOnly, IValidationPropertyValidator } from '../models';


export function getDatepickerConfig(): BsDatepickerConfig {
	return Object.assign(new BsDatepickerConfig(), {
		containerClass: AppConstants.DATEPICKER_THEME,
		returnFocusToInput: true,
		showWeekNumbers: false
	});
}

export function getDaterangepickerConfig(): BsDaterangepickerConfig {
	return Object.assign(new BsDaterangepickerConfig(), {
		containerClass: AppConstants.DATEPICKER_THEME,
		returnFocusToInput: true,
		showWeekNumbers: false
	});
}

export function nowPlusDays(days: number) {
	return addDays(new Date, days);
}

export function convertStringToDate(dateStr: string): Date {
	const date = new Date(dateStr);
	const utc = -date.getTimezoneOffset() / 60;
	date.setHours(date.getHours() + utc);
	return date;
}

export function daysRange(dateStart: Date, dateEnd: Date = new Date()): Date[] {
	const range = [];
	let ticker;
	for (ticker = new Date(dateStart.getTime()); ticker <= dateEnd; ticker.setDate(ticker.getDate() + 1)) {
		range.push(new Date(ticker));
	}
	return range;
}

export function getDatesToCompare(value: Date, doNotCheckHours = false): { dateToTest: Date; now: Date; } {
	let now = new Date();
	let dateToTest = new Date(value);

	// Remove time, just keep the date
	if (doNotCheckHours) {
		now = startOfDay(now);
		dateToTest = startOfDay(dateToTest);
	}

	return {
		dateToTest,
		now
	};
}

// Get a JS Date from a DateOnly string sent by the API.
export function dateOnlyToDate(dateOnlyString?: DateOnly): Date | null {
	return !dateOnlyString ? null : new Date(dateOnlyString);
}

export function isValidDate(d): boolean {
	return d instanceof Date && !isNaN(<any>d);
}

export function getMinDate(validators: IValidationPropertyValidator[]): Date {
	for (const validator of validators) {
		switch (validator.name) {
			case ValidationPropertyType.OLDER_THAN_DAYS:
				return addDays(startOfDay(new Date), validator.additionalData.numberOfDays);
			case ValidationPropertyType.AFTER_NOW:
				return addDays(startOfDay(new Date), 1);
			case ValidationPropertyType.AFTER_FACTOR_NOW:
				return startOfDay(new Date);
			case ValidationPropertyType.NOT_OLDER_THAN_DAYS:
				return subDays(startOfDay(new Date), validator.additionalData.numberOfDays);
		}
	}

	return null;
}

export function getMaxDate(validators: IValidationPropertyValidator[]): Date {
	for (const validator of validators) {
		switch (validator.name) {
			case ValidationPropertyType.BEFORE_NOW:
			case ValidationPropertyType.BEFORE_FACTOR_NOW:
				return startOfDay(new Date)
			case ValidationPropertyType.YOUNGER_THAN_DAYS:
				return addDays(startOfDay(new Date), validator.additionalData.numberOfDays);
		}
	}

	return null;
}

export function fromDateExpression(expression: string): Date {
	const d = startOfDay(new Date);

	const TOKENS = AppConstants.DATE_EXPRESSION_TOKEN_TYPES;
	const validator = new RegExp(`^(${TOKENS.DAY}|${TOKENS.WEEK}|${TOKENS.MONTH})(\\+|\\-)(\\d+)$`);
	const parts = expression.toUpperCase().trim().match(validator);

	if (parts?.length !== 4) {
		console.warn(`"${expression}" is not a valid ({D,W,M}±X) relative date expression`)
		return d;
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [all, token, operator, number] = parts;
	const factor = operator === '-' ? -1 : 1

	if (token === TOKENS.DAY) return addDays(d, +number * factor);
	if (token === TOKENS.WEEK) return addWeeks(d, +number * factor);
	if (token === TOKENS.MONTH) return addMonths(d, +number * factor);
	return d;
}

export function isBeforeDay<DateType extends Date>(
	dateLeft: string | number | DateType,
	dateRight: string | number | DateType
): boolean {
	return compareAsc(startOfDayFn(toDate(dateLeft)), startOfDayFn(toDate(dateRight))) === -1
}

export function isAfterDay<DateType extends Date>(
	dateLeft: string | number | DateType,
	dateRight: string | number | DateType
): boolean {
	return compareAsc(startOfDayFn(toDate(dateLeft)), startOfDayFn(toDate(dateRight))) === 1
}

export function isAfter<DateType extends Date>(
	dateLeft: string | number | DateType,
	dateRight: string | number | DateType
): boolean {
	return isAfterFn(toDate(dateLeft), toDate(dateRight))
}

export function duration<DateType extends Date>(
	dateLeft: string | number | DateType,
	dateRight: string | number | DateType
): number {
	const from = toDate(dateLeft);
	const dest = toDate(dateRight);
	return dest.getTime() - from.getTime();
}

export function durationInDays<DateType extends Date>(
	dateLeft: string | number | DateType,
	dateRight: string | number | DateType
): number {
	return Math.ceil(duration(dateLeft, dateRight) / 86400000);
}

export const toDate = (d: Date | string | number) => d instanceof Date ? d : new Date(d);
export const startOfDay = (d: Date | string) => startOfDayFn(toDate(d));
export const endOfDay = (d: Date | string) => endOfDayFn(toDate(d));
export const startOfMonth = (d: Date | string) => startOfMonthFn(toDate(d));
export const endOfMonth = (d: Date | string) => endOfMonthFn(toDate(d));

export const addDays = (d: Date | string, days: number) => addDaysFn(toDate(d), days);
export const subDays = (d: Date | string, days: number) => subDaysFn(toDate(d), days);
export const addWeeks = (d: Date | string, weeks: number) => addWeeksFn(toDate(d), weeks);
export const subWeeks = (d: Date | string, weeks: number) => subWeeksFn(toDate(d), weeks);
export const addMonths = (d: Date | string, days: number) => addMonthsFn(toDate(d), days);
export const subMonths = (d: Date | string, days: number) => subMonthsFn(toDate(d), days);
export const addYears = (d: Date | string, days: number) => addYearsFn(toDate(d), days);
export const subYears = (d: Date | string, days: number) => subYearsFn(toDate(d), days);
