import { Creator, createAction, ActionCreator, props } from '@ngrx/store';
import { Action, FunctionWithParametersType } from '@ngrx/store/src/models';

// https://github.com/vincent/ng-stator/blob/master/projects/ng-stator/src/lib/shared/action-creators.ts

export interface IsTypedAction<K> { is: K; }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function createTypedAction<K extends string, T extends string, P extends object>(
	itype: K,
	type: T,
): ActionCreator<T, () => Action<T> & IsTypedAction<K>>;

export function createTypedAction<K extends string, T extends string, P extends object>(
	itype: K,
	type: T,
	config?: { _as: 'props'; _p: P }
): ActionCreator<T, (props: P) => P & Action<T> & IsTypedAction<K>> & Action<T>;

export function createTypedAction<
	K extends string,
	T extends string,
	P extends any[],
	R extends object
>(
	itype: K,
	type: T,
	creator?: Creator<P, R>
): FunctionWithParametersType<P, R & Action<T>> & IsTypedAction<K> & Action<T>;

export function createTypedAction<K extends string, T extends string, C extends Creator>(
	itype: K,
	type: T,
	config?: C
): Creator {
	// An empty typed action must have some properties
	// otherwise NgrRX would take is as 'empty'
	if (typeof config === 'function') {
		return defineInternalType(itype, createAction(type, (...props) => ({ itype, ...config(...props) })));
	}
	return defineInternalType(itype, createAction(type, props => ({ itype, ...props })));
}

function defineInternalType(type: string, creator: Creator): Creator {
	return Object.defineProperty(creator, 'is', {
		value: type,
		writable: false,
	});
}


export function createErrorAction(name: string) {
	return createAction(
		`[${name}] Failure`,
		props<{ error: Error }>()
	);
}

export function createDebugAction(name: string) {
	return createAction(
		`[${name}] Debug`,
		props<{ message?: any, origin?: any }>()
	);
}

export function createActionWithId(name: string) {
	return createAction(name, (id: number) => ( { id } ));
}

export function createActionWithEntity<T>(name: string) {
	return createAction(name, props<{ entity: T }>());
}

// export function createActionWithSingleProps<T>(name: string) {
// 	return createAction(name, props<IActionParam<T>>());
// }

export function createActionWithSingleProps<T>(name: string) {
	return createAction(name, props<{ param: T }>());
}

export interface IActionParam<T> {
	param: T;
}

