import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as AppStoreSelectors from 'apps/debtor-portal/src/app/root-store/app-store/selectors';

import { mapToCommentThread } from '../functions';
import { ICommentThreadApi } from '../models/api';
import { ICommentItem } from '../models';

@Injectable({
	providedIn: 'root'
})
export class CommentsService {
	constructor(
		private http: HttpClient,
		private store: Store,
	) { }

	base$: Observable<string> = combineLatest([
		this.store.select(AppStoreSelectors.selectConfig),
		this.store.select(AppStoreSelectors.selectTenant),
	]).pipe(map(([config, tenant]) => `${config.apiUrl}/${tenant.superDebtorId}`))

	currentUser$ = this.store.select(AppStoreSelectors.selectTenant).pipe(
		map(tenant => ({ fullName: tenant.superDebtorName, initials: tenant.superDebtorName.slice(0, 2) })))

	getDunningActionComments(dunningActionId: number): Observable<ICommentItem[]> {
		return combineLatest([ this.base$, this.currentUser$ ]).pipe(
			switchMap(([apiUrl, tenant]) => this.http.get<ICommentThreadApi[]>(`${apiUrl}/${dunningActionId}/actioncomment`).pipe(
				map(apiModels => apiModels.map(m => mapToCommentThread(m, tenant))),
			)))
	}

	sendDunningActionComment(dunningActionId: number, comment: ICommentItem): Observable<any> {
		return this.base$.pipe(
			switchMap(apiUrl => this.http.post(`${apiUrl}/${dunningActionId}/actioncomment`,  comment))
		)
	}

	getAccountingDocumentComments(accountingDocumentId: number): Observable<ICommentItem[]> {
		return combineLatest([ this.base$, this.currentUser$ ]).pipe(
			switchMap(([apiUrl, tenant]) => this.http.get<ICommentThreadApi[]>(`${apiUrl}/${accountingDocumentId}/accountingDocumentComment`).pipe(
				map(apiModels => apiModels.map(m => mapToCommentThread(m, tenant))),
			))
		)
	}

	sendAccountingDocumentComment(accountingDocumentId: number, comment: ICommentItem): Observable<any> {
		return this.base$.pipe(
			switchMap(apiUrl => this.http.post(`${apiUrl}/${accountingDocumentId}/accountingDocumentComment`,  comment))
		)
	}
}
