import { catchError, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import * as AppStoreActions from 'apps/debtor-portal/src/app/root-store/app-store/actions';
import { AppLanguage, mapToFullNameString } from '@aston/foundation';
import { HttpClient } from '@angular/common/http';
import { AppStoreSelectors } from 'apps/debtor-portal/src/app/root-store/app-store';

import { IAuthParams, IUser } from '../models';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	private authParams: IAuthParams;
	private auth$ = new BehaviorSubject<boolean>(null);

	constructor(
		private http: HttpClient,
		private store: Store,
	) {}

	getIsAuthenticated(): Observable<boolean> {
		return this.auth$.asObservable()
	}

	getAccessToken(): Observable<string> {
		return of('')
	}

	login(params: IAuthParams): Observable<boolean> {
		this.authParams = params;
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<any>(`${config.apiUrl}/${params.superDebtorId}/tenant`, {})),
			map(tenantInfos => {
				const userData: IUser = {
					fullName: {
						firstName: 'A simple',
						lastName: 'User',
					},
					email: 'test@example.com',
					language: navigator.language.match(/fr/i) ? AppLanguage.FR : AppLanguage.EN,
					userFilters: {},
					userPrefs: {},
					isSupport: false,
					isObserver: true,
				}

				// TODO: This triggers a Login action on each loggedin pageview
				this.store.dispatch(AppStoreActions.Login({userData}));
				this.store.dispatch(AppStoreActions.LoadTenantIdentitySuccess({ entity: { ...tenantInfos, superDebtorId: params.superDebtorId } }));
				this.store.dispatch(AppStoreActions.StartUserSession({userName: mapToFullNameString(userData.fullName)}));

				this.auth$.next(true);

				return true;
			}),
			catchError(() => of(false))
		);
	}

	logout(): void {
        //
	}

	getSignedHash(): string {
		return encodeURIComponent(this.authParams.signedHash);
	}

	getSSubscriptionKey(): string {
		return this.authParams.subscriptionKey;
	}

	getCurrentSuperDebtorId(): number {
		return this.authParams.superDebtorId;
	}

	isLoggedIn(): boolean {
		return !!this.auth$.getValue();
	}

	getTenantId(): string {
		return null // mostly for compatibility
	}
}
