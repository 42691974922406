import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';

import { formatDate } from '../functions';

@Pipe({
	name: 'dateFormat',
	pure: false,
	standalone: true
})
export class DateFormatPipe extends TranslatePipe implements PipeTransform {
	constructor(private translateService: TranslateService, changeDetectorRef: ChangeDetectorRef) {
		super(translateService, changeDetectorRef);
	}

	transform(value, formatKey = 'Base'): string {
		if (!value) return '';
		const format = this.translateService.instant('Formats.Date.' + formatKey);
		if (!format) {
			console.log(`[date-format] Invalid format "${formatKey}"`);
			return '';
		}
		return formatDate(value as Date, format);
	}
}
