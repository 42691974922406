<div class="modal-header">
  <h2 class="modal-title" [translate]="title">
  </h2>
  <button type="button" class="modal-button-close fal fa-times" (click)="onClose()"></button>
</div>
<div class="modal-body">
  <div class="content-block" [class.block-loader]="loader.isLoading$ | async">
    <loader-spinner [model]="loader"/>
    <comments-block [comments]="comments$ | async" (submitNewComment)="onSubmit($event)"/>
  </div>
</div>
