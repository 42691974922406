@if (item(); as pin) {
	<button 
		type="button"
		class="btn btn-thumbtack fas pl-1 pr-3"
		[class.fa-thumbtack-slash]="pin.marked"
		[class.fa-thumbtack]="!pin.marked"
		[disabled]="pin.disabled"
		[ngbTooltip]="pin.disabled ? ('Bookmarks.LimitReached' | translate: { max }) : undefined"
		placement="top-left"
		(click)="store.toggle(pin)"
	></button>
}
