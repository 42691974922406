export enum AccountingDocumentTypeCode {
	Invoice = 'Invoice',
	Cashing = 'Cashing',
	Misc = 'Misc',
	Asset = 'Asset'
}

export enum AccountingDocumentPaymentTypeCode {
	Chq = 'Chq',
	Trt = 'Trt',
	Vir = 'Vir',
	Vire = 'Vire',
	Prel = 'Prel',

	// Acc only
	Cb = 'Cb',
	Esp = 'Esp',
	Other = 'Other'
}

export enum AccountingDocumentMiscOperationType {
	Odajh = 'MiscH',
	Odajb = 'MiscB'
}
