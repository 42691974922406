import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { createEntitySliceSelectors } from '@aston/foundation';

import { ICommentsState } from './state';
import { FeatureName } from './feature';


export const selectState: MemoizedSelector<object, ICommentsState> = createFeatureSelector<ICommentsState>(FeatureName);

export const {
	selectEntity: selectCommentsList,
	selectError: selectCommentsError,
	selectIsLoading: selectCommentsIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.comments));

export const selectDunningActionId = createSelector(selectState, state => state.dunningActionId);

export const selectAccountingDocumentId = createSelector(selectState, state => state.accountingDocumentId);

export const {
	selectSlice: selectActionDocument,
	selectError: selectActionDocumentError,
	selectIsLoading: selectActionDocumentIsLoading,
} = createEntitySliceSelectors(createSelector(selectState, state => state.previewDocumentId));
