
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap, withLatestFrom, debounceTime } from 'rxjs/operators';
import { DunningActionsService } from 'apps/debtor-portal/src/app/accounting-module/services';

import { CommentsStoreActions } from '../../comments-store';
import { catchWithAppError as catchError } from '../../app-store';
import * as featureSelectors from '../selectors';
import * as featureActions from '../actions';


@Injectable({providedIn: 'root'})
export class AccountingStoreDunningActionsEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		private dunningActionsService: DunningActionsService,
	) { }

	loadDunningActionsEffect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadDunningActionsRequest),
		withLatestFrom(this.store.select(featureSelectors.selectState)),
		debounceTime(500),
		switchMap(([_, state]) => {
			const { paging } = state.dunningActionsList;
			return this.dunningActionsService.getDunningActions({ ...paging }).pipe(
				map(list => featureActions.LoadDunningActionsSuccess({ list })),
				catchError(error => of(featureActions.LoadDunningActionsFailure({ error })))
			);
		})
	));

	updateDASettings$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateDunningActionsSettings, CommentsStoreActions.DiscussModalClose,),
		map(_ => featureActions.LoadDunningActionsRequest())
	));
}
