<div class="card-item comment-item">
    <header class="card-item-head comment-item-head">
        <div>
            <user-avatar [user]="comment.user" [hasFullName]="true"/>
        </div>
        <div class="card-item-date">{{ comment.date | dateFormat:'WithHours' }}</div>
    </header>
    <div class="row">
        <div class="col-10 card-item-body text-left">
            @if (truncateSize) {
                <div>
                    <p>
                        {{ (comment.content ? inlineContent : comment.contentToTranslate | translate) | textEllipsis:(isCollapsed ? truncateSize : -1):false }}
                        @if (isTruncateNeeded && isCollapsed) {
                            <button class="btn-a" type="button" (click)="onToggle()" data-cy="Expand">…</button>
                        }
                    </p>
                </div>
            } @else {
                {{ comment.content ? comment.content : comment.contentToTranslate | translate }}
            }
        </div>
        @if (comment.id && canReply) {
            <div class="col-2 align-self-end text-right card-item-reply">
                <button class="btn btn-primary" translate="Actions.Reply" data-cy="Reply" (click)="showReply.emit()"></button>
            </div>
        }
    </div>
</div>
