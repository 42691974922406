import { Component, Output, EventEmitter, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CurrencyCodeType, SortedTableClass } from '@aston/foundation';
import { ISort } from '@aston/foundation';

import { AccountingListSortProp } from '../../enums';
import { IAccountingListItem } from '../../models';

@Component({
	selector: 'accounting-list-table',
	templateUrl: './accounting-list-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountingListTableComponent extends SortedTableClass<IAccountingListItem, AccountingListSortProp> {
	@Input() showId = true;
	@Input() showDownload = true;
	@Input() showStatus = false;
	@Input() isDetailsOpenedInNewTab = false;
	@Input() canSelect = false;
	@Input() isInBulkEditLimit = false;
	@Input() allSelected = false;
	@Input() isSortable = true;
	@Input() selectedIds: number[] = [];
	@Input() ofAggregatedCustomer: boolean;
	@Input() useInvoicingCurrency: boolean;
	@Input() fixedHeight: boolean;
	@Input() hideComment: boolean;
	@Input() currency: CurrencyCodeType;

	@Output() toggleLetteredDocument: EventEmitter<IAccountingListItem> = new EventEmitter();
	@Output() letteredDocumentSortUpdate: EventEmitter<{ id: number, letteringCode: string, sort: ISort<AccountingListSortProp> }> = new EventEmitter();

	@Output() selectAllToggle: EventEmitter<boolean> = new EventEmitter();
	@Output() selectItemToggle: EventEmitter<{ isSelected: boolean; id: number; }> = new EventEmitter();

	@Input() showCompleted: boolean;
	@Input() hideCheckbox?: boolean = true;

	sortCols = AccountingListSortProp;

	constructor(private cd: ChangeDetectorRef) {
		super();
	}

	onToggleSelectAll(isSelected: boolean) {
		this.selectAllToggle.emit(isSelected);
	}
}
