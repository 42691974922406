
import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyCodeType, TextEllipsisPipe } from '@aston/foundation';
import * as RoutesDefinitions from 'apps/debtor-portal/src/app/routes-definitions';
import { CommentsStoreActions, CommentsStoreSelectors } from 'apps/debtor-portal/src/app/root-store/comments-store';
import { CommentBearing } from 'apps/debtor-portal/src/app/comments-module/enums';

import { IDunningAction } from '../../models';

@Component({
	// Must be an attribute based selector to prevent <table> layout break
	selector: '[actions-list-table-row]',
	templateUrl: './actions-list-table-row.component.html',
	providers: [TextEllipsisPipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DunningActionListTableRowComponent {
	@Input() item: IDunningAction;
	@Input() currency: CurrencyCodeType;

	previewLoading$ = this.store.select(CommentsStoreSelectors.selectActionDocument).pipe(
		map(slice => slice.isLoading && slice.entity === this.item?.dunningActionId))

	constructor(
		protected cd: ChangeDetectorRef,
		protected store: Store,
		public textEllipsisPipe: TextEllipsisPipe,
		protected translateService: TranslateService,
	) { }

	getAccountingDetailPath(id: number): string {
		return RoutesDefinitions.getDunningAccountingFullPath(id);
	}

	onDiscuss() {
		const dunningActionId = this.item.dunningActionId;
		const bearing = CommentBearing.DunningAction;

		this.store.dispatch(CommentsStoreActions.DiscussModalOpen({ dunningActionId, accountingDocumentId: null, bearing }))
	}

	onPreview() {
		this.store.dispatch(CommentsStoreActions.PreviewActionDocumentRequest(this.item.dunningActionId))
	}
}
