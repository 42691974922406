import { createReducer, on } from '@ngrx/store';
import { withEntityReducer } from '@aston/foundation';

import { ICommentsState, initialState } from './state';
import * as featureActions from './actions';


export const commentsReducer = createReducer<ICommentsState>(
	initialState,

	...withEntityReducer<ICommentsState>('comments', featureActions.loadAccountingDocumentCommentsEntityActions),
	...withEntityReducer<ICommentsState>('comments', featureActions.loadDunningActionCommentsEntityActions),
	...withEntityReducer<ICommentsState>('previewDocumentId', featureActions.previewDunningActionDocumentActions),

	on(featureActions.SetDiscussModal, (state, action) => ({
		...state,
		discussFormModalRef: action.modal
	})),

	on(featureActions.DiscussModalOpen, (state, action) => ({
		...state,
		dunningActionId: action.dunningActionId,
		accountingDocumentId: action.accountingDocumentId,
	})),

	on(featureActions.PreviewActionDocumentRequest, (state, action) => ({
		...state,
		previewDocumentId: {
			...state.previewDocumentId,
			entity: action.id,
			isLoading: true,
		}
	})),
);
