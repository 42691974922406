
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { NotificationService } from './notification.service';

export interface IPageLoader {
	isLoading: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class PageLoaderService {

	private loader = new BehaviorSubject<IPageLoader>({ isLoading: false });
	loader$ = this.loader.asObservable();

	constructor(private notificationService: NotificationService) {
	}

	getLoader(): IPageLoader {
		return this.loader.value;
	}

	isLoading(): boolean {
		return this.getLoader().isLoading;
	}

	start() {
		this.loader.next({ isLoading: true });
	}

	end() {
		this.loader.next({ isLoading: false });
	}

	// Stops loading end sends a translatable message key as error notification
	endError(messageTranslateKey: string, messageAutoClose = true) {
		this.end();

		if (messageTranslateKey) {
			this.notificationService.errorMessage(messageTranslateKey, messageAutoClose);
		}
	}
	
}
