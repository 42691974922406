import { AppConstants as AppConstantsBase, IFileUploaderConfig } from '@aston/foundation';

import { ScriptToLoad } from './shared-module/enums';


export class AppConstants extends AppConstantsBase {
	static EPSILON = 0.0001;

	static CRITICAL_ERROR_RELOAD_TIMEOUT = 0;

	static TOP_SEARCH_MAX_RESULTS = 3;

	static LOCALSTORAGE_KEYS = {
		...AppConstantsBase.LOCALSTORAGE_KEYS,
		REDIRECT_URL: 'redirect_url',
		POST_LOGIN_ROUTE: 'post_login_route',
		AUTHENTICATION_TRIES: 'authentication_tries'
	};

	static STORE_LANGUAGE = false;

	static ANIMATED_SCROLL_ELEMENT_DEFAULT = '#main';

	static COMPANY_NAME_COL_MAX_CHAR = 30;

	static DEFAULT_COMMENT_TRUNCATE = 100;

	static SUPER_DEBTOR_SEARCH_TERM_MIN_CHARS = 3;

	static IMAGE_PREVIEW_MATCH = /^image/;
	static IFRAME_PREVIEW_MATCH = /\/pdf/;

	static NOTIFICATIONS_REFRESH_DELAY = 10 * AppConstantsBase.MILLISECONDS;

	// Maximum time between 2 api calls
	static MAX_INACTIVITY_TIME = AppConstantsBase.HOUR;
	// Inactivity warning countdown
	static INACTIVITY_WARNING_COUNTDOWN_BEFORE_LOGOUT = AppConstantsBase.MINUTE;

	static REFRESH_ACTIVITY_EXCLUDED_APIS = ['hubs/main', 'well-known/jwks'];

	static SCRIPTS_SRC: { [name: string]: string } = {
		[ScriptToLoad.CKEDITOR]: '/assets/vendor/ckeditor/ckeditor.js'
	};

	static FILE_UPLOADER_CONFIGS: { [key: string]: IFileUploaderConfig } = {
		DEFAULT: {
			acceptExtensions: ['jpg', 'jpeg', 'png', 'gif', 'pdf'],
			maxSize: 4 * AppConstantsBase.MEGABYTE
		},
	};

	static MIN_AUTOCOMPLETER_INPUT_LENGTH = 3;
	static MIN_AUTOCOMPLETER_INPUT_LENGTH_RECOVERY_ADVISOR = 1;
	static MIN_AUTOCOMPLETER_SEARCH_RESULTS = 20;

	static AUTHENTICATION_MAX_TRIES_IN_DELAY = 2;
	static AUTHENTICATION_MAX_TRY_DELAY_SECONDS = 60 ;

}
