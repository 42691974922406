export const AUTH_EVENTS = {
	Logout: 'logout'
}

export const AUTH_ERRORS = {
	LoggedInUserDoesNotExists: 'LoggedInUserDoesNotExists',
	UserSignedOut: 'UserSignedOut',
}

export function shortToken(token: string) {
	return token
		? token.substring(token.length - 5)
		: null
}

export function stripSlashes(str: string) {
	return str.trim().replace(/\/$/, '')
}

export class SimpleAuthenticationStorageService {
	get ns() {
		return 'U'
	}

	read(key: string) {
		return localStorage.getItem(`${this.ns}/${key}`);
	}

	write(key: string, value: any): void {
		localStorage.setItem(`${this.ns}/${key}`, value);
	}

	remove(key: string): void {
		localStorage.removeItem(`${this.ns}/${key}`);
	}

	clear(): void {
		localStorage.clear();
	}

	dispatch(key: string, newValue: string | number = Date.now()): void {
		localStorage.setItem(key, newValue.toString())
	}

	onKeyChange(keyMatch, handler: (evt: StorageEvent) => void) {
		window.addEventListener('storage', (event: StorageEvent) => {
			if (event.key.includes(keyMatch)) handler(event)
		});
	}
}
