import { map, tap, filter, switchMap, startWith, withLatestFrom, auditTime, share, distinctUntilChanged } from 'rxjs/operators';
import { ReplaySubject, fromEvent, Observable, from, combineLatest, merge, Subject, BehaviorSubject, of, timer } from 'rxjs';
import { XhrHttpClient } from '@microsoft/signalr/dist/esm/XhrHttpClient';
import { HttpClient } from '@angular/common/http';
import * as signalR from '@microsoft/signalr';
import { ILogger } from '@microsoft/signalr';
import { Injector } from '@angular/core';

import { AppBaseConfiguration } from '../app-base.configuration';
import { shortToken, stripSlashes } from '../functions';
import { AppConstants } from '../config';

export type WsEvent = any;

const debug = (...args) => window['__aston_debug_ws'] && console.debug(...args)

export class WSDumpLogger implements ILogger {
	log(...args): void {
		if (args[1] && args[1].includes('String data of length')) {
			// these are pretty useless
			return
		}
		debug(`[ws] ${args[1]}`)
	}
}

export abstract class AFactoringHttpClient<Auth> extends XhrHttpClient {
	abstract get authService(): Auth

	constructor(protected injector: Injector, protected logger: ILogger) {
		super(logger)
	}
}

export abstract class WebSocketController<
	WsEvents extends Record<string, string>,
	Telemetry extends { event(type: string, event: any): void },
	Logger extends ILogger,
	WSHttpClient extends XhrHttpClient,
	Auth extends { getIsAuthenticated(): Observable<boolean>, getAccessToken(): Observable<string>, getTenantId(): string },
> {

	protected _hubUrl = '/hubs/main';
	protected _hub: signalR.HubConnection;

	private hub$: ReplaySubject<signalR.HubConnection> = new ReplaySubject(1);
	private fakeEvents$: Subject<WsEvent> = new Subject();

	// temporary hack to force re-refetching the access token
	// Bug 35119: Le WS ne se reconnecte pas
	private forceReconnect$: BehaviorSubject<number> = new BehaviorSubject(0);
	private reconnectHandler;
	private forceReconnector = ([config, user, token, _]) => {
		if (config && user && !token) {
			if (this.reconnectHandler) clearTimeout(this.reconnectHandler)
			this.reconnectHandler = setTimeout(_ => {
				debug(`[ws] schedule a force-reconnect`)
				this.forceReconnect$.next(this.forceReconnect$.getValue() + 1)
			}, 500)
		}
	}

	protected shouldReconnect([_a, ua, tokenA, forcedA], [_b, ub, tokenB, forcedB]) {
		return `${!!ua?.id}${tokenA}${forcedA}` === `${!!ub?.id}${tokenB}${forcedB}`
	}

	WsEventTypes: Record<string, string> = {}

	protected onLogout$: Observable<any>;
	protected onLoginConfig$: Observable<any>;

	public connected$ = new BehaviorSubject<boolean>(false);

	// Bug 35264: Le message WS s'affiche
	// Bug 35119: Le WS ne se reconnecte pas
	public connectWarning$ = timer(8 * AppConstants.SECOND, 4 * AppConstants.SECOND).pipe(
		withLatestFrom(this.authService().getIsAuthenticated()),
		map(([_, loggedIn]) => loggedIn && !this.connected$.getValue()),
		switchMap(warn => timer(warn ? 0 : 4 * AppConstants.SECOND)),
		startWith(false),
	);

	public loggedOut$ = new BehaviorSubject<boolean>(true);
	public hubId$ = this.hub$.pipe(map(h => h.connectionId))

	constructor(
		protected injector: Injector,
		protected http: HttpClient,
		protected logger: () => Logger,
		protected wsEventTypes: () => WsEvents,
		protected httpClient: () => WSHttpClient,
		protected telemetryService: () => Telemetry,
		protected authService: () => Auth,
		protected selectConfig: () => Observable<AppBaseConfiguration>,
		protected selectCurrentUser: () => Observable<any>,
	) {
		this.WsEventTypes = { ...(wsEventTypes()) }

		this.onLogout$ = combineLatest([
			this.hub$,
			selectCurrentUser()
		]).pipe(
			filter(([hub, user]) => hub && !user),
			map(([hub]) => hub)
		);

		this.onLoginConfig$ = of(null).pipe(
			switchMap(_ => combineLatest([
				this.selectConfig(),
				this.selectCurrentUser(),
				this.forceReconnect$.pipe(switchMap(_ => authService().getAccessToken())),
				this.forceReconnect$,
			])),
			tap(([config, _, token]) => debug(`[ws] dependencies changed: config=${!!config} token=${shortToken(token)}`)),
			tap(this.forceReconnector),
			filter(([config, user, token]) => config && user && !!token),
			distinctUntilChanged(this.shouldReconnect),
			auditTime(200),
			tap(_ => debug(`[ws] dependencies change acknowledged`)),
			share(),
		);

		this.connect();

		this.onLogout$.subscribe(hub => {
			debug('[ws] stop websocket hub after logout');
			hub.stop();
		});

		// Show all received messages in local env
		this.onLoginConfig$.pipe(
			switchMap(_ => merge(...Object.values(this.WsEventTypes)
			.map(type => this.ofType(type as WsEvent).pipe(map(event => ( { type, event } ))))
			))
		).subscribe(e =>
			debug(`[ws] received event [${e.type}] with data [${JSON.stringify(e.event)}]`));
	}

	get hubUrl(): string {
		return `${this._hubUrl}?tenant_id=${this.authService().getTenantId()}`;
	}

	private connectionWrapper(connection: signalR.HubConnection) {
		connection.onreconnected(_ => {
			this.connected$.next(true);
		});
		connection.onreconnecting(_ => {
			this.connected$.next(false);
		});
		connection.onclose(_ => {
			this.connected$.next(false);
		});
		connection.start()
		.then(_ => {
			this.hub$.next(connection);
			this.connected$.next(true);
		})
		.catch(error => {
			debug(`[ws] cannot connect to websocket hub "${this.hubUrl}": ${error}`);
			debug(`[ws] will trigger a reconnect in ${AppConstants.WEBSOCKET_INIT_RECONNECT_MAX_RETRIES}sec`);
			setTimeout(_ => {
				if (this.forceReconnect$.getValue() > AppConstants.WEBSOCKET_INIT_RECONNECT_MAX_RETRIES) {
					debug(`[ws] will NOT try to reconnect after ${AppConstants.WEBSOCKET_INIT_RECONNECT_MAX_RETRIES} failures`);
					return
				}
				// https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-8.0&tabs=visual-studio#automatically-reconnect
				// withAutomaticReconnect won't configure the HubConnection to retry initial start failures, so start failures need to be handled manually
				this.forceReconnect$.next(this.forceReconnect$.getValue() + 1)
			}, AppConstants.WEBSOCKET_INIT_RECONNECT_TIMEOUT)
		});
	}

	connect() {
		this.onLoginConfig$.pipe(
			tap(_ => debug(`[ws] init websocket hub`)),
			withLatestFrom(this.hub$.pipe(startWith(null))),
			map(([[config, _, accessToken], currentHub]) => {
				if (currentHub) {
					debug(`[ws] stop the current hub before reinit`)
					currentHub.stop();
				}
				return new signalR.HubConnectionBuilder()
				.withUrl(`${stripSlashes(config.apiUrl)}${this.hubUrl}`, {
					accessTokenFactory: () => accessToken,
					httpClient: this.httpClient()
				})
				.withAutomaticReconnect({
					nextRetryDelayInMilliseconds: retryContext => {
						if (!accessToken) {
							debug(`wont reconnect to websocket hub until login`);
							return null;

						} else if (retryContext.elapsedMilliseconds < 60000) {

							// If we've been reconnecting for less than 60 seconds so far,
							return 1000;

						} else {
							// If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
							debug(`cannot reconnect to websocket hub`);
							return null;
						}
					}
				})
				.configureLogging(this.logger())
				.build();
			})
		).subscribe(connection => {
			if (this._hub) {
				debug(`[ws] stop the current hub after reinit`)
				this._hub.stop()
			}
			this._hub = connection
			this.connectionWrapper(connection)
		});
	}

	ofType(...types: WsEvents[]): Observable<WsEvent> {
		return merge(
			this.hub$.pipe(switchMap(hub => merge(...types.map(eventType =>
				fromEvent(hub, eventType.toString()),
				tap<any>(event => this.telemetryService()?.event('wsevent', event))
			)))),
			this.fakeEvents$.pipe(filter(event => types.includes(event.type))) // for testing
		);
	}

	send(methodName: string, ...args: WsEvent[]) {
		return this.hub$.pipe(
			switchMap(hub => from(hub.send(methodName, ...args))),
			tap(_ => {
				debugger; // eslint-disable-line no-debugger
			})
		);
	}

	emitLocally(...args: WsEvent[]) {
		args.forEach(event => this.fakeEvents$.next(event));
	}
}
