
<div class="authenticated-layout" [class.with-debug-bar]="!debugBar()?.hidden()">
	<layout-header
		[isAuthenticated]="isAuthenticated"
		[currentLanguage]="currentLanguage$ | async"
		[currentActionId]="currentActionId$ | async"
		[userSession]="userSession$ | async"
		[tenant]="tenant$ | async"
		(lang)="setLanguage($event)"
		(logout)="logout()"
	/>

	<section id="content">
		<main id="main" scrolledByContainer>
			<router-outlet/>
		</main>
	</section>
</div>

<footer id="footer" class="has-bg">
	<div class="footer-legal-text">
		<span class="footer-legal">© 2019 - {{year}} <a href="{{ 'App.Author.Website' | translate }}" target="_blank" data-cy="OpenTabLegal">
			<img src="assets/images/logos/logo_aston_ai.png" alt="{{ 'App.Author.Name' | translate }}">
		</a></span>
	</div>
</footer>

<debug-bar/>
