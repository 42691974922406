import { dateOnlyToDate, mapToListModel } from '@aston/foundation';

import { IAccountingListItem, IAccountingBaseItem, IAccountingItemList } from '../models';
import { IAccountingBaseItemListApi, IAccountingItemApi } from '../models/api';


export function mapToAccountingBaseModel(apiModel: IAccountingItemApi): IAccountingBaseItem {
	return {
		...apiModel,
		displayId: apiModel.displayId || apiModel.functionalId,
		accountingDate: dateOnlyToDate(apiModel.accountingDate),
		dueDate: dateOnlyToDate(apiModel.dueDate),
		paymentDate: dateOnlyToDate(apiModel.paymentDate),
		claimName: apiModel.claimName,
		hasNewComment: apiModel.hasNewComment,
	};
}

export function mapToAccountingItemModel(apiModel: IAccountingItemApi): IAccountingListItem {
	return {
		...mapToAccountingBaseModel(apiModel),
		isSelected: false
	};
}

export function mapToInvoicesListModel(apiModel: IAccountingBaseItemListApi): IAccountingItemList {
	return {
		...mapToListModel(apiModel.accountingDocuments, mapToAccountingItemModel),
		meta: {
			totalBalanceInvoicing: apiModel.totalBalanceInvoicing,
		}
	};
}
