import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { map, switchMap, mapTo } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as AppStoreSelectors from 'apps/debtor-portal/src/app/root-store/app-store/selectors';

import { INotificationItemApi } from '../models/api';
import { INotificationItem } from '../models';
import { mapToNotificationItemModel } from '../functions/mappers.function';

@Injectable({
	providedIn: 'root'
})
export class NotificationsService {


	constructor(private http: HttpClient, private store: Store) {
	}

	base$: Observable<string> = combineLatest([
		this.store.select(AppStoreSelectors.selectConfig),
		this.store.select(AppStoreSelectors.selectTenant)
	]).pipe(
		map(([config, tenant]) => `${config.apiUrl}/${tenant.superDebtorId}`)
	)

	getNotifications(): Observable<INotificationItem[]> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.get<INotificationItemApi[]>(`${config.apiUrl}/api/UserNotifications/me`)),
			map(apiModel => apiModel.map(m => mapToNotificationItemModel(m)))
		);
	}

	markAsRead(id: number): Observable<boolean> {
		return this.store.select(AppStoreSelectors.selectConfig).pipe(
			switchMap(config => this.http.post(`${config.apiUrl}/api/UserNotifications/${id}/markasread`, {})),
			mapTo(true)
		);
	}
	
	sendNotificationToMO() {
		return this.base$.pipe(
			switchMap(apiUrl => this.http.post(`${apiUrl}/paymentLink/notification`, {}))
		);
	}

}
