
import { AppConfiguration } from 'apps/debtor-portal/src/app/app.configuration';
import { ITenantInfos, IUser, IUserSession } from 'apps/debtor-portal/src/app/authentication-module/models';
import { IFactorConfiguration, ICountryListItem, uuid } from '@aston/foundation';
import { IConfigurationReferential } from 'apps/debtor-portal/src/app/shared-module/models';

import { IStoreEntityStateSlice, createEntitySlice } from '../functions';

export interface IAppState {
	isLoading: boolean;
	isFinished: boolean;
	sessionId: string,
	bootstrapError: Error,
	validatorsLoaded: boolean;
	config: AppConfiguration;
	factorConfig: IStoreEntityStateSlice<IFactorConfiguration>;
	isScrolling: boolean;
	tenant: IStoreEntityStateSlice<ITenantInfos>;
	isPlatformCustomizationEnabled: boolean;
	logo: string;
	user: IUser;
	scripts: { [name: string]: boolean; };
	userSession: IUserSession;
	countries: IStoreEntityStateSlice<ICountryListItem[]>;
	language: IStoreEntityStateSlice<{language:string}>;
	referential: IStoreEntityStateSlice<IConfigurationReferential>;
}

export const initialState: IAppState = {
	isLoading: true,
	isFinished: false,
	sessionId: uuid(),
	bootstrapError: null,
	validatorsLoaded: false,
	config: null,
	factorConfig: createEntitySlice(),
	isScrolling: false,
	tenant: createEntitySlice(),
	isPlatformCustomizationEnabled: null,
	logo: null,
	user: null,
	scripts: {},
	userSession: {},
	countries: createEntitySlice({ entity: [] }),
	language: createEntitySlice(),
	referential: createEntitySlice(),
};
