<div class="modal-header">
    <h2>{{ title }}</h2>
    <button type="button" class="modal-button-close fal fa-times" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
    <loader-spinner [model]="loader"/>
    @if (!loader.isLoading && !loader.errorMessage) {
        @if (files.length) {
            <ngb-carousel [interval]="0" [showNavigationArrows]="files.length > 1" [showNavigationIndicators]="files.length > 1">
                @for (file of files; track file) {
                    <ng-template ngbSlide>
                        @if (file.error) {
                            <ngb-alert type="warning" class="alert alert-warning document-error" [dismissible]="false">
                                <span class="content">
                                    {{ 'Errors.NotFound' | translate }} ({{ file.id + ' : ' + file.name }})
                                </span>
                            </ngb-alert>
                        } @else {
                            @if (previewType(file) === documentPreviewType.Image) {
                                <img [src]="file.url"/>
                            }
                            @if (previewType(file) === documentPreviewType.Iframe) {
                                <iframe [src]="file.url"></iframe>
                            }
                            @if (previewType(file) === documentPreviewType.Download) {
                                <div class="document-download">
                                    <div class="document-download-name">{{ file.name }}</div>
                                    <a class="btn btn-primary" target="_blank" [href]="file.url" translate="Actions.Download" data-cy="Download"></a>
                                </div>
                            }
                            <div class="carousel-caption"></div>
                        }
                    </ng-template>
                }
            </ngb-carousel>
        }
    }
</div>
