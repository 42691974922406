<div id="app" class="{{ layoutName }}-page">
    <div id="app-container" class="container">
        <router-outlet />
    </div>
</div>

<div class="page-loader" [class.is-loading]="pageLoader.isLoading$ | async">
    <loader-spinner [model]="pageLoader" />
</div>

<div class="page-notifications">
    @for (
        notification of notifications;
        track notification;
        let index = $index
    ) {
        <notification-message
            [notification]="notification"
            (dismissEvent)="removeNotification(index)"
            [autoCloseDelay]="8000"
        />
    }
</div>
