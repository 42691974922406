
import { Directive, ElementRef, HostListener, Injectable, Pipe, PipeTransform, inject, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScrolledByService {

	static HEADER = 50
	static SAFE_MARGIN = 20

	private scroll = signal(0)
	private height = signal(0)

	scrolled = this.scroll.asReadonly()

	update(height: number, scroll: number) {
		this.height.set(height)
		this.scroll.set(scroll)
	}

	taller(min: number) {
		return this.height() >= min
	}

	after(min: number) {
		return this.scroll() >= min
	}

	/**
	 * Return the given class name if the nearest scrolledByContainer
	 *  - is scrolled more than the given value
	 *  - is taller than the given height
	 * 
	 * @param name Class name
	 * @param minScroll number
	 * @param minHeight number, defaults to 950
	 * @returns string
	 */
	classAfter(name: string, minScroll: number, minHeight = 950) {
		return (this.taller(minHeight + ScrolledByService.HEADER + ScrolledByService.SAFE_MARGIN) && this.after(minScroll)) ? name : ''
	}
}

@Directive({
	selector: '[scrolledByContainer]',
	standalone: true,
})
export class ScrolledByContainerDirective {
	service = inject(ScrolledByService)
	element = inject(ElementRef)

	@HostListener('scroll', [])
    onScroll() {
		this.service.update(
			Math.round(this.element.nativeElement.clientHeight),
			Math.round(this.element.nativeElement.scrollTop),
		);
    }
}

@Pipe({
	name: 'scrolledBy',
	standalone: true,
	pure: false
})
export class ScrolledByPipe implements PipeTransform {

	constructor(private service: ScrolledByService) {}

	transform(min: number): boolean {
		return this.service.after(min)
	}
}