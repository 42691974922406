import { Component, Input, Output, EventEmitter } from '@angular/core';

import { ICommentItem } from '../../models/comment.model';
import { CommentItemWrapperComponent } from '../comment-item-wrapper/comment-item-wrapper.component';
import { FormCommentsComponent } from '../form-comments/form-comments.component';


@Component({
    selector: 'comments-block',
    templateUrl: './comments-block.component.html',
    styles: [],
    standalone: true,
    imports: [FormCommentsComponent, CommentItemWrapperComponent]
})
export class CommentsBlockComponent {
	@Input() comments: ICommentItem[];
	@Output() submitNewComment: EventEmitter<ICommentItem> = new EventEmitter();

	submitComment(comment: ICommentItem) {
		this.submitNewComment.emit(comment);
	}
}
