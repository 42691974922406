
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ExportResponse, IListState, getListApiHttpParam } from '@aston/foundation';
import * as AppStoreSelectors from 'apps/debtor-portal/src/app/root-store/app-store/selectors';

import { AccountingListSortProp } from '../enums';
import { IAccountingBaseItemListApi } from '../models/api';
import { IAccountingItemList } from '../models';
import { mapToInvoicesListModel } from '../functions';

@Injectable({
	providedIn: 'root'
})
export class InvoicingService {
	constructor(
		private http: HttpClient,
		private store: Store,
	) { }

	base$: Observable<string> = combineLatest([
		this.store.select(AppStoreSelectors.selectConfig),
		this.store.select(AppStoreSelectors.selectTenant),
	]).pipe(map(([config, tenant]) => `${config.apiUrl}/${tenant.superDebtorId}`))

	getInvoices(params: IListState<AccountingListSortProp>): Observable<IAccountingItemList> {
		const httpParams = getListApiHttpParam(params);

		return this.base$.pipe(
			switchMap(apiUrl => this.http.get<IAccountingBaseItemListApi>(`${apiUrl}/invoices`, {params: httpParams}).pipe(
				map(apiModel => mapToInvoicesListModel(apiModel))
			))
		);
	}

	exportInvoicingList(params: IListState<AccountingListSortProp>): Observable<ExportResponse> {
		const httpParams = getListApiHttpParam(params);
		return this.base$.pipe(
			switchMap(apiUrl => this.http.get(`${apiUrl}/invoices/export?${httpParams}`, {observe: 'response', responseType: 'blob'})),
			map(response => new ExportResponse(response))
		);
	}

	getInvoicesCount(params: IListState<AccountingListSortProp>): Observable<number> {
		const httpParams = getListApiHttpParam(params);

		return this.base$.pipe(
			switchMap(apiUrl => this.http.get<number>(`${apiUrl}/invoices/count`, {params: httpParams}))
		);
	}
}
