import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { AppConstants } from 'apps/debtor-portal/src/app/app.constants';

import { ScriptToLoad } from '../enums';

@Injectable({
	providedIn: 'root'
})
export class ScriptLoaderService {

	load(name: ScriptToLoad): Observable<boolean> {
		return new Observable<boolean>((observer: Observer<boolean>) => {
			const scriptSrc = AppConstants.SCRIPTS_SRC[name];
			const scriptElement = document.createElement('script');

			scriptElement.type = 'text/javascript';
			scriptElement.src = scriptSrc;

			scriptElement.onload = () => {
				observer.next(true);
				observer.complete();
			};

			scriptElement.onerror = () => {
				observer.error(`Couldn't load script ${name} : ${scriptSrc}`);
			};

			document.getElementsByTagName('body')[0].appendChild(scriptElement);
		});
	}
}
