
import { Observable } from 'rxjs';
import { ValidatorFn } from '@angular/forms';

import { IFilterOperator } from '../enums/filter-operator.enum';

// simple alphanumeric regexp
export const alphanum = '^[a-zA-Z0-9]+$';
// alphanumeric with most european accented characters and other special ones (', ", &, =, +, _, -) allowed
export const alphanumExtended = '^[a-zA-Z0-9à-úÀ-Ú -\'"&@\\.=+_-]+$';
// alphanumeric with space and most european accented characters and other special ones (', ", &, =, +, _, -) allowed
// eslint-disable-next-line no-useless-escape
export const alphanumExtendedWithSpace = '^[\sa-zA-Z0-9à-úÀ-Ú -\'"&@\\.=+_-]+$';
// numeric string REGEXP accepts . for decimal separator with optional 1 to 2 digits after separator
export const numeric = '^-?[0-9]{1,15}$';
export const numPercent = '^(100|[1-9]\\d?|0)$';
export const decimal = '^-?[0-9]{1,15}([\\.,][0-9]{1,2})?$';
export const positiveDecimal = '^[0-9]{1,15}([\\.,][0-9]{1,2})?$';
export const phone = '^[0-9 \\.\\-\\+]{10,30}$';
// Lettering code should be maximum 4 alpha char long and with same case (ex: ABCD or abcd)
export const letteringCode = '^([a-z]{1,4}|[A-Z]{1,4})$';

export const booleanOperatorValues = [
	{ value: false, label: 'CommonTexts.No' },
	{ value: true, label: 'CommonTexts.Yes' },
];

export const stringOperatorsSpecs: IFilterOperatorSpec[] = [
	{ operator: IFilterOperator.Contains },
	{ operator: IFilterOperator.NotContains }
];

export const stringExactOperatorsSpecs: IFilterOperatorSpec[]
	= stringOperatorsSpecs.map(o => ({ ...o, caseSensitive: true}));

export const numericOperatorsSpecs: IFilterOperatorSpec[] = [
	{ operator: IFilterOperator.Equals },
	{ operator: IFilterOperator.NotEquals },
	{ operator: IFilterOperator.GreaterThan },
	{ operator: IFilterOperator.LessThan },
	{ operator: IFilterOperator.GreaterThanOrEqualTo },
	{ operator: IFilterOperator.LessThanOrEqualTo },
	{ operator: IFilterOperator.Between }
];


export const dateOperatorsSpecs: IFilterOperatorSpec[] = [
	{ operator: IFilterOperator.Equals },
	{ operator: IFilterOperator.NotEquals },
	{ operator: IFilterOperator.GreaterThan },
	{ operator: IFilterOperator.LessThan },
	{ operator: IFilterOperator.GreaterThanOrEqualTo },
	{ operator: IFilterOperator.LessThanOrEqualTo },
	{ operator: IFilterOperator.DateBetween }
];

export const autocompleteOperatorsSpecs: IFilterOperatorSpec[] = [
	{ operator: IFilterOperator.Among },
	{ operator: IFilterOperator.NotAmong },
];

export const amongMultipleOperatorsSpecs: IFilterOperatorSpec[] = [
	{ operator: IFilterOperator.Among, allowMultiple: true },
	{ operator: IFilterOperator.NotAmong, allowMultiple: true },
];

export const booleanOperatorSpecs: IFilterOperatorSpec[] = [
	{ operator: IFilterOperator.Equals, values: booleanOperatorValues, allowMultiple: true, translateValues: true },
	{ operator: IFilterOperator.NotEquals, values: booleanOperatorValues, allowMultiple: true, translateValues: true },
]

export interface IFilterField {
	label: string;
	rawLabel?: boolean;
	value: any;
	backend?: string;
}

export interface IFilterValue {
	label: string;
	value: any;
}

export interface IFilterOperatorSpec {
	operator: IFilterOperator;
	values?: IFilterValue[];
	allowMultiple?: boolean;
	translateValues?: boolean;
	caseSensitive?: boolean;
	strictLowBoundary?: boolean;
}

export interface IFilterFieldSpec {
	field: IFilterField;
	operatorSpecs: IFilterOperatorSpec[];
	defaultOperator?: IFilterOperator;
	defaultValue?: any[];
	validation?: string | ValidatorFn;
	isDate?: boolean;
	isDateOnly?: boolean;
	isExtradata?: boolean;
	hasDateExpression?: boolean;
	autocompleter?: any;
	autocompleterDisplayProp?: string;
	chipValues?: (value: IFilterValue[]) => Observable<IFilterValue[]>;
	chipsOptions?: IFilterChipsOptions;
	valueTransform?: (value: any[]) => any[];
}

export interface IFilterSpec {
	items: IFilterFieldSpec[];
}

export interface IFilterCategories {
	[key: string]: {
		defaultField?: string;
		value: IFilterSpec;
		label: string;
	};
}

export interface IFieldAutocompleters {
	[key: string]: (term: string) => Observable<IFilterValue[]>
}

export interface IFilterChipsOptions {
	validators?: ValidatorFn[];
	minimumTextLength?: number;
	showDropdownIfEmpty?: boolean;
	groupBy?: string;
	addTag?: boolean;
	notClearSearchOnAdd?: boolean;
}
