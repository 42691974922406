<div class="table-responsive">
    <table class="table table-sortable">
        <thead class="thead-light">
            <tr>
                <th
                    class="col-id-lg is-sortable"
                    translate="DataList.Columns.ReferenceShort"
                    [ngClass]="getSortClasses(sortCols.DunningActionId)"
                    (click)="setSort(sortCols.DunningActionId)"
                ></th>
                <th
                    class="col-small-type"
                    translate="DataList.Columns.DunningType"
                ></th>
                <th
                    class="col-acc-date is-sortable"
                    translate="DataList.Columns.RealizationDate"
                    [ngClass]="getSortClasses(sortCols.RealizationDate)"
                    (click)="setSort(sortCols.RealizationDate)"
                ></th>
                <th
                    class="col-due-date is-sortable"
                    translate="DataList.Columns.DunningAmount"
                    [ngClass]="getSortClasses(sortCols.DunningAmountInvoicing)"
                    (click)="setSort(sortCols.DunningAmountInvoicing)"
                ></th>
                <th
                    class="col-amount is-sortable"
                    translate="DataList.Columns.DunningTotalBalance"
                    [ngClass]="getSortClasses(sortCols.TotalBalanceInvoicing)"
                    (click)="setSort(sortCols.TotalBalanceInvoicing)"
                ></th>
                <th
                    class="col-file"
                    translate="DataList.Columns.AccountingDocuments"
                ></th>
                <th
                    class="col-comment"
                    translate="DataList.Columns.Comment"
                ></th>
                <th class="col-file" translate="DataList.Columns.Preview"></th>
            </tr>
        </thead>
        <tbody>
            @for (
                item of list.items;
                track item.dunningActionId;
                let odd = $odd;
                let even = $even
            ) {
                <tr
                    actions-list-table-row
                    [class.table-odd]="odd"
                    [class.table-even]="even"
                    [currency]="currency"
                    [item]="item"
                ></tr>
            }
            @if (list.items.length === 0) {
                <tr table-row-empty></tr>
            }
        </tbody>
    </table>
</div>
