import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as AppStoreActions from 'apps/debtor-portal/src/app/root-store/app-store/actions';

import { AppStoreModule } from './app-store';
import { AccountingStoreModule } from './accounting-store';
import { NotificationsStoreModule } from './notifications-store';
import { CommentsStoreModule } from './comments-store';
import { getStateFromPreviousSession } from './root-state';


export function logout(reducer) {
	return function (state, action) {
		return reducer(action.type === AppStoreActions.Logout.type ? getStateFromPreviousSession(state) : state, action);
	};
}

// export function throwOnUndefined(reducer) {
// 	return function (state, action) {
// 		const newState = reducer(state, action);
// 		Object.keys(newState).map(sliceName => {
// 			if (newState[sliceName] === undefined) {
// 				console.error(`Your ${sliceName} reducer returned undefined. Did you forgot to handle the "default" case ?`);
// 			}
// 		});
// 		return newState;
// 	};
// }


// The metareducers option allow to reset the whole internal state on logout
// see https://ngrx.io/guide/store/metareducers
// export const MetareducedStoreModule = StoreModule.forRoot({}, { metaReducers: [logout, throwOnUndefined] });
export const MetareducedStoreModule = StoreModule.forRoot({}, {
	metaReducers: [logout],
	runtimeChecks: {
		// because we are savages
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
	},
});

@NgModule({
	declarations: [],
	imports: [
		AccountingStoreModule,
		AppStoreModule,
		CommentsStoreModule,
		CommonModule,
		MetareducedStoreModule,
		NotificationsStoreModule,
		EffectsModule.forRoot([]),
	]
})
export class RootStoreModule { }
