import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { computed } from '@angular/core';

import { AppConstants } from '../../config/constants.config';
import { IBookmark } from '../../models/bookmark.model';


export const BookmarksStore = signalStore(
	{ providedIn: 'root' },

	withState({	items: [] as IBookmark[] }),
	
	withComputed(({ ...store }) => ({
		limitReached: computed(() => store.items().length >= AppConstants.BOOKMARKS_LIMIT),
	})),
	
	withMethods(({ ...store }) => {
		const add = (item: IBookmark) => patchState(store, { items: [...store.items(), item] });
		const remove = (item: IBookmark) => patchState(store, { items: store.items().filter(i => i.url !== item.url) });
		const isBookmarked = (url: string) => !!store.items().find(i => i.url === url);
		const isDisabled = (url: string) => store.limitReached() && !isBookmarked(url);
		const toggle = (item: IBookmark) => isBookmarked(item.url) ? remove(item) : add(item);
		
		return {
			add,
			remove,
			toggle,
			isDisabled,
			isBookmarked,
		}
	}),
)

export type BookmarksStore = InstanceType<typeof BookmarksStore>;
