<div class="table-responsive" [class.table-fixed-height-wrapper]="fixedHeight">
    <table class="table table-sortable">
        <thead class="thead-light">
            <tr>
                @if (!hideCheckbox) {
                    <th class="col-checkbox"></th>
                }
                <th
                    class="col-id-lg"
                    [class.is-sortable]="isSortable"
                    translate="DataList.Columns.Reference"
                    [class.d-none]="!showId"
                    [ngClass]="getSortClasses(sortCols.DisplayId)"
                    (click)="setSort(sortCols.DisplayId)"
                ></th>
                <th
                    class="col-small-type"
                    translate="DataList.Columns.Type"
                ></th>
                <th
                    class="col-acc-date"
                    [class.is-sortable]="isSortable"
                    translate="DataList.Columns.AccountingDate"
                    [ngClass]="getSortClasses(sortCols.AccountingDate)"
                    (click)="setSort(sortCols.AccountingDate)"
                ></th>
                <th
                    class="col-due-date"
                    [class.is-sortable]="isSortable"
                    translate="DataList.Columns.DueDate"
                    [ngClass]="getSortClasses(sortCols.DueDate)"
                    (click)="setSort(sortCols.DueDate)"
                ></th>
                <th
                    class="col-amount"
                    [class.is-sortable]="isSortable"
                    translate="DataList.Columns.AmountTTC"
                    [ngClass]="getSortClasses(sortCols.Amount)"
                    (click)="setSort(sortCols.Amount)"
                ></th>
                <th
                    class="col-amount"
                    [class.is-sortable]="isSortable"
                    translate="DataList.Columns.Balance"
                    [ngClass]="getSortClasses(sortCols.Balance)"
                    (click)="setSort(sortCols.Balance)"
                ></th>
                @if (showStatus) {
                    <th
                        class="col-small-type"
                        translate="DataList.Columns.Status"
                    ></th>
                }
                <th
                    class="col-file"
                    translate="DataList.Columns.AccountingDocumentFile"
                ></th>
                <th
                    class="col-comment"
                    translate="DataList.Columns.Comment"
                ></th>
            </tr>
        </thead>
        <tbody>
            @for (
                item of list.items;
                track item.accountingDocumentId;
                let odd = $odd;
                let even = $even
            ) {
                <tr
                    accounting-list-table-row
                    [class.table-odd]="odd"
                    [class.table-even]="even"
                    [item]="item"
                    [showStatus]="showStatus"
                    [currency]="currency"
                ></tr>
            }
            @if (list.items.length === 0) {
                <tr table-row-empty></tr>
            }
        </tbody>
        @if (list.items.length > 0) {
            <tfoot class="table-footer-totals">
                <tr>
                    <td
                        translate="DataList.Columns.Total"
                        colspan="5"
                        class="text-right"
                    ></td>
                    <td class="col-amount">
                        {{ list.meta.totalBalanceInvoicing | amount: { with: 'symbol-narrow' } }}
                    </td>
                    <td></td>
                    <td></td>
                </tr>
            </tfoot>
        }
    </table>
</div>
