import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class CacheInterceptor implements HttpInterceptor {

	// https://medium.com/@tltjr/disabling-internet-explorer-caching-in-your-angular-5-application-3e148f4437ad
	intercept(req: HttpRequest<any>, next: HttpHandler) {
		if (req.url.includes('/assets') || req.url.includes('connect/token')) {
			return next.handle(req);
		}

		const headers = req.headers
			.set('Cache-Control', 'no-cache')
			.set('Pragma', 'no-cache')
			.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

		const httpRequest = req.clone({ headers });

		return next.handle(httpRequest);
	}
}
