import { AFactoringHttpClient, TelemetryService, WSDumpLogger, WebSocketController, WsEvent } from '@aston/foundation';
import { Observable, BehaviorSubject, EMPTY } from 'rxjs';
import { Injector, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import { AuthenticationService } from '../../authentication-module/services';
import { AppStoreSelectors } from '../../root-store/app-store';
import { WsEventType } from '../enums';

const logger = new WSDumpLogger;

class WSHttpClient extends AFactoringHttpClient<AuthenticationService> {
	constructor(protected injector: Injector) {
		super(injector, logger)
	}
	get authService(): AuthenticationService {
		return this.injector.get(AuthenticationService);
	}
}

@Injectable({ providedIn: 'root' })
export class WebSocketService extends WebSocketController<
	WsEvent,
	TelemetryService,
	WSDumpLogger,
	WSHttpClient,
	AuthenticationService
> {
	constructor(
		protected injector: Injector,
		protected http: HttpClient,
		protected store: Store,
	) {
		super(
			injector,
			http,
			() => logger,
			() => WsEventType,
			() => new WSHttpClient(injector),
			() => injector.get(TelemetryService),
			() => injector.get(AuthenticationService),
			() => store.select(AppStoreSelectors.selectConfig),
			() => store.select(AppStoreSelectors.selectCurrentUser),
		)
	}
}

export const provideMockWebSocketService = (subject$: Observable<any> = EMPTY) => ({
	provide: WebSocketService, useValue: { ofType: _ => subject$, connected$: new BehaviorSubject(true) }
})
