export const BaseRoutePath = '';

export const AuthenticationRoutePath = 'authentication';
export const LoginRoutePath = 'login';
export const AuthenticationIssueRoutePath = 'issue';
export const LoginCallbackRoutePath = 'callback';

export const DashboardsPageRoutePath = 'dashboard';

export const DunningAccountingRoutePath = 'show/:id';
export const DunningActionsListRoutePath = 'actions';
export const DunningInvoicingRoutePath = 'invoices';

export const AccountingDocsListRoutePath = 'accounting';
export const AccountingDetailRoutePath = ':id';

export const AdminToolsPath = 'admin-tools';
export const AdminToolsDefaultPath = 'default';
export const AdminToolsIndicatorsPath = 'indicators';
export const AdminToolsWorkflowSchedulingInstancesListPath = 'workflow-scheduling-instances';
export const AdminToolsWorkflowSchedulingInstanceDetailPath = ':id';

export function getBasePath(): string {
	return BaseRoutePath;
}

export function getDashboardFullPath(): string {
	return `${getBasePath()}/${DashboardsPageRoutePath}`;
}

export function getAuthenticationFullPath(): string {
	return `${getBasePath()}/${AuthenticationRoutePath}`;
}

export function getLoginFullPath(): string {
	return `${getAuthenticationFullPath()}/${LoginRoutePath}`;
}

export function getAuthenticationIssueFullPath(): string {
	return `${getAuthenticationFullPath()}/${AuthenticationIssueRoutePath}`;
}

export function getLoginCallbackFullPath(): string {
	return `${getAuthenticationFullPath()}/${LoginCallbackRoutePath}`;
}

export function getRedirectionAfterLoginFullPath(): string {
	return getAccountingListFullPath();
}

export function getAccountingListFullPath(): string {
	return `${getBasePath()}/${AccountingDocsListRoutePath}`;
}

export function getDunningAccountingFullPath(id: number): string {
	return `${getBasePath()}/${DunningAccountingRoutePath.replace(':id', '' + id)}`;
}

export function getDunningInvoicingFullPath(): string {
	return `${getBasePath()}/${DunningInvoicingRoutePath}`;
}

export function getDunningActionsListFullPath(): string {
	return `${getBasePath()}/${DunningActionsListRoutePath}`;
}

export function getAdminToolsPageRouteFullPath() {
	return `${getBasePath()}/${AdminToolsPath}`;
}

export function getAdminToolsDefaultRouteFullPath() {
	return `${getAdminToolsPageRouteFullPath()}/${AdminToolsDefaultPath}`;
}

export function getAdminToolsIndicatorsRouteFullPath() {
	return `${getAdminToolsPageRouteFullPath()}/${AdminToolsIndicatorsPath}`;
}

export function getAdminToolsWorkflowSchedulingInstancesListRouteFullPath() {
	return `${getAdminToolsPageRouteFullPath()}/${AdminToolsWorkflowSchedulingInstancesListPath}`;
}

export function getAdminToolsWorkflowSchedulingInstanceDetailRouteFullPath(id: number) {
	return `${getAdminToolsPageRouteFullPath()}/${AdminToolsWorkflowSchedulingInstancesListPath}/${AdminToolsWorkflowSchedulingInstanceDetailPath.replace(':id', '' + id)}`;
}
